import { Card, CardContent, Grid } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import PageContainer from "../components/common/page/pageContainer";
import ReportTile from "../components/reports/ReportTile";
import salesImage from "../assets/images/sales.jpg";
import SalesReportDialog from "../components/reports/SalesReportDialog";
import { useState } from "react";
import { saveAs } from "file-saver";
import { useAuth0 } from "@auth0/auth0-react";
import { generateMontlySales } from "../api/reportsApi";
import { format } from "date-fns";

const ReportsPage = () => {
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();

  const [open, setOpen] = useState(false);

  const breadcrumbs = [
    { label: t("pages.home.title"), link: "/" },
    { label: t("pages.reports.title"), isActive: true },
  ];

  const handleOnClose = () => {
    setOpen(false);
  };

  const handleOnGenerate = (date) => {
    getAccessTokenSilently().then((token) => {
      generateMontlySales(token, format(date, "yyyy-MM-dd")).then((x) => {
        saveAs(x.data, "sales-by-month.xlsx");
        setOpen(false);
      });
    });
  };

  return (
    <PageContainer heading={t("pages.reports.title")} breadcrumbs={breadcrumbs}>
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <ReportTile
                onClick={() => setOpen(true)}
                image={salesImage}
                imageTitle={t("pages.reports.report-tile.imageTitle")}
                title={t("pages.reports.report-tile.title")}
                description={t("pages.reports.report-tile.description")}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <SalesReportDialog
        open={open}
        onClose={handleOnClose}
        onGenerate={handleOnGenerate}
      ></SalesReportDialog>
    </PageContainer>
  );
};

export default ReportsPage;

import { useAuth0 } from "@auth0/auth0-react";
import {
  AppBar,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  makeStyles,
  Toolbar,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { createAccount, getCurrentAccount } from "../api/accountsApi";
import AffiliateLink from "../components/affiliate/AffiliateLink";
import AssociateId from "../components/affiliate/AssociateId";
import PageContainer from "../components/common/page/pageContainer";
import AppContext from "../components/common/providers/AppContext";
import { sidebarNavs } from "../menu-affiliate";
import MonthSummary from "../components/affiliate/MonthSummary";
import EarningsOverview from "../components/affiliate/EarningsOverview";
import PaymentsInformationAlert from "../components/affiliate/PaymentsInformationAlert";
import { NavigateBefore, NavigateNext } from "@material-ui/icons";
import { addMonths, startOfMonth } from "date-fns";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "white",
  },
}));

const HomeAssociatePage = () => {
  const classes = useStyles();

  const { setSidebarItems } = useContext(AppContext);
  const [account, setAccount] = useState(null);
  const [date, setDate] = useState(startOfMonth(new Date()));

  const { getAccessTokenSilently } = useAuth0();

  const { t } = useTranslation();
  const breadcrumbs = [
    { label: t("pages.home-associate.title"), isActive: true },
  ];

  useEffect(() => {
    setSidebarItems(sidebarNavs);
    getAccessTokenSilently().then((token) => {
      getCurrentAccount(token)
        .then((response) => {
          setAccount(response.data);
        })
        .catch(() => {
          createAccount(token).then((response) => {
            setAccount(response.data);
          });
        });
    });
  }, [getAccessTokenSilently, setSidebarItems]);

  useEffect(() => {
    setDate(startOfMonth(new Date()));
  }, []);

  const handleNext = () => {
    setDate(addMonths(date, 1));
  };

  const handlePrevious = () => {
    setDate(addMonths(date, -1));
  };

  return (
    <PageContainer
      heading={t("pages.home-associate.title")}
      breadcrumbs={breadcrumbs}
    >
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            {!account || !account.validated ? (
              <Grid item xs={12}>
                {(!account || !account.isPaymentInformationSet) && (
                  <Box>{t("pages.home-associate.needs-approve-message")}</Box>
                )}
              </Grid>
            ) : (
              <>
                <Grid item xs={12}>
                  {(!account || !account.isPaymentInformationSet) && (
                    <PaymentsInformationAlert />
                  )}
                </Grid>
                <Grid item xs={12}>
                  {account && <AssociateId id={account.shortId} />}
                </Grid>
                <Grid item xs={12}>
                  {account && <AffiliateLink id={account.shortId} />}
                </Grid>
              </>
            )}
          </Grid>
        </CardContent>
      </Card>

      {account && account.validated && (
        <Box mt={4}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <AppBar className={classes.root} position="static">
                <Toolbar>
                  <Box display="flex" flexGrow={1}>
                    <Button
                      color="primary"
                      startIcon={<NavigateBefore />}
                      onClick={handlePrevious}
                    >
                      {t("pages.home-associate.previous")}
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      color="primary"
                      endIcon={<NavigateNext />}
                      onClick={handleNext}
                    >
                      {t("pages.home-associate.next")}
                    </Button>
                  </Box>
                </Toolbar>
              </AppBar>
            </Grid>
            <Grid item xs={8}>
              <EarningsOverview date={date} />
            </Grid>
            <Grid item xs={4}>
              <MonthSummary date={date} account={account} />
            </Grid>
          </Grid>
        </Box>
      )}
    </PageContainer>
  );
};

export default HomeAssociatePage;

import React from "react";
import { TableCell } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { format } from "date-fns";

const DateFilterCell = ({ filter, onFilter }) => {
  return (
    <TableCell sx={{ width: "100%", p: 1 }}>
      <KeyboardDatePicker
        disableToolbar
        variant="inline"
        format="dd/MM/yyyy"
        value={filter ? filter.value : null}
        onChange={(e) =>
          onFilter(
            e
              ? {
                  value: format(e, "yyyy-MM-dd'T'00:00:00'Z'"),
                  operation: "=",
                }
              : null
          )
        }
      />
    </TableCell>
  );
};

DateFilterCell.propTypes = {};

export default DateFilterCell;

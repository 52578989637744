import axios from "axios";

import { createQueryURL, createAuthHeader } from "./apiUtils";

const baseUrl = `${process.env.REACT_APP_ADMIN_API}/api/trips`;

export function getTrips(token, loadOptions) {
  return axios.get(
    createQueryURL(baseUrl + "/all", loadOptions),
    createAuthHeader(token)
  );
}

export function getAvailables(loadOptions) {
  return axios.get(createQueryURL(baseUrl, loadOptions));
}

export function getTrip(token, id) {
  return axios.get(`${baseUrl}/${id}`, createAuthHeader(token));
}

export function getTripSlots(token, tripId) {
  return axios.get(`${baseUrl}/${tripId}/slots/all`, createAuthHeader(token));
}

export function saveTrip(token, trip) {
  if (trip.id) {
    return axios.put(`${baseUrl}/${trip.id}`, trip, createAuthHeader(token));
  }
  return axios.post(baseUrl, trip, createAuthHeader(token));
}

export function saveTripSlot(token, trip, tripSlot) {
  if (tripSlot.id) {
    return axios.put(
      `${baseUrl}/${trip.id}/slots/${tripSlot.id}`,
      tripSlot,
      createAuthHeader(token)
    );
  }
  return axios.post(
    `${baseUrl}/${trip.id}/slots`,
    tripSlot,
    createAuthHeader(token)
  );
}

export function deleteTripSlot(token, trip, tripSlot, deleteSeries, reason) {
  return axios.delete(`${baseUrl}/${trip.id}/slots/${tripSlot.id}`, {
    ...createAuthHeader(token),
    data: {
      deleteSeries,
      message: reason,
    },
  });
}

export function disableTrip(token, trip) {
  return axios.post(
    `${baseUrl}/${trip.id}/disable`,
    {},
    createAuthHeader(token)
  );
}

export function enableTrip(token, trip) {
  return axios.post(
    `${baseUrl}/${trip.id}/enable`,
    {},
    createAuthHeader(token)
  );
}

export function saveExtra(token, trip, extra) {
  if (extra.id) {
    return axios.put(
      `${baseUrl}/${trip.id}/extras/${extra.id}`,
      extra,
      createAuthHeader(token)
    );
  }
  return axios.post(
    `${baseUrl}/${trip.id}/extras`,
    extra,
    createAuthHeader(token)
  );
}

export function getExtras(token, tripId) {
  return axios.get(`${baseUrl}/${tripId}/extras`, createAuthHeader(token));
}

export function deleteExtra(token, trip, extra) {
  return axios.delete(
    `${baseUrl}/${trip.id}/extras/${extra.id}`,
    createAuthHeader(token)
  );
}

export function disableTripSlot(token, trip, tripSlot) {
  return axios.post(
    `${baseUrl}/${trip.id}/slots/${tripSlot.id}/disable`,
    {},
    createAuthHeader(token)
  );
}

export function enableTripSlot(token, trip, tripSlot) {
  return axios.post(
    `${baseUrl}/${trip.id}/slots/${tripSlot.id}/enable`,
    {},
    createAuthHeader(token)
  );
}

export function uploadThumbnail(token, trip, formData) {
  return axios.post(`${baseUrl}/${trip.id}/thumbnail`, formData, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  });
}

import React, { useState } from "react";
import { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { useContext } from "react";
import AppContext from "../components/common/providers/AppContext";

const RedirectToRole = ({ role }) => {
  switch (role) {
    case "administrator":
      return <Redirect to="/home-administrator" />;
    case "validator":
      return <Redirect to="/home-sailor" />;
    case "affiliate":
      return <Redirect to="/home-affiliate" />;
    default:
      return <Redirect to="/unauthorized" />;
  }
};

const HomePage = () => {
  const { roles } = useContext(AppContext);
  const [role, setRole] = useState("");

  useEffect(() => {
    if (roles.includes("administrator")) {
      setRole("administrator");
    } else if (roles.includes("validator")) {
      setRole("validator");
    } else if (roles.includes("affiliate")) {
      setRole("affiliate");
    } else {
      setRole("unauthorized");
    }
  }, [roles]);

  return !role ? <>Loading your profile...</> : <RedirectToRole role={role} />;
};

export default HomePage;

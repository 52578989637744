import axios from "axios";

import { createAuthHeader } from "./apiUtils";

const baseUrl = `${process.env.REACT_APP_ADMIN_API}/api/reports`;

export function generateMontlySales(token, date) {
  return axios.get(`${baseUrl}/monthly-sales/${date}`, {
    ...createAuthHeader(token),
    responseType: "blob",
  });
}

import HomeIcon from "@material-ui/icons/Home";

export const sidebarNavs = [
  {
    name: "main",
    type: "section",
    children: [
      {
        name: "home",
        type: "item",
        icon: <HomeIcon />,
        link: "/",
      },
    ],
  },
];

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  drawerContainer: {
    width: "100%",
    height: "100%",
  },
  drawerContent: {
    position: "relative",
    width: "100%",
    height: "100%",
    zIndex: 1,
  },
  overlayRoot: {
    position: "absolute",
    left: 0,
    top: 0,
    zIndex: 0,
    width: "100%",
    height: "100%",
  },
}));

export default useStyles;

import React, { useState, useEffect, useContext } from "react";
import PageContainer from "../components/common/page/pageContainer";
import ManageTripForm from "../components/trips/ManageTripForm";
import {
  saveTrip,
  getTrip,
  saveTripSlot,
  getTripSlots,
  deleteTripSlot,
  saveExtra,
  getExtras,
  deleteExtra,
  enableTripSlot,
  disableTripSlot,
  uploadThumbnail,
} from "../api/tripsApi";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import AppContext from "../components/common/providers/AppContext";
import ManageTripSlotDialog from "../components/trips/ManageTripSlotDialog";
import startOfToday from "date-fns/startOfToday";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";
import { isBefore } from "date-fns";
import DeleteTripSlotDialog from "../components/trips/DeleteTripSlotDialog";
import ManageExtraDialog from "../components/trips/ManageExtraDialog";
import ConfirmationDialog from "../components/common/dialogs/ConfirmationDialog";

const defaultTripSlot = {
  isRecurring: false,
  departureOriginTimes: [],
  departureDestinationTimes: [],
  recurring: {
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  },
  slotDate: {
    startDate: startOfToday(),
    endDate: startOfToday(),
  },
  totalNumberOfPeople: 0,
  availableNumberOfPeople: 0,
};

const defaultExtra = {
  price: {
    amount: 0,
    currency: "EUR",
  },
  title: "",
  image: "",
  reference: "",
  description: "",
};

const TripManagementPage = () => {
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();

  const [openManageTripSlot, setOpenManageTripSlot] = useState(false);
  const [openManageExtra, setOpenManageExtra] = useState(false);
  const [openDeleteTripSlot, setOpenDeleteTripSlot] = useState(false);
  const [openDeleteExtra, setOpenDeleteExtra] = useState(false);
  const { quickFeedback, setQuickFeedback } = useContext(AppContext);
  const { tripId } = useParams();
  const [errors, setErrors] = useState({});
  const [formIsDirty, setFormDirty] = useState(false);
  const [trip, setTrip] = useState({
    name: "",
    description: "",
    thumbnail: "",
    pricePerAdult: { amount: 0, currency: "Euro" },
    pricePerChild: { amount: 0, currency: "Euro" },
    ignoreDiscounts: false,
  });
  const [tripSlots, setTripSlots] = useState([]);
  const [extras, setExtras] = useState([]);
  const breadcrumbs = [
    { label: t("pages.home.title"), link: "/" },
    { label: t("pages.trips.title"), link: "/trips" },
    {
      label: `${
        tripId ? t("pages.edit-trip.title") : t("pages.add-trip.title")
      }`,
      isActive: true,
    },
  ];
  const [slotErrors, setSlotErrors] = useState({});
  const [tripSlot, setTripSlot] = useState(null);
  const [extra, setExtra] = useState(null);
  const [extraErrors, setExtraErrors] = useState({});
  const [thumbnailUpdated, setThumbnailUpdated] = useState(false);

  useEffect(() => {
    if (tripId) {
      getAccessTokenSilently().then((token) => {
        getTrip(token, tripId).then((response) => {
          setTrip(response.data);
        });

        getTripSlots(token, tripId).then((response) => {
          setTripSlots(response.data.data);
        });

        getExtras(token, tripId).then((response) => {
          setExtras(response.data);
        });
      });
    }
  }, [tripId, getAccessTokenSilently]);

  useEffect(() => {
    if (thumbnailUpdated) {
      save();
    }
    // eslint-disable-next-line
  }, [thumbnailUpdated]);

  const history = useHistory();

  const formIsValid = () => {
    const errors = {};

    const { name } = trip;

    if (!name) errors.name = t("pages.manage-trip.form.name.error");

    setErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const save = () => {
    if (!formIsValid() || !formIsDirty) return;
    getAccessTokenSilently().then((token) => {
      saveTrip(token, trip)
        .then((response) => {
          setFormDirty(false);
          setTrip(response.data);
          setQuickFeedback({
            ...quickFeedback,
            show: true,
            severity: "success",
            message: "Trip has been saved successfully.",
          });
        })
        .catch((error) => {
          setErrors({ onSave: error.message });
          setQuickFeedback({
            ...quickFeedback,
            show: true,
            severity: "error",
            message: "Oops! Something went wrong! Try again later.",
          });
        });
    });
  };

  const handleOnBlur = (event) => {
    event.preventDefault();

    save();
  };

  const handleOnChange = (event) => {
    setFormDirty(true);
    const { name, value } = event.target;

    switch (name) {
      case "pricePerAdult":
      case "pricePerChild":
        setTrip({
          ...trip,
          [name]: {
            amount: value,
            currency: "Euro",
          },
        });
        break;
      case "ignoreDiscounts":
        setTrip({
          ...trip,
          [name]: event.target.checked,
        });
        break;
      default:
        setTrip({ ...trip, [name]: value });
        break;
    }
  };

  const handleOnCancel = () => {
    history.push("/trips");
  };

  const handleOnOpenSlot = (slot) => {
    if (slot) setTripSlot(slot);
    else setTripSlot(defaultTripSlot);

    setOpenManageTripSlot(true);
  };

  const handleOnChangeTripSlot = (event) => {
    const { name, value } = event.target;

    switch (name) {
      case "addDepartureOriginTimes":
        setTripSlot({
          ...tripSlot,
          departureOriginTimes: [...tripSlot.departureOriginTimes, value],
        });
        break;
      case "addDepartureDestinationTimes":
        setTripSlot({
          ...tripSlot,
          departureDestinationTimes: [
            ...tripSlot.departureDestinationTimes,
            value,
          ],
        });
        break;
      case "removeDepartureOriginTimes":
        setTripSlot({
          ...tripSlot,
          departureOriginTimes: tripSlot.departureOriginTimes.filter(
            (x) => x !== value
          ),
        });
        break;
      case "removeDepartureDestinationTimes":
        setTripSlot({
          ...tripSlot,
          departureDestinationTimes: tripSlot.departureDestinationTimes.filter(
            (x) => x !== value
          ),
        });
        break;
      case "recurringMonday":
        setTripSlot({
          ...tripSlot,
          recurring: {
            ...tripSlot.recurring,
            monday: event.target.checked,
          },
        });
        break;
      case "recurringTuesday":
        setTripSlot({
          ...tripSlot,
          recurring: {
            ...tripSlot.recurring,
            tuesday: event.target.checked,
          },
        });
        break;
      case "recurringWednesday":
        setTripSlot({
          ...tripSlot,
          recurring: {
            ...tripSlot.recurring,
            wednesday: event.target.checked,
          },
        });
        break;
      case "recurringThursday":
        setTripSlot({
          ...tripSlot,
          recurring: {
            ...tripSlot.recurring,
            thursday: event.target.checked,
          },
        });
        break;
      case "recurringFriday":
        setTripSlot({
          ...tripSlot,
          recurring: {
            ...tripSlot.recurring,
            friday: event.target.checked,
          },
        });
        break;
      case "recurringSaturday":
        setTripSlot({
          ...tripSlot,
          recurring: {
            ...tripSlot.recurring,
            saturday: event.target.checked,
          },
        });
        break;
      case "recurringSunday":
        setTripSlot({
          ...tripSlot,
          recurring: {
            ...tripSlot.recurring,
            sunday: event.target.checked,
          },
        });
        break;
      case "isRecurring":
        setTripSlot({ ...tripSlot, isRecurring: event.target.checked });
        break;
      case "totalNumberOfPeople":
        setTripSlot({ ...tripSlot, totalNumberOfPeople: parseInt(value) });
        break;
      case "startDate":
        if (!tripSlot.id) {
          setTripSlot({
            ...tripSlot,
            slotDate: {
              ...tripSlot.slotDate,
              startDate: new Date(value),
            },
          });
        } else {
          setTripSlot({
            ...tripSlot,
            slotDate: {
              ...tripSlot.slotDate,
              startDate: new Date(value),
              endDate: new Date(value),
            },
          });
        }
        break;
      case "endDate":
        setTripSlot({
          ...tripSlot,
          slotDate: {
            ...tripSlot.slotDate,
            endDate: new Date(value),
          },
        });
        break;

      default:
        break;
    }
  };

  const slotFormIsValid = () => {
    const errors = {};

    const {
      slotDate,
      isRecurring,
      recurring,
      departureOriginTimes,
      departureDestinationTimes,
    } = tripSlot;

    if (departureOriginTimes.length === 0) {
      errors.departureOriginTimes = t(
        "components.manage-trip-slot-dialog.form.departureOriginTimes.error"
      );
    }

    if (departureDestinationTimes.length === 0) {
      errors.departureDestinationTimes = t(
        "components.manage-trip-slot-dialog.form.departureDestinationTimes.error"
      );
    }

    if (isRecurring) {
      if (isBefore(slotDate.endDate, slotDate.startDate)) {
        errors.slotDate = t(
          "components.manage-trip-slot-dialog.form.startDate.error"
        );
      }

      if (
        !recurring.monday &&
        !recurring.tuesday &&
        !recurring.wednesday &&
        !recurring.thursday &&
        !recurring.friday &&
        !recurring.saturday &&
        !recurring.sunday
      ) {
        errors.recurring = t(
          "components.manage-trip-slot-dialog.form.recurring.error"
        );
      }
    }

    setSlotErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const handleOnSaveSlot = () => {
    if (!slotFormIsValid()) return;

    getAccessTokenSilently().then((token) => {
      saveTripSlot(token, trip, tripSlot)
        .then((response) => {
          if (!tripSlot.id) {
            setTripSlots([...tripSlots, ...response.data]);
          } else {
            setTripSlots(
              tripSlots.map((tripSlot) =>
                tripSlot.id === response.data[0].id
                  ? response.data[0]
                  : tripSlot
              )
            );
          }
          setTripSlot(null);
          setQuickFeedback({
            ...quickFeedback,
            show: true,
            severity: "success",
            message: "Trip slot has been saved successfully.",
          });
        })
        .catch((error) => {
          setErrors({ onSave: error.message });
          setQuickFeedback({
            ...quickFeedback,
            show: true,
            severity: "error",
            message: "Oops! Something went wrong! Try again later.",
          });
        });
    });
  };

  const handleOnCloseManageTripSlot = () => {
    setTripSlot(null);
    setOpenManageTripSlot(false);
  };

  const handleOnDeleteSlot = (slot) => {
    setTripSlot(slot);
    setOpenDeleteTripSlot(true);
  };

  const handleOnCloseDeleteDialog = () => {
    setTripSlot(null);
    setOpenDeleteTripSlot(false);
  };

  const handleOnDeleteDeleteDialog = (reason) => {
    getAccessTokenSilently().then((token) => {
      deleteTripSlot(token, trip, tripSlot, false, reason)
        .then(() => {
          setTripSlots(tripSlots.filter((x) => x.id !== tripSlot.id));
          setTripSlot(null);
          setOpenDeleteTripSlot(false);
        })
        .catch((error) => {
          setErrors({ onSave: error.message });
          setQuickFeedback({
            ...quickFeedback,
            show: true,
            severity: "error",
            message: "Oops! Something went wrong! Try again later.",
          });
        });
    });
  };

  const handleOnDeleteAllDeleteDialog = (reason) => {
    getAccessTokenSilently().then((token) => {
      deleteTripSlot(token, trip, tripSlot, true, reason)
        .then(() => {
          setTripSlots(
            tripSlots.filter(
              (x) => x.recurring.seriesId !== tripSlot.recurring.seriesId
            )
          );
          setTripSlot(null);
          setOpenDeleteTripSlot(false);
        })
        .catch((error) => {
          setErrors({ onSave: error.message });
          setQuickFeedback({
            ...quickFeedback,
            show: true,
            severity: "error",
            message: "Oops! Something went wrong! Try again later.",
          });
        });
    });
  };

  const handleOpenExtra = (item) => {
    if (item) setExtra(item);
    else setExtra(defaultExtra);

    setOpenManageExtra(true);
  };

  const handleOnChangeExtra = (event) => {
    const { name, value } = event.target;

    switch (name) {
      case "price":
        setExtra({
          ...extra,
          [name]: {
            amount: value,
            currency: "Euro",
          },
        });
        break;
      default:
        setExtra({
          ...extra,
          [name]: value,
        });
        break;
    }
  };

  const handleOnCloseManageExtra = () => {
    setExtra(null);
    setOpenManageExtra(false);
  };

  const handleOnSaveExtra = () => {
    if (!extraFormValid()) return;

    getAccessTokenSilently().then((token) => {
      saveExtra(token, trip, extra)
        .then((response) => {
          if (!extra.id) {
            setExtras([...extras, response.data]);
          } else {
            setExtras(
              extras.map((extra) =>
                extra.id === response.data.id ? response.data : extra
              )
            );
          }
          setExtra(null);
          setQuickFeedback({
            ...quickFeedback,
            show: true,
            severity: "success",
            message: "Extra has been saved successfully.",
          });
        })
        .catch((error) => {
          setErrors({ onSave: error.message });
          setQuickFeedback({
            ...quickFeedback,
            show: true,
            severity: "error",
            message: "Oops! Something went wrong! Try again later.",
          });
        });
    });
  };

  const extraFormValid = () => {
    const errors = {};

    const { title, price, reference } = extra;

    if (!title) {
      errors.title = t("components.manage-trip-extra-dialog.form.title.error");
    }

    if (!reference) {
      errors.reference = t(
        "components.manage-trip-extra-dialog.form.title.error"
      );
    }

    if (price.amount <= 0) {
      errors.price = t("components.manage-trip-extra-dialog.form.price.error");
    }

    setExtraErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const handleOnDeleteExtra = (extra) => {
    setExtra(extra);
    setOpenDeleteExtra(true);
  };

  const handleCancelDeleteExtra = () => {
    setExtra(null);
    setOpenDeleteExtra(false);
  };

  const handleAcceptDeleteExtra = () => {
    getAccessTokenSilently().then((token) => {
      deleteExtra(token, trip, extra)
        .then(() => {
          setExtras(extras.filter((x) => x.id !== extra.id));
          setExtra(null);
          setOpenDeleteExtra(false);
        })
        .catch((error) => {
          setErrors({ onSave: error.message });
          setQuickFeedback({
            ...quickFeedback,
            show: true,
            severity: "error",
            message: "Oops! Something went wrong! Try again later.",
          });
        });
    });
  };

  const handleOnDisable = (tripSlot) => {
    getAccessTokenSilently().then((token) => {
      disableTripSlot(token, trip, tripSlot).then(() => {
        setTripSlots(
          tripSlots.map((x) => {
            if (x.id !== tripSlot.id) return x;
            else return { ...x, enabled: false };
          })
        );
      });
    });
  };

  const handleOnEnable = (tripSlot) => {
    getAccessTokenSilently().then((token) => {
      enableTripSlot(token, trip, tripSlot).then(() => {
        setTripSlots(
          tripSlots.map((x) => {
            if (x.id !== tripSlot.id) return x;
            else return { ...x, enabled: true };
          })
        );
      });
    });
  };

  const handleOnThumbnailChange = (files) => {
    if (!files || files.length === 0) return;
    const formData = new FormData();
    formData.append("file", files[0].file);

    getAccessTokenSilently().then((token) => {
      uploadThumbnail(token, trip, formData)
        .then((response) => {
          setTrip({ ...trip, thumbnail: response.data });
          setFormDirty(true);
          setThumbnailUpdated(true);
        })
        .catch((error) => {
          setErrors({ onSave: error.message });
          setQuickFeedback({
            ...quickFeedback,
            show: true,
            severity: "error",
            message: "Oops! Something went wrong! Try again later.",
          });
        });
    });
  };

  const handleOnThumbnailChangeExtra = (files) => {
    if (!files || files.length === 0) return;
    const formData = new FormData();
    formData.append("file", files[0].file);

    getAccessTokenSilently().then((token) => {
      uploadThumbnail(token, trip, formData)
        .then((response) => {
          setExtra({
            ...extra,
            image: response.data,
          });
        })
        .catch((error) => {
          setErrors({ onSave: error.message });
          setQuickFeedback({
            ...quickFeedback,
            show: true,
            severity: "error",
            message: "Oops! Something went wrong! Try again later.",
          });
        });
    });
  };

  return (
    <PageContainer
      heading={`${
        tripId ? t("pages.edit-trip.title") : t("pages.add-trip.title")
      }`}
      breadcrumbs={breadcrumbs}
    >
      <ManageTripForm
        trip={trip}
        tripSlots={tripSlots}
        errors={errors}
        extras={extras}
        onBlur={handleOnBlur}
        onCancel={handleOnCancel}
        onChange={handleOnChange}
        onOpenSlot={handleOnOpenSlot}
        onDeleteSlot={handleOnDeleteSlot}
        onOpenExtra={handleOpenExtra}
        onDeleteExtra={handleOnDeleteExtra}
        onDisable={handleOnDisable}
        onEnable={handleOnEnable}
        onThumbnailChange={handleOnThumbnailChange}
      />
      <ManageTripSlotDialog
        open={openManageTripSlot}
        onClose={handleOnCloseManageTripSlot}
        onChange={handleOnChangeTripSlot}
        onSave={handleOnSaveSlot}
        errors={slotErrors}
        tripSlot={tripSlot}
      />
      <ManageExtraDialog
        open={openManageExtra}
        onClose={handleOnCloseManageExtra}
        onChange={handleOnChangeExtra}
        onSave={handleOnSaveExtra}
        errors={extraErrors}
        extra={extra}
        onThumbnailChange={handleOnThumbnailChangeExtra}
      />
      <DeleteTripSlotDialog
        open={openDeleteTripSlot}
        tripSlot={tripSlot}
        onClose={handleOnCloseDeleteDialog}
        onDelete={handleOnDeleteDeleteDialog}
        onDeleteAll={handleOnDeleteAllDeleteDialog}
      />
      <ConfirmationDialog
        open={openDeleteExtra}
        onCancel={handleCancelDeleteExtra}
        onAccept={handleAcceptDeleteExtra}
        message={t("pages.edit-trip.delete-extra.message")}
        title={t("pages.edit-trip.delete-extra.title")}
      />
    </PageContainer>
  );
};

export default TripManagementPage;

import React, { useContext, useEffect, useState } from "react";

import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";

import { Box } from "@material-ui/core";
import Sidebar from "../sidebar/Sidebar";
import SidebarHeader from "../sidebar/SidebarHeader";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import useStyles from "./MainContainer.style";
import LayoutContext from "../providers/LayoutContext";
import { HEADER_TYPE, FOOTER_TYPE } from "../constants/themeOptions";
import SidebarContent from "../sidebar/SidebarContent";

const MainContainer = ({ className, children, ...rest }) => {
  const {
    headerType,
    footerType,
    sidebarType,
    isSidebarOpen,
    isSidebarFixed,
    miniSidebarWidth,
    sidebarWidth,
    actionSidebarWidth,
    drawerBreakPoint,
  } = useContext(LayoutContext);

  const theme = useTheme();
  const isDrawer = useMediaQuery(theme.breakpoints.down(drawerBreakPoint));

  const [headerClasses, setHeaderClasses] = useState("");
  const [footerClasses, setFooterClasses] = useState("");
  const [sidebarClasses, setSidebarClasses] = useState("");

  useEffect(() => {
    setHeaderClasses(
      headerType === HEADER_TYPE.FIXED ? "Cmt-fixedHeaderLayout" : ""
    );
  }, [headerType]);

  useEffect(() => {
    setFooterClasses(
      footerType === FOOTER_TYPE.FIXED ? "Cmt-FixedFooterLayout" : ""
    );
  }, [footerType]);

  useEffect(() => {
    const newClasses = [];

    if (sidebarType === "drawer" || isDrawer)
      newClasses.push("Cmt-drawerLayout");
    else if (sidebarType === "mini")
      newClasses.push(isSidebarOpen ? "Cmt-fullMiniLayout" : "Cmt-miniLayout");

    if (isSidebarFixed) newClasses.push("Cmt-sidebar-fixed");

    setSidebarClasses(newClasses.join(" "));
  }, [sidebarType, isSidebarOpen, isDrawer, isSidebarFixed]);

  const classes = useStyles({
    miniSidebarWidth,
    sidebarWidth,
    actionSidebarWidth,
    drawerBreakPoint,
  });

  return (
    <Box
      className={`${
        classes.appRoot
      } ${headerClasses} ${sidebarClasses} ${footerClasses} ${className || ""}`}
      {...rest}
    >
      <div className={classes.appInnerRoot}>
        <div className={classes.appMainContainer}>
          <Sidebar>
            <SidebarHeader />
            <SidebarContent />
          </Sidebar>
          <div className={classes.appMain}>
            <Header />
            {children}
            <Footer />
          </div>
        </div>
      </div>
    </Box>
  );
};

export default MainContainer;

import React from "react";
import PropTypes from "prop-types";
import { Box, Card, CardContent, Grid, Typography } from "@material-ui/core";
import { format } from "date-fns";
import CommonGrid from "../common/grids/CommonGrid";
import Table from "@material-ui/core/Table";
import { TableBody, TableCell, TableRow } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const SummaryAndPayment = ({ booking }) => {
  const { t } = useTranslation();

  const itemsColumns = [
    { name: "item", title: t("components.summary-and-payment.grid.item") },
    {
      name: "price",
      title: t("components.summary-and-payment.grid.price"),
      getCellValue: (row) => `${row.price.amount} €`,
    },
    {
      name: "quantity",
      title: t("components.summary-and-payment.grid.quantity"),
    },
    {
      name: "amount",
      title: t("components.summary-and-payment.grid.amount"),
      getCellValue: (row) => `${row.amount} €`,
    },
  ];

  const getItems = () => {
    let items = [];

    if (booking.numberOfAdults > 0) {
      items.push({
        item: `${booking.tripName}-${t(
          "components.summary-and-payment.items.adult"
        )}-${format(new Date(booking.date.startDate), "dd/MM/yyy")}-${
          booking.departureOriginTime
        }-${booking.departureDestinationTime}`,
        price: booking.pricePerAdult,
        quantity: booking.numberOfAdults,
        amount: booking.pricePerAdult.amount * booking.numberOfAdults,
      });
    }

    if (booking.numberOfChildren > 0) {
      items.push({
        item: `${booking.tripName}-${t(
          "components.summary-and-payment.items.child"
        )}-${format(new Date(booking.date.startDate), "dd/MM/yyy")}-${
          booking.departureOriginTime
        }-${booking.departureDestinationTime}`,
        price: booking.pricePerChild,
        quantity: booking.numberOfChildren,
        amount: booking.pricePerChild.amount * booking.numberOfChildren,
      });
    }

    return items;
  };

  const extras = booking.extras.map((x) => {
    return {
      item: x.title,
      price: x.price,
      quantity: x.amount,
      amount: x.price.amount * x.amount,
    };
  });

  const items = getItems();

  let totals = [
    {
      name: t("components.summary-and-payment.totals.subtotal"),
      value: `${booking.subTotal.amount.toFixed(2)} €`,
    },
  ];

  if (!booking.ignoreDiscounts) {
    if (booking.applyWebDiscount) {
      totals = [
        ...totals,
        {
          name: t("components.summary-and-payment.totals.web"),
          value: `${(((booking.subTotal.amount * 10) / 100) * -1).toFixed(
            2
          )} €`,
        },
      ];
    }

    if (booking.applyResidentsDiscount) {
      totals = [
        ...totals,
        {
          name: t("components.summary-and-payment.totals.residents"),
          value: `${(((booking.subTotal.amount * 20) / 100) * -1).toFixed(
            2
          )} €`,
        },
      ];
    }
  }

  if (booking.extras.length > 0) {
    totals = [
      ...totals,
      {
        name: t("components.summary-and-payment.totals.extras"),
        value: `${booking.extras
          .reduce((acc, item) => acc + item.price.amount * item.amount, 0)
          .toFixed(2)} €`,
      },
    ];
  }

  totals = [
    ...totals,
    {
      name: t("components.summary-and-payment.totals.total"),
      value: `${booking.amount.amount.toFixed(2)} €`,
    },
  ];

  return (
    <Card>
      <CardContent>
        <Box p={6}>
          <Grid container>
            <Grid item xs={10}>
              <Grid container>
                <Grid item xs={4}></Grid>
                <Grid item xs={8}>
                  <Box ml={6}>
                    <Typography>Lanchas la Gaviota S.L.</Typography>
                    <Typography>Calle Moll Comercial s/n</Typography>
                    <Typography>07470 Port de Pollença</Typography>
                    <Typography>Illes Balears</Typography>
                    <Typography>NIF B07063811</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={2}>
              <Grid container justifyContent="flex-end">
                <img
                  src={`data:image/png;base64, ${booking.qrCodeBase64}`}
                  alt="QR code"
                />
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Box mt={6}>{t("components.summary-and-payment.bill-to")}</Box>
              <Box>
                {booking.customerFullName
                  ? booking.customerFullName
                  : booking.customerEmail}
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Grid container justifyContent="flex-end">
                <Box mt={6}>
                  <Typography variant="h3">TICKET</Typography>
                </Box>
              </Grid>
              <Grid container justifyContent="flex-end">
                # TICKET-{String(booking.autoId).padStart(6, "0")}
              </Grid>
              <Grid container justifyContent="flex-end">
                {format(new Date(booking.created), "dd/MM/yyyy")}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Box mt={6} mb={10}>
                <CommonGrid
                  columns={itemsColumns}
                  items={items}
                  defaultColumnWidths={[
                    { columnName: "item", width: "auto" },
                    { columnName: "price", width: 100 },
                    { columnName: "quantity", width: 100 },
                    { columnName: "amount", width: 100 },
                  ]}
                />
              </Box>
            </Grid>
            {booking.extras.length > 0 && (
              <>
                <Grid item xs={12}>
                  <Box>Extras</Box>
                </Grid>
                <Grid item xs={12}>
                  <Box mt={6} mb={10}>
                    <CommonGrid
                      columns={itemsColumns}
                      items={extras}
                      defaultColumnWidths={[
                        { columnName: "item", width: "auto" },
                        { columnName: "price", width: 100 },
                        { columnName: "quantity", width: 100 },
                        { columnName: "amount", width: 100 },
                      ]}
                    />
                  </Box>
                </Grid>
              </>
            )}
            <Grid item xs={6}></Grid>
            <Grid item xs={6}>
              <Table>
                <TableBody>
                  {totals.map((row) => (
                    <TableRow key={row.name}>
                      <TableCell>{row.name}</TableCell>
                      <TableCell align="right">{row.value}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {t("components.summary-and-payment.greetings")}
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
};

SummaryAndPayment.propTypes = {
  booking: PropTypes.object.isRequired,
};

export default SummaryAndPayment;

import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  TableColumnResizing,
  TableFilterRow,
  TableHeaderRow,
  Toolbar,
  VirtualTable,
} from "@devexpress/dx-react-grid-material-ui";
import {
  DataTypeProvider,
  FilteringState,
  IntegratedSorting,
  SortingState,
  VirtualTableState,
} from "@devexpress/dx-react-grid";
import { VIRTUAL_PAGE_SIZE } from "./commonGridExtenderUtils";
import ActionFormatter from "./ActionFormatter";
import DateFormatter from "./DateFormatter";
import FilterCell from "./FilterCell";
import BoolFormatter from "./BoolFormater";
import NumberFormatter from "./NumberFormatter";

const CommonGridExtended = ({
  state,
  columns,
  onGetRows,
  onSortingChange,
  onChangeFilters,
  children,
}) => {
  const { rows, loading, totalCount, skip, sorting, filters } = state;

  const sortingStateColumnExtensions = columns
    .filter((x) => x.allowSorting === false)
    .map((x) => {
      return {
        columnName: x.name,
        sortingEnabled: false,
      };
    });

  const filteringStateColumnExtensions = columns
    .filter((x) => x.allowFiltering === false)
    .map((x) => {
      return {
        columnName: x.name,
        filteringEnabled: false,
      };
    });

  const defaultColumnWidths = columns.map((x) => {
    return {
      columnName: x.name,
      width: x.width ? x.width : "auto",
    };
  });

  const [stringFilterOperations] = useState([
    "contains",
    "notContains",
    "startsWith",
    "endsWith",
  ]);

  const [numberFilterOperations] = useState([
    "equal",
    "notEqual",
    "greaterThan",
    "greaterThanOrEqual",
    "lessThan",
    "lessThanOrEqual",
  ]);

  const stringColumns = columns
    .filter((x) => x.type === "string")
    .map((x) => x.name);

  const numberColumns = columns
    .filter((x) => x.type === "number")
    .map((x) => x.name);

  const dateColumns = columns
    .filter((x) => x.type === "date")
    .map((x) => x.name);

  const boolColumns = columns
    .filter((x) => x.type === "bool")
    .map((x) => x.name);

  return (
    <Grid rows={rows} columns={columns} getRowId={(row) => row.id}>
      <DataTypeProvider
        for={["actions"]}
        formatterComponent={ActionFormatter}
      />

      <DataTypeProvider
        for={stringColumns}
        availableFilterOperations={stringFilterOperations}
      />

      <DataTypeProvider
        for={numberColumns}
        availableFilterOperations={numberFilterOperations}
        formatterComponent={NumberFormatter}
      />

      <DataTypeProvider for={boolColumns} formatterComponent={BoolFormatter} />

      <DataTypeProvider for={dateColumns} formatterComponent={DateFormatter} />

      <VirtualTableState
        loading={loading}
        totalRowCount={totalCount}
        pageSize={VIRTUAL_PAGE_SIZE}
        skip={skip}
        getRows={onGetRows}
      />
      <SortingState
        columnExtensions={sortingStateColumnExtensions}
        sorting={sorting}
        onSortingChange={onSortingChange}
      />
      <FilteringState
        columnExtensions={filteringStateColumnExtensions}
        filters={filters}
        onFiltersChange={onChangeFilters}
      />

      <IntegratedSorting />
      <VirtualTable />
      <TableColumnResizing
        resizingMode="nextColumn"
        defaultColumnWidths={defaultColumnWidths}
      />
      <TableHeaderRow showSortingControls />
      <TableFilterRow showFilterSelector cellComponent={FilterCell} />
      <Toolbar />
      {children}
    </Grid>
  );
};

CommonGridExtended.propTypes = {
  state: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
  onGetRows: PropTypes.func.isRequired,
  onSortingChange: PropTypes.func.isRequired,
  onChangeFilters: PropTypes.func.isRequired,
};

export default CommonGridExtended;

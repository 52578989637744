import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory, useParams } from "react-router";
import { getCustomer } from "../api/customersApi";
import PageContainer from "../components/common/page/pageContainer";
import defaultCustomer from "../domain/defaultCustomer";
import CustomerManagement from "../components/customers/CustomerManagement";
import { useTranslation } from "react-i18next";

const CustomerManagementPage = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { t } = useTranslation();
  const history = useHistory();
  const { customerId } = useParams();
  const [customer, setCustomer] = useState(defaultCustomer);

  const breadcrumbs = [
    { label: t("pages.home.title"), link: "/" },
    { label: t("pages.customers.title"), link: "/customers" },
    {
      label: `${
        customerId
          ? t("pages.edit-customer.title")
          : t("pages.add-customer.title")
      }`,
      isActive: true,
    },
  ];

  useEffect(() => {
    if (customerId) {
      getAccessTokenSilently().then((token) => {
        getCustomer(token, customerId).then((response) => {
          setCustomer({ ...response.data });
        });
      });
    }
  }, [customerId, getAccessTokenSilently]);

  const handleOnCancel = () => {
    history.push("/customers");
  };

  return (
    <PageContainer
      heading={`${
        customerId
          ? t("pages.edit-customer.title")
          : t("pages.add-customer.title")
      }`}
      breadcrumbs={breadcrumbs}
    >
      <CustomerManagement customer={customer} onCancel={handleOnCancel} />
    </PageContainer>
  );
};

export default CustomerManagementPage;

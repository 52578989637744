import React from "react";
import PropTypes from "prop-types";
import {
  AppBar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Tab,
  Tabs,
  TextField,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { DatePicker } from "@material-ui/pickers";
import AddressPicker from "../common/addressPicker/AddressPicker";
import { useTranslation } from "react-i18next";
import CommonGrid from "../common/grids/CommonGrid";
import ConfirmationDialog from "../common/dialogs/ConfirmationDialog";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { cancelBooking } from "../../api/bookingsApi";
import { useEffect } from "react";
import { getBookingsByCustomer } from "../../api/customersApi";
import { format } from "date-fns";

const ManageCustomerForm = ({
  customer,
  errors,
  onChange,
  onCancel,
  onBlur,
  showCancelButton = true,
  showBookings = true,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { getAccessTokenSilently } = useAuth0();

  const columns = [
    {
      name: "ticket",
      title: t("pages.bookings.grid.ticket"),
      type: "string",
    },
    {
      name: "tripName",
      title: t("pages.bookings.grid.trip"),
      type: "string",
    },
    {
      name: "slot",
      title: t("pages.bookings.grid.slot"),
      getCellValue: (row) =>
        `${format(new Date(row.date.startDate), "dd/MM/yyyy")} ${
          row.departureOriginTime
        }-${row.departureDestinationTime}`,
      type: "string",
    },
    {
      name: "created",
      title: t("pages.bookings.grid.created"),
      getCellValue: (row) => format(new Date(row.created), "E, d MMMM yyyy"),
      width: 150,
      type: "date",
    },
    {
      name: "stateName",
      title: t("pages.bookings.grid.state"),
      type: "choice",
      options: ["Draft", "Paid", "TicketValidated", "Cancelled"],
      width: 100,
    },
    {
      name: "actions",
      title: t("pages.bookings.grid.actions"),
      actions: [
        {
          name: "View",
          run: (item) => {
            history.push(`/bookings/${item.id}/view`);
          },
          isActionVisible: (item) => item.stateName === "Paid",
        },
        {
          name: "Edit",
          run: (item) => {
            history.push(`/bookings/${item.id}`);
          },
          isActionVisible: (item) => item.stateName === "Draft",
        },
        {
          name: "Cancel",
          run: (item) => {
            setSelectedBooking(item);
            setOpenCancelConfirmation(true);
          },
          isActionVisible: (item) => item.stateName === "Paid",
        },
      ],
      allowSorting: false,
      allowFiltering: false,
      width: 100,
    },
  ];

  const [bookings, setBookings] = useState([]);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [openCancelConfirmation, setOpenCancelConfirmation] = useState(false);

  const handleOnCancelCancelConfirmation = () => {
    setOpenCancelConfirmation(false);
    setSelectedBooking(null);
  };

  const handleOnAcceptCancelConfirmation = () => {
    getAccessTokenSilently().then((token) => {
      cancelBooking(token, selectedBooking).then((response) => {
        setSelectedBooking(null);
        setOpenCancelConfirmation(false);

        getAccessTokenSilently().then((token) => {
          getBookingsByCustomer(token, customer.id).then((response) => {
            setBookings(response.data);
          });
        });
      });
    });
  };

  useEffect(() => {
    if (customer && customer.id && showBookings) {
      getAccessTokenSilently().then((token) => {
        getBookingsByCustomer(token, customer.id).then((response) => {
          setBookings(response.data);
        });
      });
    }
  }, [customer, customer.id, getAccessTokenSilently, showBookings]);

  return (
    <Card>
      <CardContent>
        <Box mb={3}>
          {errors.onSave && <Alert severity="error">{errors.onSave}</Alert>}
        </Box>
        <Box mb={3}>
          <TextField
            autoComplete="off"
            required
            fullWidth
            label={t("components.customer-management.form.email.label")}
            name="email"
            id="email"
            type="email"
            error={!!errors.email}
            helperText={errors.email}
            onChange={onChange}
            onBlur={onBlur}
            value={customer.email}
          />
        </Box>
        <Box mb={3}>
          <TextField
            autoComplete="off"
            fullWidth
            label={t("components.customer-management.form.fullName.label")}
            name="fullName"
            id="fullName"
            type="text"
            error={!!errors.fullName}
            helperText={errors.fullName}
            onChange={onChange}
            onBlur={onBlur}
            value={customer.fullName}
          />
        </Box>
        <Box mb={3}>
          <TextField
            autoComplete="off"
            fullWidth
            label={t("components.customer-management.form.phone.label")}
            name="phone"
            id="phone"
            type="tel"
            error={!!errors.phone}
            helperText={errors.phone}
            onChange={onChange}
            onBlur={onBlur}
            value={customer.phone ? customer.phone : ""}
          />
        </Box>
        <Box mb={3}>
          <TextField
            autoComplete="off"
            fullWidth
            label={t("components.customer-management.form.language.label")}
            name="language"
            id="language"
            type="text"
            InputProps={{
              readOnly: true,
            }}
            value={customer.language ? customer.language : ""}
          />
        </Box>
        <Box mb={3}>
          <DatePicker
            fullWidth
            autoOk
            id="dateOfBirth"
            name="dateOfBirth"
            label={t("components.customer-management.form.dateOfBirth.label")}
            format="dd/MM/yyyy"
            value={customer.dateOfBirth}
            onChange={(value) =>
              onChange({ target: { name: "dateOfBirth", value: value } })
            }
            onBlur={onBlur}
            helperText={errors.dateOfBirth}
            error={errors.dateOfBirth}
          />
        </Box>
        <Box mb={3}>
          <AddressPicker
            label={t("components.customer-management.form.address.label")}
            address={customer.address}
            errors={{ ...errors.address }}
            onSave={(address) => {
              onChange({
                target: {
                  name: "address",
                  value: address,
                },
              });
            }}
          />
        </Box>
        {showBookings && (
          <>
            <Box mt={6}>
              <AppBar position="static">
                <Tabs value={0}>
                  <Tab
                    label={t("components.customer-management.tabs.bookings")}
                  />
                </Tabs>
              </AppBar>
              <CommonGrid
                columns={columns}
                items={bookings}
                defaultColumnWidths={[
                  { columnName: "ticket", width: "auto" },
                  { columnName: "tripName", width: "auto" },
                  { columnName: "slot", width: "auto" },
                  { columnName: "created", width: "auto" },
                  { columnName: "stateName", width: "auto" },
                  { columnName: "actions", width: 100 },
                ]}
              />
            </Box>
            <ConfirmationDialog
              open={openCancelConfirmation}
              onCancel={handleOnCancelCancelConfirmation}
              onAccept={handleOnAcceptCancelConfirmation}
              message={t("pages.bookings.cancel-booking.message")}
              title={t("pages.bookings.cancel-booking.title")}
            />
          </>
        )}
      </CardContent>
      {showCancelButton && (
        <CardActions style={{ justifyContent: "flex-end" }}>
          <Button variant="contained" color="secondary" onClick={onCancel}>
            {t("components.customer-management.buttons.close")}
          </Button>
        </CardActions>
      )}
    </Card>
  );
};

ManageCustomerForm.propTypes = {
  customer: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  onBlur: PropTypes.func.isRequired,
  showCancelButton: PropTypes.bool,
  showBookings: PropTypes.bool,
};

export default ManageCustomerForm;

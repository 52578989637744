import React from "react";
import PropTypes from "prop-types";
import { Box, TextField } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useTranslation } from "react-i18next";

const ManageAddressForm = ({ address, onChange, errors }) => {
  const { t } = useTranslation();
  return (
    <>
      <Box mb={3}>
        {errors.onSave && <Alert severity="error">{errors.onSave}</Alert>}
      </Box>
      <Box mb={3}>
        <TextField
          autoComplete="off"
          fullWidth
          label={t("components.manage-address-dialog.form.addressLine.label")}
          name="addressLine"
          id="addressLine"
          type="text"
          error={!!errors.addressLine}
          helperText={errors.addressLine}
          onChange={onChange}
          value={address.addressLine}
        />
      </Box>
      <Box mb={3}>
        <TextField
          autoComplete="off"
          fullWidth
          label={t("components.manage-address-dialog.form.postCode.label")}
          name="postCode"
          id="postCode"
          type="text"
          error={!!errors.postCode}
          helperText={errors.postCode}
          onChange={onChange}
          value={address.postCode}
        />
      </Box>
      <Box mb={3}>
        <TextField
          autoComplete="off"
          fullWidth
          label={t("components.manage-address-dialog.form.city.label")}
          name="city"
          id="city"
          type="text"
          error={!!errors.city}
          helperText={errors.city}
          onChange={onChange}
          value={address.city}
        />
      </Box>
      <Box mb={3}>
        <TextField
          autoComplete="off"
          fullWidth
          label={t("components.manage-address-dialog.form.country.label")}
          name="country"
          id="country"
          type="text"
          error={!!errors.country}
          helperText={errors.country}
          onChange={onChange}
          value={address.country}
        />
      </Box>
    </>
  );
};

ManageAddressForm.propTypes = {
  address: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

export default ManageAddressForm;

import axios from "axios";

import { createAuthHeader } from "./apiUtils";

const baseUrl = `${process.env.REACT_APP_AFFILIATES_API}/api/rewards`;

export function getMonthSummary(token, year, month) {
  return axios.get(
    `${baseUrl}/month-summary/${year}/${month}`,
    createAuthHeader(token)
  );
}

export function getMonthStatistics(token, year, month) {
  return axios.get(
    `${baseUrl}/month-statistics/${year}/${month}`,
    createAuthHeader(token)
  );
}

export function withdraw(token, year, month) {
  return axios.get(
    `${baseUrl}/withdraw/${year}/${month}`,
    createAuthHeader(token)
  );
}

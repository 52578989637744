import React, { useEffect, useState } from "react";
import PageContainer from "../components/common/page/pageContainer";
import { useParams } from "react-router";
import { useAuth0 } from "@auth0/auth0-react";
import { getBooking } from "../api/bookingsApi";
import SummaryAndPayment from "./../components/bookings/SummaryAndPayment";
import { useTranslation } from "react-i18next";

const BookingViewPage = () => {
  const { t } = useTranslation();
  const breadcrumbs = [
    { label: t("pages.home.title"), link: "/" },
    { label: t("pages.bookings.title"), link: "/bookings" },
    { label: t("pages.view-booking.title"), isActive: true },
  ];
  const { bookingId } = useParams();
  const { getAccessTokenSilently } = useAuth0();
  const [booking, setBooking] = useState(null);

  useEffect(() => {
    if (bookingId) {
      getAccessTokenSilently().then((token) => {
        getBooking(token, bookingId).then((response) => {
          setBooking({ ...response.data });
        });
      });
    }
  }, [bookingId, getAccessTokenSilently]);

  return (
    <PageContainer
      heading={t("pages.view-booking.title")}
      breadcrumbs={breadcrumbs}
    >
      {booking && <SummaryAndPayment booking={booking} />}
    </PageContainer>
  );
};

BookingViewPage.propTypes = {};

export default BookingViewPage;

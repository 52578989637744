import React from "react";
import { Card, CardContent } from "@material-ui/core";
import PageContainer from "../components/common/page/pageContainer";
import { useTranslation } from "react-i18next";
import BookingsGrid from "../components/bookings/BookingsGrid";

const BookingsPage = () => {
  const { t } = useTranslation();

  const breadcrumbs = [
    { label: t("pages.home.title"), link: "/" },
    { label: t("pages.bookings.title"), isActive: true },
  ];

  return (
    <PageContainer
      heading={t("pages.bookings.title")}
      breadcrumbs={breadcrumbs}
    >
      <Card>
        <CardContent>
          <BookingsGrid />
        </CardContent>
      </Card>
    </PageContainer>
  );
};

export default BookingsPage;

import { Box, Card, CardContent, Grid } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import PageContainer from "../components/common/page/pageContainer";

import Counters from "../components/statistics/Counters";
import Statistics from "../components/statistics/Statistics";
import Planning from "../components/planning/Planning";
import { useContext } from "react";
import AppContext from "../components/common/providers/AppContext";
import { useEffect } from "react";
import { sidebarNavs } from "../menu";

const HomeAdministratorPage = () => {
  const { setSidebarItems } = useContext(AppContext);

  const { t } = useTranslation();
  const breadcrumbs = [
    { label: t("pages.home-administrator.title"), isActive: true },
  ];

  useEffect(() => {
    setSidebarItems(sidebarNavs);
  }, [setSidebarItems]);

  return (
    <PageContainer
      heading={t("pages.home-administrator.title")}
      breadcrumbs={breadcrumbs}
    >
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box
                mt={6}
                textAlign="center"
                fontWeight="fontWeightBold"
                fontSize="20px"
              >
                {t("pages.home-administrator.statistics.title")}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Counters />
            </Grid>
            <Grid item xs={12}>
              <Statistics />
            </Grid>
            <Grid item xs={12}>
              <Box
                mt={6}
                textAlign="center"
                fontWeight="fontWeightBold"
                fontSize="20px"
              >
                {t("pages.home-administrator.planning.title")}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Planning />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </PageContainer>
  );
};

export default HomeAdministratorPage;

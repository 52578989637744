import React, { useState, useMemo, useEffect, useContext } from "react";

import { getBackgroundStyle, getOverLayStyle } from "../helpers/jssHelpers";

import AppContext from "./AppContext";
import sidebarThemeColors from "../sidebar/sidebarThemeColors";
import SidebarContext from "./SidebarContext";

const SidebarContextProvider = ({ children }) => {
  const { themeType } = useContext(AppContext);

  const [activeTheme, setActiveTheme] = useState({
    ...sidebarThemeColors[themeType],
    backgroundColor: "",
    backgroundImage: "",
    gradientDirection: "",
  });

  useEffect(() => {
    setActiveTheme({
      ...sidebarThemeColors[themeType],
    });
  }, [themeType]);

  const sidebarContextValue = useMemo(() => {
    const backgroundStyle = getBackgroundStyle(
      activeTheme.backgroundColor,
      activeTheme.backgroundImage,
      activeTheme.gradientDirection
    );

    const overlayStyle = getOverLayStyle({
      colors: activeTheme.backgroundColor,
      opacity: 0.6,
      direction: "180deg",
    });

    return {
      sidebarTheme: activeTheme,
      backgroundStyle: backgroundStyle,
      overlayStyle: overlayStyle,
      setSidebarTheme: setActiveTheme,
    };
  }, [activeTheme]);

  return (
    <SidebarContext.Provider value={sidebarContextValue}>
      {children}
    </SidebarContext.Provider>
  );
};

export default SidebarContextProvider;

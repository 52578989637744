import React, { useContext } from "react";
import SidebarContext from "../providers/SidebarContext";

const SidebarContainer = ({ children, classes }) => {
  const { backgroundStyle, overlayStyle } = useContext(SidebarContext);

  return (
    <div className={`${classes.appSidebar} Cmt-sidebar`}>
      <div
        className={`${classes.appSidebarContent} Cmt-sidebar-content`}
        style={backgroundStyle}
      >
        {children}
        {overlayStyle && (
          <div
            className={`${classes.overlayRoot} Cmt-Drawer-overlay`}
            style={overlayStyle}
          />
        )}
      </div>
    </div>
  );
};

export default SidebarContainer;

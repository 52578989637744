const defaultCustomer = {
  email: "",
  phone: "",
  fullName: "",
  dateOfBirth: null,
  address: null,
  language: "",
};

export default defaultCustomer;

import React from "react";
import PageContainer from "../components/common/page/pageContainer";
import { useTranslation } from "react-i18next";
import PaymentInformation from "../components/affiliate/PaymentInformation";

const PaymentInformationPage = () => {
  const { t } = useTranslation();

  const breadcrumbs = [
    { label: t("pages.home-associate.title"), link: "/home-affiliate" },
    { label: t("pages.payment-information.title"), isActive: true },
  ];

  return (
    <PageContainer
      heading={t("pages.payment-information.title")}
      breadcrumbs={breadcrumbs}
    >
      <PaymentInformation />
    </PageContainer>
  );
};

export default PaymentInformationPage;

import { useAuth0 } from "@auth0/auth0-react";
import { createRowCache } from "@devexpress/dx-react-grid";
import React from "react";
import { useMemo } from "react";
import { useEffect } from "react";
import { useReducer } from "react";
import { useTranslation } from "react-i18next";
import { approveAccount, getAssociates } from "../../api/accountsApi";
import { createQueryURL } from "../../api/apiUtils";
import CommonGridExtended from "../common/grids/CommonGridExtended";
import {
  initialState,
  MAX_ROWS,
  reducer,
  VIRTUAL_PAGE_SIZE,
} from "../common/grids/commonGridExtenderUtils";

const AssociatesGrid = () => {
  const { t } = useTranslation();

  const columns = [
    { name: "email", title: t("components.affiliates-grid.email") },
    {
      name: "created",
      title: t("components.affiliates-grid.created"),
      type: "date",
    },
    {
      name: "validated",
      title: t("components.affiliates-grid.isValidated"),
      type: "bool",
    },
    {
      name: "actions",
      title: t("components.affiliates-grid.actions"),
      actions: [
        {
          name: "Approve",
          run: (item) => {
            getAccessTokenSilently().then((token) => {
              approveAccount(token, item.id)
                .then(() => {
                  dispatch({ type: "FORCE_RELOAD" });
                })
                .catch(() => dispatch({ type: "REQUEST_ERROR" }));
            });
          },
          isActionVisible: (item) => !item.validated,
        },
      ],
      allowSorting: false,
      allowFiltering: false,
      width: 100,
      type: "actions",
    },
  ];
  const [state, dispatch] = useReducer(reducer, initialState);

  const { getAccessTokenSilently } = useAuth0();

  const cache = useMemo(() => createRowCache(VIRTUAL_PAGE_SIZE), []);

  const updateRows = (skip, count, newTotalCount) => {
    dispatch({
      type: "UPDATE_ROWS",
      payload: {
        skip: Math.min(skip, newTotalCount),
        rows: cache.getRows(skip, count),
        totalCount: newTotalCount < MAX_ROWS ? newTotalCount : MAX_ROWS,
      },
    });
  };

  const loadData = () => {
    const {
      requestedSkip,
      take,
      lastQuery,
      loading,
      forceReload,
      totalCount,
      filters,
      sorting,
    } = state;

    const loadOptions = {
      take,
      filters,
      sorting,
      skip: requestedSkip,
    };
    const query = createQueryURL("", loadOptions);

    if ((query !== lastQuery || forceReload) && !loading) {
      if (forceReload) {
        cache.invalidate();
      }

      const cached = cache.getRows(requestedSkip, take);

      if (cached.length === take) {
        updateRows(requestedSkip, take, totalCount);
      } else {
        dispatch({ type: "FETCH_INIT" });

        getAccessTokenSilently().then((token) => {
          getAssociates(token, loadOptions)
            .then((response) => {
              cache.setRows(requestedSkip, response.data.data);
              updateRows(requestedSkip, take, response.data.totalCount);
            })
            .catch(() => dispatch({ type: "REQUEST_ERROR" }));
        });
      }
      dispatch({ type: "UPDATE_QUERY", payload: query });
    }
  };

  const handleOnChangeFilters = (value) => {
    dispatch({ type: "CHANGE_FILTERS", payload: value });
  };

  const handleOnSortingChange = (value) => {
    dispatch({ type: "CHANGE_SORTING", payload: value });
  };

  const handleOnGetRows = (requestedSkip, take) => {
    dispatch({ type: "START_LOADING", payload: { requestedSkip, take } });
  };

  useEffect(() => loadData());

  return (
    <CommonGridExtended
      state={state}
      columns={columns}
      onChangeFilters={handleOnChangeFilters}
      onGetRows={handleOnGetRows}
      onSortingChange={handleOnSortingChange}
    ></CommonGridExtended>
  );
};

export default AssociatesGrid;

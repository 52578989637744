import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  pageHeaderRoot: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      alignItems: "center",
      flexDirection: "row",
    },
  },
  titleRoot: {
    [theme.breakpoints.down("xs")]: {
      marginBottom: 10,
    },
  },
}));

export default useStyles;

import React from "react";
import PageContainer from "../components/common/page/pageContainer";
import { Card, CardContent } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import CustomersGrid from "../components/customers/CustomersGrid";

const CustomersPage = () => {
  const { t } = useTranslation();

  const breadcrumbs = [
    { label: t("pages.home.title"), link: "/" },
    { label: t("pages.customers.title"), isActive: true },
  ];

  return (
    <PageContainer
      heading={t("pages.customers.title")}
      breadcrumbs={breadcrumbs}
    >
      <Card>
        <CardContent>
          <CustomersGrid />
        </CardContent>
      </Card>
    </PageContainer>
  );
};

export default CustomersPage;

import React from "react";
import PropTypes from "prop-types";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Box, Link } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

const WithdrawAlert = ({ show }) => {
  const { t } = useTranslation();

  return (
    show && (
      <Alert severity="success">
        <AlertTitle>{t("components.withdraw-alert.title")}</AlertTitle>
        <Box display="flex">
          <Box>{t("components.withdraw-alert.message")}</Box>
          <Box ml={2}>
            <Link component={RouterLink} to="/my-credit-slips">
              {t("components.withdraw-alert.link")}
            </Link>
          </Box>
        </Box>
      </Alert>
    )
  );
};

WithdrawAlert.propTypes = {
  show: PropTypes.bool.isRequired,
};

export default WithdrawAlert;

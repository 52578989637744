import React from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { useContext } from "react";
import AppContext from "./providers/AppContext";
import { useState } from "react";
import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const ProtectedRoute = ({ children, authorizedRoles }) => {
  const { roles, setRoles } = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const { getIdTokenClaims } = useAuth0();
  const [retrievedRoles, setRetrievedRoles] = useState();

  useEffect(() => {
    if ((!roles || roles.length === 0) && loading) {
      getIdTokenClaims().then((claims) => {
        setRoles(claims.user_roles);
        setRetrievedRoles(claims.user_roles);
        setLoading(false);
      });
    }
  }, [getIdTokenClaims, loading, setRoles, roles]);

  if (!loading) {
    if (!retrievedRoles || retrievedRoles.length === 0) {
      return <Redirect replace to="/unauthorized" />;
    } else if (authorizedRoles.some((r) => retrievedRoles.includes(r))) {
      return children;
    } else return <Redirect replace to="/unauthorized" />;
  } else {
    return null;
  }
};

ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired,
  authorizedRoles: PropTypes.array.isRequired,
};

export default ProtectedRoute;

import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import ManageExtraForm from "./ManageExtraForm";

const ManageExtraDialog = ({
  open,
  onClose,
  onChange,
  onSave,
  errors,
  extra,
  onThumbnailChange,
}) => {
  const { t } = useTranslation();

  return extra ? (
    <Dialog maxWidth="md" open={open} onClose={onClose} fullWidth>
      <DialogTitle>
        {extra.id
          ? t("components.manage-trip-extra-dialog.edit-title")
          : t("components.manage-trip-extra-dialog.add-title")}
      </DialogTitle>
      <DialogContent>
        <ManageExtraForm
          errors={errors}
          onChange={onChange}
          onThumbnailChange={onThumbnailChange}
          extra={extra}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose} color="secondary">
          {t("components.manage-trip-extra-dialog.buttons.cancel")}
        </Button>
        <Button variant="contained" onClick={onSave} color="primary">
          {t("components.manage-trip-extra-dialog.buttons.save")}
        </Button>
      </DialogActions>
    </Dialog>
  ) : null;
};

ManageExtraDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  extra: PropTypes.object,
  onThumbnailChange: PropTypes.func.isRequired,
};

export default ManageExtraDialog;

import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import PropTypes from "prop-types";
import ManageTripSlotForm from "./ManageTripSlotForm";
import { useTranslation } from "react-i18next";

const ManageTripSlotDialog = ({
  open,
  onClose,
  onChange,
  onSave,
  errors,
  tripSlot,
}) => {
  const { t } = useTranslation();

  return tripSlot ? (
    <Dialog maxWidth="md" open={open} onClose={onClose} fullWidth>
      <DialogTitle>
        {tripSlot.id
          ? t("components.manage-trip-slot-dialog.edit-title")
          : t("components.manage-trip-slot-dialog.add-title")}
      </DialogTitle>
      <DialogContent>
        <ManageTripSlotForm
          onChange={onChange}
          errors={errors}
          tripSlot={tripSlot}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose} color="secondary">
          {t("components.manage-trip-slot-dialog.buttons.cancel")}
        </Button>
        <Button variant="contained" onClick={onSave} color="primary">
          {t("components.manage-trip-slot-dialog.buttons.save")}
        </Button>
      </DialogActions>
    </Dialog>
  ) : null;
};

ManageTripSlotDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  tripSlot: PropTypes.object,
};

export default ManageTripSlotDialog;

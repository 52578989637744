import React from "react";
import PropTypes from "prop-types";
import { Drawer } from "@material-ui/core";
import { getBackgroundStyle, getOverLayStyle } from "../helpers/jssHelpers";
import useStyles from "./CustomDrawer.style";

const CustomDrawer = ({
  backgroundColor,
  backgroundImage,
  gradientDirection,
  children,
  overlay,
  ...rest
}) => {
  const backgroundStyles = getBackgroundStyle(
    backgroundColor,
    backgroundImage,
    gradientDirection
  );

  const overlayStyles = getOverLayStyle(overlay);

  const classes = useStyles();

  return (
    <Drawer {...rest}>
      <div
        className={`${classes.drawerContainer} Cmt-Drawer-container`}
        style={backgroundStyles}
      >
        <div className={`${classes.drawerContent} Cmt-Drawer-content`}>
          {children}
        </div>
        {overlay.colors && (
          <div
            className={`${classes.overlayRoot} Cmt-Drawer-overlay`}
            style={overlayStyles}
          />
        )}
      </div>
    </Drawer>
  );
};

CustomDrawer.propTypes = {
  backgroundColor: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  backgroundImage: PropTypes.string,
  gradientDirection: PropTypes.string,
  overlay: PropTypes.object,
};

CustomDrawer.defaultProps = {
  overlay: { colors: "", opacity: 0, direction: "" },
};

export default React.memo(CustomDrawer);

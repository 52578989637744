import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import TripSelector from "../trips/TripSelector";
import { ViewState } from "@devexpress/dx-react-scheduler";
import {
  Scheduler,
  WeekView,
  DateNavigator,
  Appointments,
  TodayButton,
  MonthView,
  DayView,
  Toolbar,
  ViewSwitcher,
} from "@devexpress/dx-react-scheduler-material-ui";
import { format } from "date-fns";
import { useAuth0 } from "@auth0/auth0-react";
import { getAvailables, getTripSlots } from "../../api/tripsApi";
import { useTranslation } from "react-i18next";

const Appointment = ({ children, style, onClick, ...restProps }) => {
  const { data } = restProps;

  return (
    <Appointments.Appointment
      {...restProps}
      onClick={(event) => {
        onClick(event.data);
      }}
      style={{
        ...style,
        backgroundColor: data.isFull ? "#ff1744" : "#4caf50",
      }}
    >
      {children}
    </Appointments.Appointment>
  );
};

const TripDataForm = ({
  errors,
  booking,
  trip,
  onSelectedTrip,
  onSelectedTripSlot,
  onSelectedDepartureTime,
}) => {
  const { t } = useTranslation();
  const [trips, setTrips] = useState([]);
  const { getAccessTokenSilently } = useAuth0();
  const [currentViewName, setCurrentViewName] = useState("Week");
  const [currentDate, setCurrentDate] = useState(
    format(new Date(), "yyyy/MM/dd")
  );

  const [appointments, setAppointments] = useState([]);
  const handleOnClick = (appointment) => {
    onSelectedTripSlot(appointment);
  };

  useEffect(() => {
    if (trip) {
      getAccessTokenSilently().then((token) => {
        getTripSlots(token, trip.id).then((response) => {
          setAppointments(
            response.data.data.map((x) => ({
              ...x,
              title: trip.name,
              startDate: new Date(x.slotDate.startDate),
              endDate: new Date(x.slotDate.endDate),
              isFull: x.availableNumberOfPeople === 0,
            }))
          );
        });
      });
    }
  }, [trip, getAccessTokenSilently]);

  useEffect(() => {
    getAccessTokenSilently().then((token) => {
      getAvailables(token, {}).then((response) => {
        setTrips(response.data.data);
      });
    });
  }, [getAccessTokenSilently]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={6}>
        <Box mx={4}>
          <TripSelector
            trips={trips}
            trip={trip ? trips.find((x) => x.id === trip.id) : null}
            errors={errors}
            onChange={onSelectedTrip}
          />
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box mx={4}>
          <TextField
            autoComplete="off"
            fullWidth
            InputProps={{
              readOnly: true,
            }}
            label={t("components.trip-data-form.slot.label")}
            name="slot"
            id="slot"
            type="text"
            error={!!errors.selectedTripSlotId}
            helperText={errors.selectedTripSlotId}
            value={
              booking.tripSlot && booking.tripSlot.id
                ? `${format(
                    new Date(booking.tripSlot.slotDate.startDate),
                    "E d MMM yyyy"
                  )} - From ${format(
                    new Date(booking.tripSlot.slotDate.startDate),
                    "HH:mm"
                  )} - To ${format(
                    new Date(booking.tripSlot.slotDate.endDate),
                    "HH:mm"
                  )}`
                : ""
            }
          />
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box mx={4}>
          <FormControl error={!!errors.departureOriginTime} fullWidth>
            <InputLabel>
              {t("components.trip-data-form.departureOriginTime.label")}
            </InputLabel>
            <Select
              name="departureOriginTime"
              value={booking.departureOriginTime}
              onChange={onSelectedDepartureTime}
            >
              {booking.tripSlot &&
                booking.tripSlot.departureOriginTimes.map((x, index) => (
                  <MenuItem key={index} value={x}>
                    {x}
                  </MenuItem>
                ))}
            </Select>
            <FormHelperText>{errors.departureOriginTime}</FormHelperText>
          </FormControl>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box mx={4}>
          <FormControl error={!!errors.departureDestinationTime} fullWidth>
            <InputLabel>
              {t("components.trip-data-form.departureOriginTime.label")}
            </InputLabel>
            <Select
              name="departureDestinationTime"
              value={booking.departureDestinationTime}
              onChange={onSelectedDepartureTime}
            >
              {booking.tripSlot &&
                booking.tripSlot.departureDestinationTimes.map((x, index) => (
                  <MenuItem key={index} value={x}>
                    {x}
                  </MenuItem>
                ))}
            </Select>
            <FormHelperText>{errors.departureDestinationTime}</FormHelperText>
          </FormControl>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Scheduler firstDayOfWeek={1} data={appointments}>
          <ViewState
            currentViewName={currentViewName}
            onCurrentViewNameChange={(currentView) => {
              setCurrentViewName(currentView);
            }}
            currentDate={currentDate}
            onCurrentDateChange={(date) => {
              setCurrentDate(date);
            }}
          />
          <WeekView startDayHour={7} endDayHour={20} />
          <MonthView />
          <DayView />
          <Toolbar />
          <DateNavigator />
          <TodayButton />
          <ViewSwitcher />
          <Appointments
            appointmentComponent={(props) => (
              <Appointment {...props} onClick={handleOnClick} />
            )}
          />
        </Scheduler>
      </Grid>
    </Grid>
  );
};

TripDataForm.propTypes = {
  errors: PropTypes.object.isRequired,
  booking: PropTypes.object.isRequired,
  trip: PropTypes.object,
  onSelectedTrip: PropTypes.func.isRequired,
  onSelectedTripSlot: PropTypes.func.isRequired,
  onSelectedDepartureTime: PropTypes.func.isRequired,
};

export default TripDataForm;

import React, { useContext } from "react";
import SidebarContext from "../providers/SidebarContext";
import { useMediaQuery, useTheme } from "@material-ui/core";
import useStyles from "./Sidebar.style";
import SidebarDrawer from "./SidebarDrawer";
import SidebarContainer from "./SidebarContainer";
import LayoutContext from "../providers/LayoutContext";

const Sidebar = ({ children }) => {
  const {
    drawerBreakPoint,
    sidebarWidth,
    miniSidebarWidth,
    actionSidebarWidth,
    sidebarType,
  } = useContext(LayoutContext);

  const { sidebarTheme } = useContext(SidebarContext);

  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down(drawerBreakPoint));

  const hasDrawer = sidebarType === "drawer" || matches;

  const classes = useStyles({
    sidebarWidth,
    miniSidebarWidth,
    actionSidebarWidth,
    sidebarTheme,
  });

  if (hasDrawer) {
    return <SidebarDrawer classes={classes}>{children}</SidebarDrawer>;
  } else {
    return <SidebarContainer classes={classes}>{children}</SidebarContainer>;
  }
};

export default Sidebar;

import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  ClickAwayListener,
  FilledInput,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Paper,
  Popper,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import { getCustomers } from "../../api/customersApi";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  paper: {
    overflow: "auto",
  },
  popper: {
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: "-0.9em",
      width: "3em",
      height: "1em",
      "&::before": {
        borderWidth: "0 1em 1em 1em",
        borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
      },
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: "-0.9em",
      width: "3em",
      height: "1em",
      "&::before": {
        borderWidth: "1em 1em 0 1em",
        borderColor: `${theme.palette.background.paper} transparent transparent transparent`,
      },
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: "-0.9em",
      height: "3em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 1em 1em 0",
        borderColor: `transparent ${theme.palette.background.paper} transparent transparent`,
      },
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: "-0.9em",
      height: "3em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 0 1em 1em",
        borderColor: `transparent transparent transparent ${theme.palette.background.paper}`,
      },
    },
  },
  arrow: {
    position: "absolute",
    fontSize: 7,
    width: "3em",
    height: "3em",
    "&::before": {
      content: '""',
      margin: "auto",
      display: "block",
      width: 0,
      height: 0,
      borderStyle: "solid",
    },
  },
}));

const CustomerPicker = ({ onClickOption }) => {
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();
  const [searchKeyword, setSearchKeyword] = useState("");
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [customers, setCustomers] = useState([]);

  const anchorRef = useRef(null);
  const [arrowRef, setArrowRef] = useState(null);

  const handleOnChange = (event) => {
    setSearchKeyword(event.target.value);
    setOpen(true);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const handleClearSearch = () => {
    setOpen(false);
    setSearchKeyword("");
  };

  useEffect(() => {
    if (searchKeyword) {
      getAccessTokenSilently().then((token) => {
        getCustomers(token, {
          filter: [
            ["email", "contains", searchKeyword],
            "or",
            ["fullName", "contains", searchKeyword],
          ],
          pageSize: 10,
        }).then((response) => {
          setCustomers(response.data.data);
        });
      });
    }
  }, [searchKeyword, getAccessTokenSilently]);
  return (
    <>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div>
          <div ref={anchorRef}>
            <FormControl variant="filled" fullWidth>
              <InputLabel variant="filled" htmlFor="customer-picker">
                {t("components.customer-picker.label")}
              </InputLabel>
              <FilledInput
                autoComplete="off"
                value={searchKeyword}
                onChange={handleOnChange}
                id="customer-picker"
                type="text"
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={handleClearSearch}>
                      <CloseIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </div>
          <Popper
            className={classes.popper}
            open={open}
            placement="bottom-start"
            anchorEl={anchorRef.current}
            modifiers={{
              flip: {
                enabled: true,
              },
              preventOverflow: {
                enabled: true,
                boundariesElement: "scrollParent",
              },
              arrow: {
                enabled: true,
                element: arrowRef,
              },
            }}
          >
            <span className={classes.arrow} ref={setArrowRef} />
            <Paper className={classes.paper}>
              <List>
                {customers.length === 0 && (
                  <ListItem button>
                    <ListItemText
                      onClick={() => {
                        handleClearSearch();
                        onClickOption();
                      }}
                    >
                      {t("components.customer-picker.customer-not-found")}
                    </ListItemText>
                  </ListItem>
                )}

                {customers.length > 0 &&
                  customers.map((customer, index) => (
                    <ListItem key={index} button>
                      <ListItemText
                        onClick={() => {
                          handleClearSearch();
                          onClickOption(customer);
                        }}
                      >{`${customer.fullName} (${customer.email})`}</ListItemText>
                    </ListItem>
                  ))}

                {customers.length > 0 && (
                  <ListItem button>
                    <ListItemText
                      onClick={() => {
                        handleClearSearch();
                        onClickOption();
                      }}
                    >
                      {t("components.customer-picker.click-to-add")}
                    </ListItemText>
                  </ListItem>
                )}
              </List>
            </Paper>
          </Popper>
        </div>
      </ClickAwayListener>
    </>
  );
};

CustomerPicker.propTypes = {
  onClickOption: PropTypes.func.isRequired,
};

export default CustomerPicker;

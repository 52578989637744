import DirectionsBoatIcon from "@material-ui/icons/DirectionsBoat";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import ContactsIcon from "@material-ui/icons/Contacts";
import HomeIcon from "@material-ui/icons/Home";
import { Assessment, Receipt } from "@material-ui/icons";
import GroupWorkIcon from "@material-ui/icons/GroupWork";

export const sidebarNavs = [
  {
    name: "main",
    type: "section",
    children: [
      {
        name: "home",
        type: "item",
        icon: <HomeIcon />,
        link: "/",
      },
      {
        name: "trips",
        type: "item",
        icon: <DirectionsBoatIcon />,
        link: "/trips",
      },
      {
        name: "bookings",
        type: "item",
        icon: <ShoppingCartIcon />,
        link: "/bookings",
      },
      {
        name: "customers",
        type: "item",
        icon: <ContactsIcon />,
        link: "/customers",
      },
      {
        name: "reports",
        type: "item",
        icon: <Assessment />,
        link: "/reports",
      },
      {
        name: "affiliates",
        type: "item",
        icon: <GroupWorkIcon />,
        link: "/affiliates",
      },
      {
        name: "affiliates-creditslip",
        type: "item",
        icon: <Receipt />,
        link: "/credit-slips",
      },
    ],
  },
];

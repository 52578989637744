import React from "react";
import { ViewState } from "@devexpress/dx-react-scheduler";
import {
  Scheduler,
  WeekView,
  DateNavigator,
  Appointments,
  TodayButton,
  MonthView,
  DayView,
  Toolbar,
  ViewSwitcher,
  AppointmentTooltip,
} from "@devexpress/dx-react-scheduler-material-ui";
import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getBookings } from "../../api/bookingsApi";
import { endOfWeek, format, setHours, setMinutes, startOfWeek } from "date-fns";
import { useState } from "react";
import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const Planning = () => {
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();
  const [appointments, setAppointments] = useState([]);
  const [startDate, setStartDate] = useState(new Date());

  const Content = ({ children, appointmentData, ...restProps }) => (
    <AppointmentTooltip.Content
      {...restProps}
      appointmentData={appointmentData}
    >
      <Box mt={5} pl={6}>
        {t("components.planning.numberOfAdults")}
        {appointmentData.numberOfAdults}
      </Box>
      <Box pl={6}>
        {t("components.planning.numberOfChildren")}
        {appointmentData.numberOfChildren}
      </Box>
    </AppointmentTooltip.Content>
  );

  const onCurrentDateChange = (date) => {
    setStartDate(date);
  };

  useEffect(() => {
    getAccessTokenSilently().then((token) => {
      getBookings(token, {
        filter: [
          [
            "date",
            ">=",
            format(
              startOfWeek(startDate, { weekStartsOn: 1 }),
              "yyyy-MM-dd'T'00:00:00'Z'"
            ),
          ],
          [
            "date",
            "<=",
            format(
              endOfWeek(startDate, { weekStartsOn: 1 }),
              "yyyy-MM-dd'T'00:00:00'Z'"
            ),
          ],
          [["state", "=", "Paid"], "or", ["state", "=", "TicketValidated"]],
        ],
      }).then((response) => {
        let data = response.data.data
          .map((x) => {
            return {
              date: x.date.split("T")[0],
              slot: x.slot,
              trip: x.trip,
              numberOfAdults: x.numberOfAdults,
              numberOfChildren: x.numberOfChildren,
            };
          })
          .reduce((acc, value) => {
            let index = acc.findIndex(
              (x) => x.date === value.date && x.slot === value.slot
            );

            if (index >= 0) {
              acc[index].numberOfAdults += value.numberOfAdults;
              acc[index].numberOfChildren += value.numberOfChildren;

              return acc;
            } else {
              return [...acc, { ...value }];
            }
          }, [])
          .map((x) => {
            let date = new Date(x.date);
            let startHours = Number(
              x.slot.split(" ")[1].split("-")[0].split(":")[0]
            );
            let startMinutes = Number(
              x.slot.split(" ")[1].split("-")[0].split(":")[1]
            );
            let endHours = Number(
              x.slot.split(" ")[1].split("-")[1].split(":")[0]
            );
            let endMinutes = Number(
              x.slot.split(" ")[1].split("-")[1].split(":")[1]
            );

            let startDate = setMinutes(
              setHours(date, startHours),
              startMinutes
            );
            let endDate = setMinutes(setHours(date, endHours), endMinutes);
            return {
              startDate: startDate,
              endDate: endDate,
              title: x.trip,
              numberOfAdults: x.numberOfAdults,
              numberOfChildren: x.numberOfChildren,
            };
          });
        setAppointments(data);
      });
    });
  }, [getAccessTokenSilently, startDate]);
  return (
    <Scheduler data={appointments} firstDayOfWeek={1}>
      <ViewState
        currentDate={startDate}
        onCurrentDateChange={onCurrentDateChange}
      />
      <WeekView startDayHour={7} endDayHour={20} />
      <MonthView />
      <DayView />
      <Toolbar />
      <DateNavigator />
      <TodayButton />
      <ViewSwitcher />
      <Appointments />
      <AppointmentTooltip contentComponent={Content} />
    </Scheduler>
  );
};

export default Planning;

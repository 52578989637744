import React, { useState, useMemo, useEffect } from "react";

import LayoutContext from "./LayoutContext";

import {
  HEADER_TYPE,
  FOOTER_TYPE,
  SIDEBAR_TYPE,
  DRAWER_BREAK_POINT,
  SIDEBAR_WIDTH,
} from "../constants/themeOptions";

const LayoutContextProvider = (props) => {
  const [isSidebarOpen, setSidebarOpen] = useState(
    props.isSidebarOpen ? props.isSidebarOpen : false
  );

  const [headerType] = useState(
    props.headerType ? props.headerType : HEADER_TYPE.FIXED
  );

  const [footerType] = useState(
    props.footerType ? props.footerType : FOOTER_TYPE.FIXED
  );

  const [sidebarType] = useState(
    props.sidebarType ? props.sidebarType : SIDEBAR_TYPE.MINI
  );

  const [isSidebarFixed] = useState(
    props.isSidebarFixed ? props.isSidebarFixed : true
  );

  const [miniSidebarWidth] = useState(
    props.miniSidebarWidth ? props.miniSidebarWidth : 80
  );

  const [sidebarWidth] = useState(
    props.sidebarWidth ? props.sidebarWidth : SIDEBAR_WIDTH.DEFAULT
  );

  const [actionSidebarWidth] = useState(
    props.actionSidebarWidth ? props.actionSidebarWidth : 0
  );

  const [drawerBreakPoint] = useState(
    props.drawerBreakPoint ? props.drawerBreakPoint : DRAWER_BREAK_POINT.MD
  );

  const handleSetSideBarOpen = (value) => {
    setSidebarOpen(value);
    sessionStorage.setItem("sidebar-open", value);
  };

  const layoutContextValue = useMemo(
    () => ({
      headerType,
      footerType,
      sidebarType,
      isSidebarFixed,
      miniSidebarWidth,
      sidebarWidth,
      actionSidebarWidth,
      drawerBreakPoint,
      isSidebarOpen,
      setSidebarOpen: handleSetSideBarOpen,
    }),
    [
      headerType,
      footerType,
      sidebarType,
      isSidebarFixed,
      miniSidebarWidth,
      actionSidebarWidth,
      sidebarWidth,
      drawerBreakPoint,
      isSidebarOpen,
    ]
  );

  useEffect(() => {
    document.body.classList.remove("layout-type-boxed", "layout-type-framed");
    document.body.classList.add("layout-type-fullwidth");

    let sideBarOpenPersisted = sessionStorage.getItem("sidebar-open");

    if (sideBarOpenPersisted != null) {
      setSidebarOpen(sideBarOpenPersisted === "true");
    }
  }, []);

  return (
    <LayoutContext.Provider value={layoutContextValue}>
      {props.children}
    </LayoutContext.Provider>
  );
};

export default LayoutContextProvider;

import React from "react";
import PropTypes from "prop-types";
import CustomerPicker from "../customers/CustomerPicker";
import { Box, Grid } from "@material-ui/core";
import CustomerManagement from "../customers/CustomerManagement";
import defaultCustomer from "../../domain/defaultCustomer";
import PeopleDataForm from "./PeopleDataForm";
import { Alert } from "@material-ui/lab";
import ExtrasDataForm from "./ExtrasDataForm";
import { addHours } from "date-fns";

const CustomerDataForm = ({
  errors,
  booking,
  onChange,
  onAddExtra,
  onDeleteExtra,
}) => {
  const handleOnClickOption = (customerFound) => {
    onChange({
      target: {
        value: customerFound ? customerFound : defaultCustomer,
        name: "customer",
      },
    });
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={6}>
        <Box m={6}>
          <CustomerPicker onClickOption={handleOnClickOption} />
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box m={6}>
          {!!errors.customer && (
            <Alert severity="error">{errors.customer}</Alert>
          )}
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <Box mx={6} mb={3}>
              {booking.customer && (
                <CustomerManagement
                  showCancelButton={false}
                  showBookings={false}
                  customer={booking.customer}
                  onCustomerSaved={(customer) =>
                    onChange({
                      target: {
                        value: customer,
                        name: "customer",
                      },
                    })
                  }
                />
              )}
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box mb={3}>
              {booking.customer && (
                <PeopleDataForm
                  booking={booking}
                  errors={errors}
                  onChange={onChange}
                />
              )}
            </Box>
            <Box mb={3}>
              {booking.customer &&
                addHours(new Date(), 48) <=
                  new Date(booking.tripSlot.slotDate.startDate) && (
                  <ExtrasDataForm
                    booking={booking}
                    errors={errors}
                    onChange={onChange}
                    onAddExtra={onAddExtra}
                    onDeleteExtra={onDeleteExtra}
                  />
                )}
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

CustomerDataForm.propTypes = {
  errors: PropTypes.object.isRequired,
  booking: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onAddExtra: PropTypes.func.isRequired,
  onDeleteExtra: PropTypes.func.isRequired,
};

export default CustomerDataForm;

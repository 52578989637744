import React from "react";
import { FormControl, MenuItem, Select, TableCell } from "@material-ui/core";

const ChoiceFilterCell = ({ filter, onFilter, column }) => {
  return (
    <TableCell sx={{ width: "100%", p: 1 }}>
      <FormControl fullWidth>
        <Select
          sx={{ fontSize: "14px", width: "100%" }}
          placeholder="Filter..."
          value={filter ? filter.value : ""}
          onChange={(e) =>
            onFilter(
              e.target.value
                ? { operation: "contains", value: e.target.value }
                : null
            )
          }
          inputProps={{
            style: { textAlign: "right", height: "inherit" },
          }}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {column.options.map((x, index) => (
            <MenuItem key={index} value={x}>
              {x}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </TableCell>
  );
};

export default ChoiceFilterCell;

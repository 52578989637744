import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(() => ({
  sideNavMenu: {
    ".Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &": {
      marginTop: 16,
    },
    position: "relative",
  },
}));

export default useStyles;

import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  TextField,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useTranslation } from "react-i18next";

const PeopleDataForm = ({ errors, onChange, onBlur, booking }) => {
  const { t } = useTranslation();
  return (
    <Card>
      <CardContent>
        <form autoComplete="off">
          <Box mb={3}>
            {errors.onSave && <Alert severity="error">{errors.onSave}</Alert>}
          </Box>
          <Box mb={3}>
            <TextField
              autoComplete="off"
              required
              fullWidth
              label={t("components.people-data-form.numberOfAdults.label")}
              name="numberOfAdults"
              id="numberOfAdults"
              type="number"
              error={!!errors.numberOfAdults}
              helperText={errors.numberOfAdults}
              onChange={onChange}
              onBlur={onBlur}
              value={booking.numberOfAdults}
            />
          </Box>
          <Box mb={3}>
            <TextField
              autoComplete="off"
              required
              fullWidth
              label={t("components.people-data-form.numberOfChildren.label")}
              name="numberOfChildren"
              id="numberOfChildren"
              type="number"
              error={!!errors.numberOfChildren}
              helperText={errors.numberOfChildren}
              onChange={onChange}
              onBlur={onBlur}
              value={booking.numberOfChildren}
            />
          </Box>
          <Box mb={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={booking.applyResidentsDiscount}
                  onChange={onChange}
                  name="applyResidentsDiscount"
                />
              }
              label={t(
                "components.people-data-form.applyResidentsDiscount.label"
              )}
            />
          </Box>
        </form>
      </CardContent>
    </Card>
  );
};

PeopleDataForm.propTypes = {
  booking: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
};

export default PeopleDataForm;

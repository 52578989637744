import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  TextField,
  Box,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useTranslation } from "react-i18next";
import AddressPicker from "../common/addressPicker/AddressPicker";

const PaymentInformationForm = ({
  errors,
  onChange,
  onSave,
  onCancel,
  paymentInformation,
}) => {
  const { t } = useTranslation();

  return (
    <Card>
      <CardContent>
        <Box mb={3}>
          {errors.onSave && <Alert severity="error">{errors.onSave}</Alert>}
        </Box>
        <Box mb={3}>
          <TextField
            focused
            autoComplete="off"
            fullWidth
            label={t(
              "components.payment-information.form.account-holder-name.label"
            )}
            name="accountHolderName"
            type="text"
            error={!!errors.accountHolderName}
            helperText={errors.accountHolderName}
            onChange={onChange}
            value={paymentInformation.accountHolderName}
          />
        </Box>
        <Box mb={3}>
          <TextField
            autoComplete="off"
            fullWidth
            label={t("components.payment-information.form.bank-name.label")}
            name="bankName"
            type="text"
            error={!!errors.bankName}
            helperText={errors.bankName}
            onChange={onChange}
            value={paymentInformation.bankName}
          />
        </Box>
        <Box mb={3}>
          <TextField
            autoComplete="off"
            required
            fullWidth
            label={t("components.payment-information.form.iban.label")}
            name="iban"
            type="text"
            error={!!errors.iban}
            helperText={errors.iban}
            onChange={onChange}
            value={paymentInformation.iban}
          />
        </Box>
        <Box mb={3}>
          <TextField
            autoComplete="off"
            required
            fullWidth
            label={t("components.payment-information.form.swift.label")}
            name="bic"
            type="text"
            error={!!errors.bic}
            helperText={errors.bic}
            onChange={onChange}
            value={paymentInformation.bic}
          />
        </Box>
        <Box mb={3}>
          <TextField
            autoComplete="off"
            required
            fullWidth
            label={t("components.payment-information.form.company-name.label")}
            name="companyName"
            type="text"
            error={!!errors.companyName}
            helperText={errors.companyName}
            onChange={onChange}
            value={paymentInformation.companyName}
          />
        </Box>
        <Box mb={3}>
          <TextField
            autoComplete="off"
            required
            fullWidth
            label={t("components.payment-information.form.vat-number.label")}
            name="vatNumber"
            type="text"
            error={!!errors.vatNumber}
            helperText={errors.vatNumber}
            onChange={onChange}
            value={paymentInformation.vatNumber}
          />
        </Box>
        <Box mb={3}>
          <AddressPicker
            label={t("components.payment-information.form.address.label")}
            address={paymentInformation.companyAddress}
            errors={errors}
            onSave={(companyAddress) => {
              onChange({
                target: {
                  name: "companyAddress",
                  value: companyAddress,
                },
              });
            }}
          />
        </Box>
        <Box mb={3}>
          <TextField
            InputProps={{
              readOnly: true,
            }}
            fullWidth
            label={t("components.payment-information.form.currency.label")}
            name="currency"
            type="text"
            error={!!errors.currency}
            helperText={errors.currency}
            onChange={onChange}
            value={paymentInformation.currency}
          />
        </Box>
      </CardContent>
      <CardActions style={{ justifyContent: "flex-end" }}>
        <Button variant="contained" color="secondary" onClick={onCancel}>
          {t("components.payment-information.buttons.close")}
        </Button>
        <Button variant="contained" color="primary" onClick={onSave}>
          {t("components.payment-information.buttons.save")}
        </Button>
      </CardActions>
    </Card>
  );
};

PaymentInformationForm.propTypes = {
  paymentInformation: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default PaymentInformationForm;

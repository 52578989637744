import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

const ConfirmationDialog = ({ open, onCancel, onAccept, message, title }) => {
  const { t } = useTranslation();

  return (
    <Dialog maxWidth="xs" open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>{message}</DialogContent>
      <DialogActions>
        <Button
          autoFocus
          variant="contained"
          onClick={onCancel}
          color="secondary"
        >
          {t("components.confirmation-dialog.cancel")}
        </Button>
        <Button variant="contained" onClick={onAccept} color="primary">
          {t("components.confirmation-dialog.ok")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default ConfirmationDialog;

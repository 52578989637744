import { Payment, Home, Receipt } from "@material-ui/icons";

export const sidebarNavs = [
  {
    name: "main",
    type: "section",
    children: [
      {
        name: "home",
        type: "item",
        icon: <Home />,
        link: "/",
      },
      {
        name: "payment-information",
        type: "item",
        icon: <Payment />,
        link: "/payment-information",
      },
      {
        name: "my-credit-slips",
        type: "item",
        icon: <Receipt />,
        link: "/my-credit-slips",
      },
    ],
  },
];

import React from "react";
import PropTypes from "prop-types";
import { Box, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  text: {
    fontWeight: "bold",
  },
}));

const AssociateId = ({ id }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Box display="flex" justifyContent="flex-end">
      <Box className={classes.text}>{t("components.associate-id.text")}</Box>
      <Box ml={2}>{id}</Box>
    </Box>
  );
};

AssociateId.propTypes = {
  id: PropTypes.string.isRequired,
};

export default AssociateId;

export const DRAWER_BREAK_POINT = {
  SX: "sx",
  SM: "sm",
  MD: "md",
};

export const HEADER_TYPE = {
  FIXED: "fixed",
  STATIC: "static",
};

export const FOOTER_TYPE = {
  FIXED: "fixed",
};

export const SIDEBAR_TYPE = {
  FULL: "full",
  MINI: "mini",
  DRAWER: "drawer",
};

export const Fonts = {
  PRIMARY: "Roboto",
};

export const THEME_TYPES = {
  DARK: "dark",
  SEMI_DARK: "semi-dark",
  LIGHT: "light",
};

export const LAYOUT_STYLES = {
  FULL_WIDTH: "full-width",
};

export const LAYOUT_TYPES = {
  VERTICAL_MINIMAL: "vertical-minimal",
};

export const SIDEBAR_WIDTH = {
  SMALL: 280,
  DEFAULT: 304,
  WIDE: 330,
};

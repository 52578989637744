export const sidebarLightTheme = {
  bgColor: "#008dd2",
  textColor: "rgba(255, 255, 255, 0.8)",
  textDarkColor: "#fff",
  textActiveColor: "#000",
  navHoverBgColor: "rgba(255, 255, 255, 0.3)",
  navActiveBgColor: "#fff",
  borderColor: "rgba(255, 255, 255, 0.1)",
};

export const sidebarSemiDarkTheme = {
  bgColor: "#363636",
  textColor: "rgba(255, 255, 255, 0.3)",
  textDarkColor: "#fff",
  textActiveColor: "#fff",
  navHoverBgColor: "rgba(187, 134, 252, 0.08)",
  navActiveBgColor: "#3f51b5",
  borderColor: "rgba(255, 255, 255, 0.08)",
};

export const sidebarDarkTheme = {
  bgColor: "#363636",
  textColor: "rgba(255, 255, 255, 0.3)",
  textDarkColor: "#fff",
  textActiveColor: "#fff",
  navHoverBgColor: "rgba(187, 134, 252, 0.08)",
  navActiveBgColor: "#3f51b5",
  borderColor: "rgba(255, 255, 255, 0.08)",
};

const sidebarThemeColors = {
  light: sidebarLightTheme,
  "semi-dark": sidebarSemiDarkTheme,
  dark: sidebarDarkTheme,
};

export default sidebarThemeColors;

import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  navCollapseBtn: {
    position: "relative",
    padding: "0 16px 0 0",
    "& .MuiTouchRipple-root": {
      display: "none",
    },
    "&:hover, &:focus": {
      backgroundColor: "transparent",
    },
    ".Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &": {
      paddingLeft: 16,
    },
  },
  navCollapse: {
    position: "relative",
    "&.open": {
      "& .Cmt-navCollapseBtn": {
        color: (props) => props.sidebarTheme.textDarkColor,
      },
      "& .Cmt-iconRoot": {
        color: theme.palette.primary.main,
      },
    },
    "& .Cmt-navHeader": {
      paddingLeft: 36,
    },
  },

  navCollapseBtnInner: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    padding: "9px 16px 9px 32px",
    borderTopRightRadius: 30,
    borderBottomRightRadius: 30,
    "&:hover, &:focus": {
      color: (props) => props.sidebarTheme.textDarkColor,
      backgroundColor: (props) => props.sidebarTheme.navHoverBgColor,
      "& .Cmt-iconRoot": {
        color: (props) => props.sidebarTheme.textDarkColor,
      },
    },
    ".Cmt-miniLayout &": {
      paddingRight: 13,
      paddingLeft: 13,
    },
    ".Cmt-miniLayout .Cmt-sidebar-content:hover &": {
      paddingRight: 16,
      paddingLeft: 32,
    },
  },
  navText: {
    flex: 1,
    fontSize: 14,
    letterSpacing: 0.25,
    whiteSpace: "nowrap",
    ".Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &": {
      display: "none",
    },
  },
  iconRoot: {
    marginRight: 16,
    fontSize: 20,
  },
  navArrow: {
    position: "absolute",
    left: 8,
    top: "50%",
    zIndex: 1,
    transform: "translateY(-50%)",
    fontSize: 16,
    ".Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &": {
      display: "none",
    },
  },
  navCollapseItem: {
    position: "relative",
    "& .Cmt-icon-root": {
      transition: "all 0.3s ease",
      transform: "translateX(-100%)",
      opacity: 0,
      visibility: "hidden",
      marginRight: 0,
    },
    "& .Cmt-nav-text": {
      transition: "all 0.35s ease",
      marginLeft: -20,
    },
    "& .Cmt-nav-menu-link": {
      padding: "9px 16px 9px 70px",
      transition: "all 0.3s ease",
      "&:hover, &:focus": {
        "& .Cmt-icon-root": {
          transform: "translateX(0)",
          marginRight: 10,
          opacity: 1,
          visibility: "visible",
        },
        "& .Cmt-nav-text": {
          marginLeft: 0,
        },
      },
      "&.active": {
        "& .Cmt-icon-root": {
          transform: "translateX(0)",
          marginRight: 10,
          opacity: 1,
          visibility: "visible",
        },
        "& .Cmt-nav-text": {
          marginLeft: 0,
        },
      },
      ".Cmt-miniLayout &": {
        paddingRight: 13,
        paddingLeft: 13,
      },
      ".Cmt-miniLayout .Cmt-sidebar-content:hover &": {
        paddingRight: 16,
        paddingLeft: 70,
      },
    },
    ".Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &": {
      display: "none",
    },
  },
}));

export default useStyles;

import React from "react";
import PropTypes from "prop-types";

import { Avatar } from "@material-ui/core";
import { componentColors } from "../helpers/jssHelpers";
import useStyles from "./CustomAvatar.style";

const colorOptions = [...componentColors, "random"];

const getRandomColor = () => {
  return colorOptions[Math.floor(Math.random() * 11)];
};

const CustomAvatar = ({
  src,
  alt,
  className,
  color,
  size,
  phCharLength,
  children,
  ...rest
}) => {
  const classes = useStyles({ size: typeof size === "number" ? size : 0 });
  const colorClass =
    color === "random" ? classes[getRandomColor()] : classes[color];
  const sizeClass =
    typeof size === "number" ? classes.customSize : classes[size];

  const placeHolderChar =
    alt && phCharLength > 0 ? alt.substr(0, phCharLength).toUpperCase() : null;

  return (
    <Avatar
      className={`${classes.root} ${colorClass} Cmt-avatar-size ${sizeClass} ${
        className || ""
      }`}
      src={src}
      alt={alt}
      {...rest}
    >
      {!src && !children && alt ? placeHolderChar : children}
    </Avatar>
  );
};

CustomAvatar.propTypes = {
  color: PropTypes.oneOf(colorOptions),
  phCharLength: PropTypes.number,
  size: PropTypes.oneOfType([
    PropTypes.oneOf(["small", "medium", "large"]),
    PropTypes.number,
  ]),
};

CustomAvatar.defaultProps = {
  color: "grey",
  phCharLength: 1,
  size: "medium",
};

export default CustomAvatar;

import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  FormControlLabel,
  FormControl,
  Grid,
  Switch,
  TextField,
  FormGroup,
  Checkbox,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { useTranslation } from "react-i18next";
import TripTimes from "./TripTimes";

const ManageTripSlotForm = ({ errors, onChange, tripSlot }) => {
  const { t } = useTranslation();
  return (
    <>
      <Box mb={3}>
        {errors.onSave && <Alert severity="error">{errors.onSave}</Alert>}
      </Box>
      {!tripSlot.id && (
        <FormControlLabel
          control={
            <Switch
              name="isRecurring"
              checked={tripSlot.isRecurring}
              onChange={onChange}
              color="primary"
            />
          }
          label={t("components.manage-trip-slot-dialog.form.isRecurring.label")}
        />
      )}
      {!tripSlot.id && tripSlot.isRecurring ? (
        <>
          <Box mb={3}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <KeyboardDatePicker
                  disablePast
                  required
                  fullWidth
                  margin="normal"
                  name="startDate"
                  label={t(
                    "components.manage-trip-slot-dialog.form.startDate.label"
                  )}
                  format="dd/MM/yyyy"
                  value={tripSlot.slotDate.startDate}
                  onChange={(value) =>
                    onChange({
                      target: {
                        name: "startDate",
                        value: value,
                      },
                    })
                  }
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  helperText={errors.slotDate}
                  error={!!errors.slotDate}
                />
              </Grid>
              <Grid item xs={6}>
                <KeyboardDatePicker
                  disablePast
                  required
                  fullWidth
                  margin="normal"
                  name="endDate"
                  label={t(
                    "components.manage-trip-slot-dialog.form.endDate.label"
                  )}
                  format="dd/MM/yyyy"
                  value={tripSlot.slotDate.endDate}
                  onChange={(value) =>
                    onChange({
                      target: {
                        name: "endDate",
                        value: value,
                      },
                    })
                  }
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  helperText={errors.slotDate}
                  error={!!errors.slotDate}
                />
              </Grid>
            </Grid>
          </Box>
          <Box mb={3}>
            <FormControl component="fieldset">
              <FormGroup>
                <Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={tripSlot.recurring.monday}
                        onChange={onChange}
                        name="recurringMonday"
                      />
                    }
                    label={t(
                      "components.manage-trip-slot-dialog.form.recurring.monday"
                    )}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={tripSlot.recurring.tuesday}
                        onChange={onChange}
                        name="recurringTuesday"
                      />
                    }
                    label={t(
                      "components.manage-trip-slot-dialog.form.recurring.tuesday"
                    )}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={tripSlot.recurring.wednesday}
                        onChange={onChange}
                        name="recurringWednesday"
                      />
                    }
                    label={t(
                      "components.manage-trip-slot-dialog.form.recurring.wednesday"
                    )}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={tripSlot.recurring.thursday}
                        onChange={onChange}
                        name="recurringThursday"
                      />
                    }
                    label={t(
                      "components.manage-trip-slot-dialog.form.recurring.thursday"
                    )}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={tripSlot.recurring.friday}
                        onChange={onChange}
                        name="recurringFriday"
                      />
                    }
                    label={t(
                      "components.manage-trip-slot-dialog.form.recurring.friday"
                    )}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={tripSlot.recurring.saturday}
                        onChange={onChange}
                        name="recurringSaturday"
                      />
                    }
                    label={t(
                      "components.manage-trip-slot-dialog.form.recurring.saturday"
                    )}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={tripSlot.recurring.sunday}
                        onChange={onChange}
                        name="recurringSunday"
                      />
                    }
                    label={t(
                      "components.manage-trip-slot-dialog.form.recurring.sunday"
                    )}
                  />
                </Box>
              </FormGroup>
            </FormControl>
          </Box>
          <Box mb={3}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TripTimes
                  items={tripSlot.departureOriginTimes}
                  onAddItem={(time) =>
                    onChange({
                      target: {
                        name: "addDepartureOriginTimes",
                        value: time,
                      },
                    })
                  }
                  onDeleteItem={(time) =>
                    onChange({
                      target: {
                        name: "removeDepartureOriginTimes",
                        value: time,
                      },
                    })
                  }
                  label={t(
                    "components.manage-trip-slot-dialog.form.departureOriginTimes.label"
                  )}
                  error={errors.departureOriginTimes}
                />
              </Grid>
              <Grid item xs={6}>
                <TripTimes
                  items={tripSlot.departureDestinationTimes}
                  onAddItem={(time) =>
                    onChange({
                      target: {
                        name: "addDepartureDestinationTimes",
                        value: time,
                      },
                    })
                  }
                  onDeleteItem={(time) =>
                    onChange({
                      target: {
                        name: "removeDepartureDestinationTimes",
                        value: time,
                      },
                    })
                  }
                  label={t(
                    "components.manage-trip-slot-dialog.form.departureDestinationTimes.label"
                  )}
                  error={errors.departureDestinationTimes}
                />
              </Grid>
            </Grid>
          </Box>
        </>
      ) : (
        <>
          <Box mb={3}>
            <KeyboardDatePicker
              disablePast
              required
              fullWidth
              margin="normal"
              name="startDate"
              label={t(
                "components.manage-trip-slot-dialog.form.startDate.label"
              )}
              format="dd/MM/yyyy"
              value={tripSlot.slotDate.startDate}
              onChange={(value) =>
                onChange({
                  target: {
                    name: "startDate",
                    value: value,
                  },
                })
              }
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              helperText={errors.slotDate}
              error={errors.slotDate}
            />
          </Box>
          <Box mb={3}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TripTimes
                  items={tripSlot.departureOriginTimes}
                  onAddItem={(time) =>
                    onChange({
                      target: {
                        name: "addDepartureOriginTimes",
                        value: time,
                      },
                    })
                  }
                  onDeleteItem={(time) =>
                    onChange({
                      target: {
                        name: "removeDepartureOriginTimes",
                        value: time,
                      },
                    })
                  }
                  label={t(
                    "components.manage-trip-slot-dialog.form.departureOriginTimes.label"
                  )}
                  error={errors.departureOriginTimes}
                />
              </Grid>
              <Grid item xs={6}>
                <TripTimes
                  items={tripSlot.departureDestinationTimes}
                  onAddItem={(time) =>
                    onChange({
                      target: {
                        name: "addDepartureDestinationTimes",
                        value: time,
                      },
                    })
                  }
                  onDeleteItem={(time) =>
                    onChange({
                      target: {
                        name: "removeDepartureDestinationTimes",
                        value: time,
                      },
                    })
                  }
                  label={t(
                    "components.manage-trip-slot-dialog.form.departureDestinationTimes.label"
                  )}
                  error={errors.departureDestinationTimes}
                />
              </Grid>
            </Grid>
          </Box>
        </>
      )}
      <Box mb={3}>
        <TextField
          autoComplete="off"
          required
          fullWidth
          InputLabelProps={{ shrink: true }}
          label={t(
            "components.manage-trip-slot-dialog.form.totalNumberOfPeople.label"
          )}
          name="totalNumberOfPeople"
          id="totalNumberOfPeople"
          type="number"
          error={!!errors.totalNumberOfPeople}
          helperText={errors.totalNumberOfPeople}
          onChange={onChange}
          value={tripSlot.totalNumberOfPeople.toString()}
        />
      </Box>
    </>
  );
};

ManageTripSlotForm.propTypes = {
  tripSlot: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ManageTripSlotForm;

import React from "react";
import { Card, CardContent, CardHeader, makeStyles } from "@material-ui/core";
import {
  Chart,
  Series,
  ArgumentAxis,
  CommonSeriesSettings,
  Legend,
  Tooltip,
  Grid,
} from "devextreme-react/chart";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";
import { useEffect } from "react";
import { getMonthStatistics } from "../../api/rewardsApi";
import { format, getMonth, getYear } from "date-fns";

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: "bold",
  },
}));

const EarningsOverview = ({ date }) => {
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();
  const classes = useStyles();

  const [statistics, setStatistics] = useState([]);

  useEffect(() => {
    getAccessTokenSilently().then((token) => {
      getMonthStatistics(token, getYear(date), getMonth(date) + 1).then(
        (response) => {
          setStatistics(response.data);
        }
      );
    });
  }, [getAccessTokenSilently, date]);
  return (
    <Card>
      <CardHeader
        classes={{ title: classes.title }}
        title={`${t("components.earnings-overview.title")} ${format(
          date,
          "MMM yyyy"
        )}`}
      ></CardHeader>
      <CardContent>
        <Chart dataSource={statistics}>
          <CommonSeriesSettings
            argumentField="dateFormatted"
            type="stackedBar"
          />
          <Series
            valueField="potentialAmount"
            name="Potential amount in Euros"
          />
          <Series valueField="dueAmount" name="Due amount in Euros" />
          <Series valueField="paidAmount" name="Paid amount in Euros" />
          <ArgumentAxis
            valueMarginsEnabled={false}
            discreteAxisDivisionMode="crossLabels"
          >
            <Grid visible={true} />
          </ArgumentAxis>
          <Legend
            verticalAlignment="bottom"
            horizontalAlignment="center"
            itemTextPosition="bottom"
          />
          <Tooltip enabled={true} />
        </Chart>
      </CardContent>
    </Card>
  );
};

export default EarningsOverview;

import React from "react";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Box, Link } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

const PaymentsInformationAlert = () => {
  const { t } = useTranslation();
  return (
    <Alert severity="error">
      <AlertTitle>
        {t("pages.home-associate.important-notice.title")}
      </AlertTitle>
      <Box display="flex">
        <Box>{t("pages.home-associate.important-notice.message")}</Box>
        <Box ml={2}>
          <Link component={RouterLink} to="/payment-information">
            {t("pages.home-associate.important-notice.link")}
          </Link>
        </Box>
      </Box>
    </Alert>
  );
};

export default PaymentsInformationAlert;

import React, { useContext } from "react";
import LayoutContext from "../providers/LayoutContext";
import CustomDrawer from "../customDrawer/CustomDrawer";
import SidebarContainer from "./SidebarContainer";

const SidebarDrawer = ({ classes, children }) => {
  const { isSidebarOpen, setSidebarOpen } = useContext(LayoutContext);
  return (
    <CustomDrawer
      variant="temporary"
      open={isSidebarOpen}
      onClose={() => setSidebarOpen(false)}
      classes={{
        paper: "Cmt-drawer-sidebar",
      }}
    >
      <SidebarContainer classes={classes}>{children}</SidebarContainer>
    </CustomDrawer>
  );
};

export default SidebarDrawer;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
} from "@material-ui/core";
import Home from "@material-ui/icons/Home";
import ManageAddressDialog from "./ManageAddressDialog";

const AddressPicker = ({ label, address, errors, onSave }) => {
  const [internalAddress, setInternalAddress] = useState({
    addressLine: "",
    postCode: "",
    city: "",
    country: "",
  });
  const [open, setOpen] = useState(false);

  const handleOnChange = (event) => {
    const { name, value } = event.target;
    setInternalAddress({ ...internalAddress, [name]: value });
  };

  useEffect(() => {
    if (address) setInternalAddress(address);
  }, [address]);

  return (
    <>
      <FormControl fullWidth error={!!errors.address}>
        <InputLabel>{label}</InputLabel>
        <Input
          type="text"
          readOnly
          value={Object.keys(internalAddress)
            .map((key) => internalAddress[key])
            .filter((x) => x !== "")
            .join(", ")}
          endAdornment={
            <InputAdornment position="end">
              <IconButton onClick={() => setOpen(true)}>
                <Home />
              </IconButton>
            </InputAdornment>
          }
        />
        <FormHelperText>{errors.address}</FormHelperText>
      </FormControl>
      <ManageAddressDialog
        open={open}
        onClose={() => setOpen(false)}
        address={internalAddress}
        onChange={handleOnChange}
        errors={errors}
        onSave={() => {
          onSave(internalAddress);
          setOpen(false);
        }}
      />
    </>
  );
};

AddressPicker.propTypes = {
  label: PropTypes.string.isRequired,
  address: PropTypes.object,
  errors: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default AddressPicker;

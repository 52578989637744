import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Button,
  makeStyles,
} from "@material-ui/core";
import { format } from "date-fns";
import CommonGrid from "../common/grids/CommonGrid";
import Table from "@material-ui/core/Table";
import { TableBody, TableCell, TableRow } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  status: {
    textAlign: "center",
    textTransform: "uppercase",
    color: "red",
    fontWeight: "800",
    border: "1px dashed red",
    padding: "10px",
  },
}));

const TicketValidation = ({ booking, onValidate }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const itemsColumns = [
    { name: "item", title: t("components.summary-and-payment.grid.item") },
    {
      name: "price",
      title: t("components.summary-and-payment.grid.price"),
      getCellValue: (row) => `${row.price.amount} €`,
    },
    {
      name: "quantity",
      title: t("components.summary-and-payment.grid.quantity"),
    },
    {
      name: "amount",
      title: t("components.summary-and-payment.grid.amount"),
      getCellValue: (row) => `${row.amount} €`,
    },
  ];

  const getItems = () => {
    let items = [];

    if (booking.numberOfAdults > 0) {
      items.push({
        item: `${booking.tripName}-${t(
          "components.summary-and-payment.items.adult"
        )}-${format(new Date(booking.date.startDate), "dd/MM/yyy")}-${
          booking.departureOriginTime
        }-${booking.departureDestinationTime}`,
        price: booking.pricePerAdult,
        quantity: booking.numberOfAdults,
        amount: booking.pricePerAdult.amount * booking.numberOfAdults,
      });
    }

    if (booking.numberOfChildren > 0) {
      items.push({
        item: `${booking.tripName}-${t(
          "components.summary-and-payment.items.child"
        )}-${format(new Date(booking.date.startDate), "dd/MM/yyy")}-${
          booking.departureOriginTime
        }-${booking.departureDestinationTime}`,
        price: booking.pricePerChild,
        quantity: booking.numberOfChildren,
        amount: booking.pricePerChild.amount * booking.numberOfChildren,
      });
    }

    return items;
  };

  const extras = booking.extras.map((x) => {
    return {
      item: x.title,
      price: x.price,
      quantity: x.amount,
      amount: x.price.amount * x.amount,
    };
  });

  const items = getItems();

  let totals = [
    {
      name: t("components.summary-and-payment.totals.subtotal"),
      value: `${booking.subTotal.amount.toFixed(2)} €`,
    },
  ];

  if (!booking.ignoreDiscounts) {
    if (booking.applyWebDiscount) {
      totals = [
        ...totals,
        {
          name: t("components.summary-and-payment.totals.web"),
          value: `${(((booking.subTotal.amount * 10) / 100) * -1).toFixed(
            2
          )} €`,
        },
      ];
    }

    if (booking.applyResidentsDiscount) {
      totals = [
        ...totals,
        {
          name: t("components.summary-and-payment.totals.residents"),
          value: `${(((booking.subTotal.amount * 20) / 100) * -1).toFixed(
            2
          )} €`,
        },
      ];
    }
  }

  if (booking.extras.length > 0) {
    totals = [
      ...totals,
      {
        name: t("components.summary-and-payment.totals.extras"),
        value: `${booking.extras
          .reduce((acc, item) => acc + item.price.amount * item.amount, 0)
          .toFixed(2)} €`,
      },
    ];
  }

  totals = [
    ...totals,
    {
      name: t("components.summary-and-payment.totals.total"),
      value: `${booking.amount.amount.toFixed(2)} €`,
    },
  ];

  return (
    <Card>
      <CardContent>
        <Box p={6}>
          <Grid container>
            <Grid item xs={4}>
              <Box># TICKET-{String(booking.autoId).padStart(6, "0")}</Box>
              <Box>{format(new Date(booking.created), "dd/MM/yyyy")}</Box>
              <Box>
                {booking.customerFullName
                  ? booking.customerFullName
                  : booking.customerEmail}
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box className={classes.status}>
                {t(
                  `components.ticket-validation.statuses.${booking.stateName}`
                )}
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Grid container justifyContent="flex-end">
                <Button
                  onClick={onValidate}
                  disabled={booking.stateName !== "Paid"}
                  color="primary"
                  variant="contained"
                >
                  {t("components.ticket-validation.validate-button")}
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Box mt={6} mb={10}>
                <CommonGrid
                  columns={itemsColumns}
                  items={items}
                  defaultColumnWidths={[
                    { columnName: "item", width: "auto" },
                    { columnName: "price", width: 100 },
                    { columnName: "quantity", width: 100 },
                    { columnName: "amount", width: 100 },
                  ]}
                />
              </Box>
            </Grid>
            {booking.extras.length > 0 && (
              <>
                <Grid item xs={12}>
                  <Box>Extras</Box>
                </Grid>
                <Grid item xs={12}>
                  <Box mt={6} mb={10}>
                    <CommonGrid
                      columns={itemsColumns}
                      items={extras}
                      defaultColumnWidths={[
                        { columnName: "item", width: "auto" },
                        { columnName: "price", width: 100 },
                        { columnName: "quantity", width: 100 },
                        { columnName: "amount", width: 100 },
                      ]}
                    />
                  </Box>
                </Grid>
              </>
            )}
            <Grid item xs={8}></Grid>
            <Grid item xs={4}>
              <Table>
                <TableBody>
                  {totals.map((row) => (
                    <TableRow key={row.name}>
                      <TableCell>{row.name}</TableCell>
                      <TableCell align="right">{row.value}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
};

TicketValidation.propTypes = {
  booking: PropTypes.object.isRequired,
  onValidate: PropTypes.func.isRequired,
};

export default TicketValidation;

import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getExtras } from "../../api/tripsApi";

const AddExtraDataDialog = ({ tripId, open, onClose, onAdd }) => {
  const { t } = useTranslation();
  const [errors, setErrors] = useState({});
  const [extras, setExtras] = useState([]);
  const { getAccessTokenSilently } = useAuth0();

  const [extraItem, setExtraItem] = useState({
    amount: 0,
    extraId: "",
    title: "",
    image: "",
    reference: "",
    description: "",
    price: {
      amount: 0,
      currency: "EUR",
    },
  });

  const handleOnChange = (event) => {
    const { name, value } = event.target;

    if (name === "extra") {
      let extra = extras.find((x) => x.id === value);
      setExtraItem({
        ...extraItem,
        extraId: extra.id,
        title: extra.title,
        reference: extra.reference,
        image: extra.image,
        description: extra.description,
        price: extra.price,
      });
    } else if (name === "amount") {
      setExtraItem({ ...extraItem, amount: Number.parseInt(value) });
    } else {
      setExtraItem({ ...extraItem, [name]: value });
    }
  };

  const handleOnAdd = () => {
    if (!formIsValid()) return;

    onAdd(extraItem);
  };

  const formIsValid = () => {
    const errors = {};

    const { amount, extraId } = extraItem;

    if (amount <= 0)
      errors.price = t("components.add-extra-data-dialog.form.amount.error");
    if (!extraId)
      errors.extra = t("components.add-extra-data-dialog.form.extra.error");

    setErrors(errors);

    return Object.keys(errors).length === 0;
  };

  useEffect(() => {
    getAccessTokenSilently().then((token) => {
      getExtras(token, tripId).then((response) => {
        setExtras(response.data);
      });
    });
  }, [getAccessTokenSilently, tripId]);

  return (
    <Dialog maxWidth="sm" open={open} onClose={onClose} fullWidth>
      <DialogTitle>{t("components.add-extra-data-dialog.title")}</DialogTitle>
      <DialogContent>
        {extras.length === 0 ? (
          <Box>{t("components.add-extra-data-dialog.no-extras")}</Box>
        ) : (
          <>
            <Box>
              <FormControl error={!!errors.extra} fullWidth>
                <InputLabel>
                  {t("components.add-extra-data-dialog.form.extra.label")}
                </InputLabel>
                <Select
                  name="extra"
                  value={extraItem.extraId}
                  onChange={handleOnChange}
                >
                  {extras.map((x, index) => (
                    <MenuItem key={index} value={x.id}>
                      {`${x.title} ${x.price.amount} ${x.price.currency}`}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{errors.extra}</FormHelperText>
              </FormControl>
            </Box>
            <Box mb={3}>
              <TextField
                autoComplete="off"
                required
                fullWidth
                label={t("components.add-extra-data-dialog.form.amount.label")}
                name="amount"
                id="amount"
                type="number"
                error={!!errors.amount}
                helperText={errors.amount}
                onChange={handleOnChange}
                value={extraItem.amount}
              />
            </Box>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose} color="secondary">
          {t("components.add-extra-data-dialog.buttons.close")}
        </Button>
        <Button
          disabled={extras.length === 0}
          variant="contained"
          onClick={handleOnAdd}
          color="primary"
        >
          {t("components.add-extra-data-dialog.buttons.add")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AddExtraDataDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  tripId: PropTypes.string.isRequired,
};

export default AddExtraDataDialog;

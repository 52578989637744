import React from "react";
import PropTypes from "prop-types";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

const TripSelector = ({ trips, trip, errors, onChange }) => {
  const { t } = useTranslation();
  return (
    <FormControl error={!!errors.selectedTripId} fullWidth>
      <InputLabel>{t("components.trip-selector.label")}</InputLabel>
      <Select name="trip" value={trip ? trip : ""} onChange={onChange}>
        {trips.map((x) => (
          <MenuItem key={x.id} value={x}>
            {x.name}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{errors.selectedTripId}</FormHelperText>
    </FormControl>
  );
};

TripSelector.propTypes = {
  trips: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

export default TripSelector;

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  active: {
    backgroundColor: theme.palette.primary.main,
  },
  completed: {
    backgroundColor: theme.palette.primary.main,
  },
}));

const CustomStepIcon = ({ children, active, completed }) => {
  const classes = useStyles();

  return (
    <Avatar
      className={`${active ? classes.active : ""} ${
        completed ? classes.completed : ""
      }`}
    >
      {children}
    </Avatar>
  );
};

CustomStepIcon.propTypes = {
  children: PropTypes.node.isRequired,
  active: PropTypes.bool.isRequired,
  completed: PropTypes.bool.isRequired,
};

export default CustomStepIcon;

import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import PropTypes from "prop-types";
import ManageAddressForm from "./ManageAddressForm";
import { useTranslation } from "react-i18next";

const ManageAddressDialog = ({
  open,
  onClose,
  onChange,
  onSave,
  address,
  errors,
}) => {
  const { t } = useTranslation();
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>{t("components.manage-address-dialog.title")}</DialogTitle>
      <DialogContent>
        <ManageAddressForm
          onChange={onChange}
          address={address}
          errors={errors}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose} color="secondary">
          {t("components.manage-address-dialog.buttons.cancel")}
        </Button>
        <Button variant="contained" onClick={onSave} color="primary">
          {t("components.manage-address-dialog.buttons.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ManageAddressDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  address: PropTypes.object.isRequired,
};

export default ManageAddressDialog;

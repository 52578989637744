import axios from "axios";

import { createQueryURL, createAuthHeader } from "./apiUtils";

const baseUrl = `${process.env.REACT_APP_ADMIN_API}/api/bookings`;

export function getBookings(token, loadOptions) {
  return axios.get(
    createQueryURL(baseUrl, loadOptions),
    createAuthHeader(token)
  );
}

export function getBooking(token, id) {
  return axios.get(`${baseUrl}/${id}`, createAuthHeader(token));
}

export function saveBooking(token, booking) {
  if (booking.id) {
    return axios.put(
      `${baseUrl}/${booking.id}`,
      booking,
      createAuthHeader(token)
    );
  }
  return axios.post(baseUrl, booking, createAuthHeader(token));
}

export function payBooking(token, booking) {
  return axios.post(
    `${baseUrl}/${booking.id}/pay`,
    null,
    createAuthHeader(token)
  );
}

export function cancelBooking(token, booking) {
  return axios.post(
    `${baseUrl}/${booking.id}/cancel`,
    null,
    createAuthHeader(token)
  );
}

export function validateBooking(token, booking) {
  return axios.post(
    `${baseUrl}/${booking.id}/validate-ticket`,
    null,
    createAuthHeader(token)
  );
}

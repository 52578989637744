import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Fab,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";
import { KeyboardTimePicker } from "@material-ui/pickers";
import { useTranslation } from "react-i18next";
import { Add, Delete } from "@material-ui/icons";
import { format } from "date-fns";

const TripTimes = ({ label, items, onAddItem, onDeleteItem, error }) => {
  const { t } = useTranslation();
  const [time, setTime] = useState(null);

  const handleOnAddItem = () => {
    let item = format(new Date(time), "HH:mm");

    if (!items.includes(item)) {
      onAddItem(item);
    }

    setTime(null);
  };

  return (
    <>
      <Box>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={10}>
            <KeyboardTimePicker
              value={time}
              onChange={(value) => setTime(value)}
              required
              fullWidth
              margin="normal"
              name="recurringStartTime"
              label={label}
              KeyboardButtonProps={{
                "aria-label": "change time",
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Fab
              size="small"
              color="primary"
              onClick={handleOnAddItem}
              disabled={time === null}
            >
              <Add />
            </Fab>
          </Grid>
        </Grid>
      </Box>
      {items.length === 0 ? (
        <Box>
          {t("components.manage-trip-slot-dialog.form.recurring.no-items")}
        </Box>
      ) : (
        <List>
          {items.map((item, index) => (
            <ListItem dense key={index}>
              <ListItemText primary={item}></ListItemText>
              <ListItemSecondaryAction>
                <IconButton
                  onClick={() => onDeleteItem(item)}
                  edge="end"
                  size="small"
                >
                  <Delete />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      )}
      {error && (
        <Box fontSize="0.75rem" color="#f44336">
          {error}
        </Box>
      )}
    </>
  );
};

TripTimes.propTypes = {
  label: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  onAddItem: PropTypes.func.isRequired,
  onDeleteItem: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
};

export default TripTimes;

import axios from "axios";

import { createQueryURL, createAuthHeader } from "./apiUtils";

const baseUrl = `${process.env.REACT_APP_ADMIN_API}/api/customers`;

export function getCustomers(token, loadOptions) {
  return axios.get(
    createQueryURL(baseUrl, loadOptions),
    createAuthHeader(token)
  );
}

export function getCustomer(token, id) {
  return axios.get(`${baseUrl}/${id}`, createAuthHeader(token));
}

export function getBookingsByCustomer(token, id) {
  return axios.get(`${baseUrl}/${id}/bookings`, createAuthHeader(token));
}

export function saveCustomer(token, customer) {
  if (customer.id) {
    return axios.put(
      `${baseUrl}/${customer.id}`,
      customer,
      createAuthHeader(token)
    );
  }
  return axios.post(baseUrl, customer, createAuthHeader(token));
}

import React, { useState, useEffect } from "react";
import { Button, Card, CardContent, Grid } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { Link } from "react-router-dom";
import PageContainer from "../components/common/page/pageContainer";
import CommonGrid from "../components/common/grids/CommonGrid";
import { disableTrip, enableTrip, getTrips } from "../api/tripsApi";
import { useHistory } from "react-router";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";

const TripsPage = () => {
  const history = useHistory();
  const { getAccessTokenSilently } = useAuth0();
  const { t } = useTranslation();

  const breadcrumbs = [
    { label: t("pages.home.title"), link: "/" },
    { label: t("pages.trips.title"), isActive: true },
  ];

  const columns = [
    { name: "name", title: t("pages.trips.grid.name") },
    { name: "available", type: "bool", title: t("pages.trips.grid.available") },
    {
      name: "actions",
      title: t("pages.trips.grid.actions"),
      actions: [
        {
          name: t("pages.trips.grid.action.edit"),
          run: (item) => {
            history.push(`/trips/${item.id}`);
          },
        },
        {
          name: t("pages.trips.grid.action.enable"),
          isActionVisible: (item) => !item.available,
          run: (item) => {
            enable(item);
          },
        },
        {
          name: t("pages.trips.grid.action.disable"),
          isActionVisible: (item) => item.available,
          run: (item) => {
            disable(item);
          },
        },
      ],
    },
  ];
  const [trips, setTrips] = useState([]);

  const disable = (trip) => {
    getAccessTokenSilently().then((token) => {
      disableTrip(token, trip).then((response) => {
        setTrips(
          trips.map((x) => {
            if (x.id !== trip.id) return x;
            else return { ...x, available: false };
          })
        );
      });
    });
  };

  const enable = (trip) => {
    getAccessTokenSilently().then((token) => {
      enableTrip(token, trip).then((response) => {
        setTrips(
          trips.map((x) => {
            if (x.id !== trip.id) return x;
            else return { ...x, available: true };
          })
        );
      });
    });
  };

  useEffect(() => {
    getAccessTokenSilently().then((token) => {
      getTrips(token, {}).then((response) => {
        setTrips(response.data.data);
      });
    });
  }, [getAccessTokenSilently]);

  return (
    <PageContainer heading={t("pages.trips.title")} breadcrumbs={breadcrumbs}>
      <Card>
        <CardContent>
          <Grid container justifyContent="flex-end">
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to={"add-trip"}
              startIcon={<Add />}
            >
              {t("pages.trips.add")}
            </Button>
          </Grid>
          <CommonGrid
            columns={columns}
            items={trips}
            defaultColumnWidths={[
              { columnName: "name", width: "auto" },
              { columnName: "available", width: "auto" },
              { columnName: "actions", width: 100 },
            ]}
          />
        </CardContent>
      </Card>
    </PageContainer>
  );
};

export default TripsPage;

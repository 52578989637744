import React, { useContext } from "react";
import LayoutContextProvider from "../providers/LayoutContextProvider";
import SidebarContextProvider from "../providers/SidebarContextProvider";
import MainContent from "./MainContent";
import MainContainer from "./MainContainer";
import globalStyles from "./VerticalLayout.style";
import AppContext from "../providers/AppContext";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

const VerticalLayout = ({ children }) => {
  globalStyles();

  const { quickFeedback, setQuickFeedback } = useContext(AppContext);
  const handleCloseFeedback = () => {
    setQuickFeedback({ ...quickFeedback, show: false });
  };

  return (
    <LayoutContextProvider>
      <SidebarContextProvider>
        <MainContainer>
          <MainContent>{children}</MainContent>
          <Snackbar
            open={quickFeedback.show}
            autoHideDuration={quickFeedback.duration}
            onClose={handleCloseFeedback}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Alert
              severity={quickFeedback.severity}
              onClose={handleCloseFeedback}
            >
              {quickFeedback.message}
            </Alert>
          </Snackbar>
        </MainContainer>
      </SidebarContextProvider>
    </LayoutContextProvider>
  );
};

export default VerticalLayout;

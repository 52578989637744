import React from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableColumnResizing,
} from "@devexpress/dx-react-grid-material-ui";
import { DataTypeProvider } from "@devexpress/dx-react-grid";
import ActionFormatter from "./ActionFormatter";
import BoolFormatter from "./BoolFormater";

const CommonGrid = ({ items, columns, defaultColumnWidths }) => {
  const ActionTypeProvider = (props) => (
    <DataTypeProvider formatterComponent={ActionFormatter} {...props} />
  );

  const boolColumns = columns
    .filter((x) => x.type === "bool")
    .map((x) => x.name);

  return (
    <Grid rows={items} columns={columns}>
      <ActionTypeProvider for={["actions"]} />
      <DataTypeProvider for={boolColumns} formatterComponent={BoolFormatter} />

      <Table />
      {defaultColumnWidths && (
        <TableColumnResizing
          resizingMode="nextColumn"
          defaultColumnWidths={defaultColumnWidths}
        />
      )}
      <TableHeaderRow />
    </Grid>
  );
};

CommonGrid.propTypes = {
  items: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  defaultColumnWidths: PropTypes.array,
};

export default CommonGrid;

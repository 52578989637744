import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Step,
  StepLabel,
  Stepper,
} from "@material-ui/core";
import CustomStepIcon from "../common/customStepIcon/CustomStepIcon";
import DirectionsBoatIcon from "@material-ui/icons/DirectionsBoat";
import ContactsIcon from "@material-ui/icons/Contacts";
import TripDataForm from "./TripDataForm";
import CustomerDataForm from "./CustomerDataForm";
import EuroIcon from "@material-ui/icons/Euro";
import SummaryAndPayment from "./SummaryAndPayment";
import { useTranslation } from "react-i18next";

const ManageBookingForm = ({
  booking,
  saving,
  onChange,
  onSave,
  onPay,
  onAddExtra,
  onDeleteExtra,
}) => {
  const { t } = useTranslation();
  const steps = [
    {
      label: t("components.add-booking.steps.trip-data.label"),
      icon: (props) => (
        <CustomStepIcon {...props}>
          <DirectionsBoatIcon />
        </CustomStepIcon>
      ),
    },
    {
      label: t("components.add-booking.steps.customer-data.label"),
      icon: (props) => (
        <CustomStepIcon {...props}>
          <ContactsIcon />
        </CustomStepIcon>
      ),
    },
    {
      label: t("components.add-booking.steps.summary-and-payment.label"),
      icon: (props) => (
        <CustomStepIcon {...props}>
          <EuroIcon />
        </CustomStepIcon>
      ),
    },
  ];
  const [activeStep, setActiveStep] = useState(0);
  const [errors, setErrors] = useState({});
  const [trip, setTrip] = useState(null);

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleNext = () => {
    if (activeStep === 0 && isTripDataFormValid()) {
      onSave();
      setActiveStep(activeStep + 1);
      return;
    }

    if (activeStep === 1 && isCustomerDataFormValid()) {
      onSave();
      setActiveStep(activeStep + 1);
      return;
    }

    if (activeStep === 2) {
      onPay();
      return;
    }
  };

  const isCustomerDataFormValid = () => {
    const errors = {};

    if (!booking.customer || !booking.customer.id)
      errors.customer = t("components.customer-picker.error");

    if (booking.numberOfAdults === 0 && booking.numberOfChildren === 0) {
      errors.numberOfAdults = t(
        "components.extra-data-form.numberOfAdults.error"
      );
      errors.numberOfChildren = t(
        "components.extra-data-form.numberOfChildren.error"
      );
    }

    setErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const isTripDataFormValid = () => {
    const errors = {};

    if (!trip) errors.selectedTripId = t("components.trip-selector.error");
    if (!booking.tripSlot)
      errors.selectedTripSlotId = t("components.trip-data-form.slot.error");
    if (!booking.departureOriginTime)
      errors.departureOriginTime = t(
        "components.trip-data-form.departureOriginTime.error"
      );
    if (!booking.departureOriginTime)
      errors.departureDestinationTime = t(
        "components.trip-data-form.departureDestinationTime.error"
      );

    setErrors(errors);

    return Object.keys(errors).length === 0;
  };

  useEffect(() => {
    if (booking.tripSlot) {
      setTrip(booking.tripSlot.trip);
    }
  }, [booking]);

  return (
    <Card>
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            <Stepper alternativeLabel activeStep={activeStep}>
              {steps.map((step) => (
                <Step key={step.label}>
                  <StepLabel StepIconComponent={step.icon}>
                    {step.label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>

          {activeStep === 0 && (
            <Grid item xs={12}>
              <Box mb={3}>
                <TripDataForm
                  errors={errors}
                  trip={trip}
                  booking={booking}
                  onSelectedTrip={(event) => setTrip(event.target.value)}
                  onSelectedTripSlot={(slot) =>
                    onChange({
                      target: {
                        value: slot,
                        name: "tripSlot",
                      },
                    })
                  }
                  onSelectedDepartureTime={onChange}
                />
              </Box>
            </Grid>
          )}

          {activeStep === 1 && (
            <Grid item xs={12}>
              <Box mb={3}>
                <CustomerDataForm
                  errors={errors}
                  booking={booking}
                  onChange={onChange}
                  onAddExtra={onAddExtra}
                  onDeleteExtra={onDeleteExtra}
                />
              </Box>
            </Grid>
          )}

          {activeStep === 2 && (
            <Grid item xs={12}>
              <Box mb={3} p={6}>
                <SummaryAndPayment booking={booking} />
              </Box>
            </Grid>
          )}

          <Grid container justifyContent="flex-end">
            <Box mr={2}>
              <Button
                variant="contained"
                color="secondary"
                disabled={activeStep === 0 || saving}
                onClick={handleBack}
              >
                {t("components.add-booking.buttons.back")}
              </Button>
            </Box>
            <Box>
              <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                disabled={saving}
              >
                {activeStep === steps.length - 1
                  ? t("components.add-booking.buttons.finish")
                  : t("components.add-booking.buttons.next")}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

ManageBookingForm.propTypes = {
  saving: PropTypes.bool.isRequired,
  booking: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onPay: PropTypes.func.isRequired,
  onAddExtra: PropTypes.func.isRequired,
  onDeleteExtra: PropTypes.func.isRequired,
};

export default ManageBookingForm;

import React from "react";
import { Breadcrumbs, Typography } from "@material-ui/core";
import useStyles from "./pageBreadcrumbs.style";
import { Link } from "react-router-dom";

const PageBreadcrumbs = ({ items, ...rest }) => {
  const classes = useStyles();

  return (
    <Breadcrumbs className="bread-crumbs" aria-label="breadcrumb" {...rest}>
      {items.map((item, index) =>
        item.isActive ? (
          <Typography
            key={index}
            className={classes.textSm}
            color="textPrimary"
          >
            {item.label}
          </Typography>
        ) : (
          <Link
            key={index}
            className={`${classes.textSm} ${classes.linkBlock}`}
            color="inherit"
            to={item.link || "/"}
          >
            {item.label}
          </Link>
        )
      )}
    </Breadcrumbs>
  );
};

export default PageBreadcrumbs;

import React from "react";
import PropTypes from "prop-types";
import { Box, Button, Grid, TextField, Tooltip } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import FileCopyIcon from "@material-ui/icons/FileCopy";

const AffiliateLink = ({ id }) => {
  const url = `${process.env.REACT_APP_WEB}?source=${id}`;
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [preview, setPreview] = useState("");
  const [openCopyUrlTooltip, setOpenCopyUrlTooltip] = useState(false);
  const [openCopyLinkTooltip, setOpenCopyLinkTooltip] = useState(false);

  const handleGenerate = () => {
    setPreview(`<a target="_blank" href="${url}">${name}</a>`);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <Box>{t("components.associate-link.text")}</Box>
        <Box mt={5}>
          <TextField
            autoComplete="off"
            required
            fullWidth
            label={t("components.associate-link.form.name")}
            name="name"
            type="text"
            onChange={handleNameChange}
            value={name}
          />
        </Box>
        <Box mt={5}>
          <Button
            disabled={!name}
            variant="contained"
            color="primary"
            onClick={handleGenerate}
          >
            {t("components.associate-link.form.generate")}
          </Button>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box mt={1}>
          <TextField
            autoComplete="off"
            multiline
            minRows={3}
            fullWidth
            label={t("components.associate-link.form.preview")}
            name="preview"
            value={preview}
            InputProps={{
              readOnly: true,
            }}
          />
        </Box>
        <Box mt={5} display="flex">
          <Box>
            <Tooltip
              arrow
              title={t("components.associate-link.form.copied")}
              PopperProps={{
                disablePortal: true,
              }}
              open={openCopyLinkTooltip}
              disableFocusListener
              disableHoverListener
              disableTouchListener
            >
              <Button
                variant="contained"
                color="secondary"
                disabled={!preview}
                startIcon={<FileCopyIcon />}
                onClick={() => {
                  setOpenCopyLinkTooltip(true);
                  navigator.clipboard.writeText(preview);
                  setTimeout(() => {
                    setOpenCopyLinkTooltip(false);
                  }, 500);
                }}
              >
                {t("components.associate-link.form.copy-link")}
              </Button>
            </Tooltip>
          </Box>
          <Box ml={3}>
            <Tooltip
              arrow
              title={t("components.associate-link.form.copied")}
              PopperProps={{
                disablePortal: true,
              }}
              open={openCopyUrlTooltip}
              disableFocusListener
              disableHoverListener
              disableTouchListener
            >
              <Button
                variant="contained"
                color="secondary"
                startIcon={<FileCopyIcon />}
                onClick={() => {
                  setOpenCopyUrlTooltip(true);
                  navigator.clipboard.writeText(url);
                  setTimeout(() => {
                    setOpenCopyUrlTooltip(false);
                  }, 500);
                }}
              >
                {t("components.associate-link.form.copy-url")}
              </Button>
            </Tooltip>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

AffiliateLink.propTypes = {
  id: PropTypes.string.isRequired,
};

export default AffiliateLink;

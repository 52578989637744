import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  appMainContent: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    padding: "30px 15px",
    [theme.breakpoints.up("md")]: {
      paddingLeft: 50,
      paddingRight: 50,
    },
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 65,
      paddingRight: 65,
    },
    [theme.breakpoints.up("xl")]: {
      paddingLeft: 88,
      paddingRight: 88,
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: 20,
      paddingBottom: 20,
    },
  },
}));

export default useStyles;

import React, { useState, useMemo, useEffect } from "react";
import AppContext from "./AppContext";
import { THEME_TYPES } from "../constants/themeOptions";
import defaultTheme from "../theme/defaultTheme";
import themeColors from "../theme/themeColors";
import { useAuth0 } from "@auth0/auth0-react";
import { sidebarNavs as administratorMenu } from "../../../menu";
import { sidebarNavs as validatorMenu } from "../../../menu-validator";
import { sidebarNavs as affiliateMenu } from "../../../menu-affiliate";

const AppContextProvider = ({ children }) => {
  const [theme, setTheme] = useState(defaultTheme);
  const [themeType, setThemeType] = useState(THEME_TYPES.LIGHT);
  const [sidebarItems, setSidebarItems] = useState([]);

  const [roles, setRoles] = useState([]);
  const { getIdTokenClaims } = useAuth0();

  const [quickFeedback, setQuickFeedback] = useState({
    show: false,
    severity: "",
    duration: 2000,
    message: "",
  });

  const contextValue = useMemo(() => {
    return {
      theme,
      themeType,
      setThemeType,
      sidebarItems,
      setSidebarItems,
      quickFeedback,
      setQuickFeedback,
      roles,
      setRoles,
    };
  }, [theme, themeType, sidebarItems, quickFeedback, roles]);

  useEffect(() => {
    setTheme((prevState) => ({
      ...prevState,
      palette: {
        ...prevState.palette,
        ...themeColors[themeType].palette,
      },
      overrides: {
        ...prevState.overrides,
        ...themeColors[themeType].overrides,
      },
    }));

    getIdTokenClaims().then((claims) => {
      setRoles(claims.user_roles);
      if (claims.user_roles.includes("administrator")) {
        setSidebarItems(administratorMenu);
      } else if (claims.user_roles.includes("validator")) {
        setSidebarItems(validatorMenu);
      } else if (claims.user_roles.includes("affiliate")) {
        setSidebarItems(affiliateMenu);
      }
    });
  }, [getIdTokenClaims, themeType]);

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};

export default AppContextProvider;

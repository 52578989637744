import React from "react";
import { Box } from "@material-ui/core";
import useStyles from "./MainContent.style";

const MainContent = ({ children }) => {
  const classes = useStyles();

  return (
    <Box
      className={`${classes.appMainContent} Cmt-appMainContent`}
      bgcolor="background.main"
    >
      {children}
    </Box>
  );
};

export default MainContent;

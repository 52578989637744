import React from "react";
import { Box, Typography } from "@material-ui/core";

import useStyles from "./pageHeader.style";

const PageHeader = ({ heading, breadcrumbComponent, children, ...rest }) => {
  const classes = useStyles();

  return (
    <Box
      className={`${classes.pageHeaderRoot} page-header`}
      mb={{ xs: 5, md: 6, lg: 8 }}
      {...rest}
    >
      <Typography
        component="div"
        variant="h1"
        className={`${classes.titleRoot} title`}
      >
        {heading}
      </Typography>
      <Box ml={{ sm: "auto" }}>{breadcrumbComponent}</Box>
      {children}
    </Box>
  );
};

export default PageHeader;

import React from "react";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import CommonGridExtended from "../common/grids/CommonGridExtended";
import { useReducer } from "react";
import {
  initialState,
  MAX_ROWS,
  reducer,
  VIRTUAL_PAGE_SIZE,
} from "../common/grids/commonGridExtenderUtils";
import { useMemo } from "react";
import { createRowCache } from "@devexpress/dx-react-grid";
import { createQueryURL } from "../../api/apiUtils";
import { useEffect } from "react";
import {
  downloadCreditSlip,
  downloadMyCreditSlip,
  getAllCreditSlips,
  getMyCreditSlips,
  payCreditSlip,
} from "../../api/creditSlipsApi";
import { saveAs } from "file-saver";
import PropTypes from "prop-types";

const CreditSlipsGrid = ({ isAdmin }) => {
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    sorting: [{ columnName: "autoId", direction: "desc" }],
  });

  const columns = [
    {
      name: "amount",
      title: t("pages.my-credit-slips.grid.amount"),
      type: "number",
    },
    {
      name: "created",
      title: t("pages.my-credit-slips.grid.created"),
      type: "date",
    },
    {
      name: "isPaid",
      title: t("pages.my-credit-slips.grid.paid"),
      type: "bool",
    },
    {
      name: "actions",
      title: t("pages.my-credit-slips.grid.actions"),
      actions: [
        {
          name: "Download",
          run: (item) => {
            getAccessTokenSilently().then((token) => {
              if (isAdmin) {
                downloadCreditSlip(token, item.id)
                  .then((response) => {
                    saveAs(response.data, "credit-slip.pdf");
                  })
                  .catch(() => dispatch({ type: "REQUEST_ERROR" }));
              } else {
                downloadMyCreditSlip(token, item.id)
                  .then((response) => {
                    saveAs(response.data, "my-credit-slip.pdf");
                  })
                  .catch(() => dispatch({ type: "REQUEST_ERROR" }));
              }
            });
          },
        },
        {
          name: "Pay",
          run: (item) => {
            getAccessTokenSilently().then((token) => {
              payCreditSlip(token, item.id)
                .then(() => {
                  dispatch({ type: "FORCE_RELOAD" });
                })
                .catch(() => dispatch({ type: "REQUEST_ERROR" }));
            });
          },
          isActionVisible: (item) => isAdmin && !item.isPaid,
        },
      ],
      allowSorting: false,
      allowFiltering: false,
      width: 100,
      type: "actions",
    },
  ];

  const cache = useMemo(() => createRowCache(VIRTUAL_PAGE_SIZE), []);

  const updateRows = (skip, count, newTotalCount) => {
    dispatch({
      type: "UPDATE_ROWS",
      payload: {
        skip: Math.min(skip, newTotalCount),
        rows: cache.getRows(skip, count),
        totalCount: newTotalCount < MAX_ROWS ? newTotalCount : MAX_ROWS,
      },
    });
  };

  const loadData = () => {
    const {
      requestedSkip,
      take,
      lastQuery,
      loading,
      forceReload,
      totalCount,
      filters,
      sorting,
    } = state;

    const loadOptions = {
      take,
      filters,
      sorting,
      skip: requestedSkip,
    };
    const query = createQueryURL("", loadOptions);

    if ((query !== lastQuery || forceReload) && !loading) {
      if (forceReload) {
        cache.invalidate();
      }

      const cached = cache.getRows(requestedSkip, take);

      if (cached.length === take) {
        updateRows(requestedSkip, take, totalCount);
      } else {
        dispatch({ type: "FETCH_INIT" });

        getAccessTokenSilently().then((token) => {
          if (isAdmin) {
            getAllCreditSlips(token, loadOptions)
              .then((response) => {
                cache.setRows(requestedSkip, response.data.data);
                updateRows(requestedSkip, take, response.data.totalCount);
              })
              .catch(() => dispatch({ type: "REQUEST_ERROR" }));
          } else {
            getMyCreditSlips(token, loadOptions)
              .then((response) => {
                cache.setRows(requestedSkip, response.data.data);
                updateRows(requestedSkip, take, response.data.totalCount);
              })
              .catch(() => dispatch({ type: "REQUEST_ERROR" }));
          }
        });
      }
      dispatch({ type: "UPDATE_QUERY", payload: query });
    }
  };

  const handleOnChangeFilters = (value) => {
    dispatch({ type: "CHANGE_FILTERS", payload: value });
  };

  const handleOnSortingChange = (value) => {
    dispatch({ type: "CHANGE_SORTING", payload: value });
  };

  const handleOnGetRows = (requestedSkip, take) => {
    dispatch({ type: "START_LOADING", payload: { requestedSkip, take } });
  };

  useEffect(() => loadData());

  return (
    <>
      <CommonGridExtended
        state={state}
        columns={columns}
        onChangeFilters={handleOnChangeFilters}
        onGetRows={handleOnGetRows}
        onSortingChange={handleOnSortingChange}
      ></CommonGridExtended>
    </>
  );
};

CreditSlipsGrid.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
};

export default CreditSlipsGrid;

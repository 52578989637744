import React from "react";
import { Card, CardContent } from "@material-ui/core";
import PageContainer from "../components/common/page/pageContainer";
import { useTranslation } from "react-i18next";

const UnauthorizedPage = () => {
  const { t } = useTranslation();

  const breadcrumbs = [
    { label: t("pages.home.title"), link: "/" },
    { label: t("pages.unauthorized.title"), isActive: true },
  ];

  return (
    <PageContainer
      heading={t("pages.unauthorized.title")}
      breadcrumbs={breadcrumbs}
    >
      <Card>
        <CardContent>Unauthorized page</CardContent>
      </Card>
    </PageContainer>
  );
};

export default UnauthorizedPage;

import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  navHeader: {
    position: "relative",
    padding: "24px 16px 20px 16px",
    textTransform: "uppercase",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: 10,
    letterSpacing: 1.5,
    ".Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &": {
      display: "none",
    },
  },
  navSection: {
    position: "relative",
    fontWeight: theme.typography.fontWeightRegular,
    "&:not(:first-child) .Cmt-navHeader": {
      borderTop: (props) => `solid 1px ${props.sidebarTheme.borderColor}`,
      marginTop: 10,
    },
    "&:not(:last-child)": {
      ".Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &": {
        borderBottom: (props) => `solid 1px ${props.sidebarTheme.borderColor}`,
        paddingTop: 10,
        paddingBottom: 10,
      },
    },
  },
}));

export default useStyles;

import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(() => ({
  perfectScrollbarSidebar: {
    height: "100%",
    transition: "all 0.3s ease",
    ".Cmt-sidebar-fixed &, .Cmt-Drawer-container &": {
      height: "calc(100% - 167px)",
    },
    ".Cmt-modernLayout &": {
      height: "calc(100% - 72px)",
    },
    ".Cmt-miniLayout &": {
      height: "calc(100% - 91px)",
    },
    ".Cmt-miniLayout .Cmt-sidebar-content:hover &": {
      height: "calc(100% - 167px)",
    },
  },
}));

export default useStyles;

import React, { useContext } from "react";

import { List } from "@material-ui/core";

import NavMenuItem from "./NavMenuItem";
import NavCollapse from "./NavCollapse";
import useStyles from "./NavSection.style";
import SidebarContext from "../providers/SidebarContext";
import { useTranslation } from "react-i18next";

const NavSection = (props) => {
  const { t } = useTranslation();
  const { name, children = [] } = props;
  const isExpandable = children && children.length > 0;
  const { sidebarTheme } = useContext(SidebarContext);
  const classes = useStyles({ sidebarTheme });

  const MenuCollapse = (
    <List component="div" className={`${classes.navHeader} Cmt-navHeader`}>
      {t(`components.sidebar.sections.${name}`)}
    </List>
  );

  const MenuItemChildren = isExpandable ? (
    <List component="div" disablePadding>
      {children.map((item, index) => {
        switch (item.type) {
          case "section":
            return <NavSection {...item} key={index} />;
          case "collapse":
            return <NavCollapse {...item} key={index} />;
          default:
            return <NavMenuItem {...item} key={index} />;
        }
      })}
    </List>
  ) : null;

  return (
    <div className={classes.navSection}>
      {MenuCollapse}
      {MenuItemChildren}
    </div>
  );
};

export default NavSection;

import { useAuth0 } from "@auth0/auth0-react";
import React, { useState, useContext } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  getPaymentInformation,
  savePaymentInformation,
} from "../../api/accountsApi";
import AppContext from "../common/providers/AppContext";
import PaymentInformationForm from "./PaymentInformationForm";

const PaymentInformation = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { getAccessTokenSilently } = useAuth0();
  const { quickFeedback, setQuickFeedback } = useContext(AppContext);
  const [errors, setErrors] = useState({});
  const [formIsDirty, setFormDirty] = useState(false);
  const [paymentInformation, setPaymentInformation] = useState({
    accountHolderName: "",
    bankName: "",
    iban: "",
    bic: "",
    currency: "EUR",
    companyName: "",
    vatNumber: "",
    companyAddress: {
      addressLine: "",
      postCode: "",
      city: "",
      country: "",
    },
  });

  const formIsValid = () => {
    const errors = {};

    const { iban, companyAddress, companyName, vatNumber } = paymentInformation;

    if (!iban)
      errors.iban = t("components.payment-information.form.iban.error");
    if (!vatNumber)
      errors.vatNumber = t(
        "components.payment-information.form.vat-number.error"
      );
    if (!companyName)
      errors.companyName = t(
        "components.payment-information.form.company-name.error"
      );
    if (!companyAddress || !companyAddress.addressLine)
      errors.address = t("components.payment-information.form.address.error");

    setErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const saveData = () => {
    if (!formIsValid() || !formIsDirty) return;
    getAccessTokenSilently().then((token) => {
      savePaymentInformation(token, paymentInformation)
        .then((_) => {
          setFormDirty(false);
          setQuickFeedback({
            ...quickFeedback,
            show: true,
            severity: "success",
            message: "Payment information has been saved successfully.",
          });

          history.push("/home-affiliate");
        })
        .catch((error) => {
          setErrors({
            onSave:
              error.response && error.response.data
                ? error.response.data
                : error.message,
          });
          setQuickFeedback({
            ...quickFeedback,
            show: true,
            severity: "error",
            message: "Oops! Something went wrong! Try again later.",
          });
        });
    });
  };

  const handleOnChange = (event) => {
    setFormDirty(true);
    const { name, value } = event.target;

    setPaymentInformation({ ...paymentInformation, [name]: value });
  };

  const handleOnCancel = () => {
    history.push("/home-affiliate");
  };

  useEffect(() => {
    getAccessTokenSilently().then((token) => {
      getPaymentInformation(token).then((response) => {
        setPaymentInformation({ ...response.data, currency: "EUR" });
      });
    });
  }, [getAccessTokenSilently]);

  return (
    <PaymentInformationForm
      paymentInformation={paymentInformation}
      errors={errors}
      onChange={handleOnChange}
      onSave={saveData}
      onCancel={handleOnCancel}
    />
  );
};

export default PaymentInformation;

import React from "react";
import PropTypes from "prop-types";
import { Box, Button, Card, CardContent, Grid } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import CommonGrid from "../common/grids/CommonGrid";
import { useState } from "react";
import AddExtraDataDialog from "./AddExtraDataDialog";

const ExtrasDataForm = ({ booking, onDeleteExtra, onAddExtra }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const columns = [
    {
      name: "title",
      title: t("components.extras-data-form.grid.title"),
    },
    {
      name: "price",
      title: t("components.extras-data-form.grid.price"),
      getCellValue: (row) => `${row.price.amount} €`,
    },
    {
      name: "amount",
      title: t("components.extras-data-form.grid.amount"),
    },
    {
      name: "actions",
      title: t("components.extras-data-form.grid.actions"),
      actions: [
        {
          name: t("components.extras-data-form.grid.action.delete"),
          run: (item) => {
            onDeleteExtra(item);
          },
        },
      ],
    },
  ];

  const onOpenExtra = () => {
    setOpen(true);
  };
  return (
    <>
      <Card>
        <CardContent>
          <Box>
            <Box mt={4}>
              <Grid container justifyContent="flex-end">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => onOpenExtra()}
                  startIcon={<Add />}
                >
                  {t("components.extras-data-form.buttons.add-extra")}
                </Button>
              </Grid>
            </Box>
            <CommonGrid
              columns={columns}
              items={booking.extras}
              defaultColumnWidths={[
                { columnName: "title", width: "auto" },
                { columnName: "price", width: "auto" },
                { columnName: "amount", width: "auto" },
                { columnName: "actions", width: 100 },
              ]}
            />
          </Box>
        </CardContent>
      </Card>
      <AddExtraDataDialog
        tripId={booking.tripSlot.trip.id}
        open={open}
        onAdd={(item) => {
          onAddExtra(item);
          setOpen(false);
        }}
        onClose={() => setOpen(false)}
      />
    </>
  );
};

ExtrasDataForm.propTypes = {
  booking: PropTypes.object.isRequired,
  onDeleteExtra: PropTypes.func.isRequired,
  onAddExtra: PropTypes.func.isRequired,
};

export default ExtrasDataForm;

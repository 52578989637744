import React from "react";
import { Grid } from "@material-ui/core";
import CountUpStats from "./CountUpStats";
import { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import { getCounters } from "../../api/statisticsApi";

const Counters = () => {
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();
  const [counters, setCounters] = useState(null);
  useEffect(() => {
    getAccessTokenSilently().then((token) => {
      getCounters(token).then((response) => {
        setCounters(response.data);
      });
    });
  }, [getAccessTokenSilently]);

  return (
    counters && (
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <CountUpStats
            start={0}
            end={counters.numberOfCustomers}
            duration={1}
            description={t("components.counters.numberOfCustomers")}
          />
        </Grid>
        <Grid item xs={4}>
          <CountUpStats
            start={0}
            end={counters.numberOfBookingsCurrentYear}
            duration={2}
            description={t("components.counters.numberOfBookings")}
          />
        </Grid>
        <Grid item xs={4}>
          <CountUpStats
            start={0}
            end={counters.currentYearRevenue}
            duration={2}
            decimals={2}
            suffix=" €"
            description={t("components.counters.revenue")}
          />
        </Grid>
      </Grid>
    )
  );
};

export default Counters;

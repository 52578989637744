export const createAuthHeader = (token) => {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};

export const createQueryURL = (baseUrl, loadOptions) => {
  const loadOptionsParsed = Object.assign.apply({}, [
    getSortingParams(loadOptions),
    getPagingParams(loadOptions),
    getFilterParams(loadOptions),
    getGroupParams(loadOptions), // overrides skip and take
    {
      requireTotalCount: true,
    },
  ]);

  let query = "?";

  [
    "skip",
    "take",
    "requireTotalCount",
    "requireGroupCount",
    "sort",
    "filter",
    "totalSummary",
    "group",
    "groupSummary",
  ].forEach(function (i) {
    if (i in loadOptionsParsed && isNotEmpty(loadOptionsParsed[i])) {
      query += `${i}=${JSON.stringify(loadOptionsParsed[i])}&`;
    }
  });

  query = query.slice(0, -1);

  return query ? baseUrl.concat(query) : baseUrl;
};

const getSortingParams = (loadOptions) =>
  loadOptions.sorting && loadOptions.sorting.length > 0
    ? {
        sort: loadOptions.sorting.map((s) => ({
          selector: s.columnName,
          desc: s.direction === "desc",
        })),
      }
    : {};

const getPagingParams = (loadOptions) => {
  const params = {};
  if (loadOptions.take) params.take = loadOptions.take;
  if (loadOptions.pageSize) params.take = loadOptions.pageSize;
  if (loadOptions.skip > 0) params.skip = loadOptions.skip;
  if (loadOptions.currentPage > 0)
    params.skip = loadOptions.currentPage * loadOptions.pageSize;
  return params;
};

const mapOperation = (operation) => {
  const commonOperations = [
    {
      input: "greaterThanOrEqual",
      output: ">=",
    },
    { input: "greaterThan", output: ">" },
    { input: "lessThanOrEqual", output: "<=" },
    { input: "lessThan", output: "<" },
    { input: "equal", output: "=" },
    { input: "notEqual", output: "<>" },
  ];

  let found = commonOperations.find((x) => x.input === operation);

  return found != null ? found.output : operation;
};

const getFilterParams = (loadOptions) =>
  loadOptions.filters && loadOptions.filters.length > 0
    ? {
        filter: loadOptions.filters.reduce((r, v) => {
          if (r.length > 0) r.push("and");
          r.push([v.columnName, mapOperation(v.operation), v.value]);
          return r;
        }, []),
      }
    : loadOptions.filter
    ? {
        filter: loadOptions.filter,
      }
    : {};

const getGroupParams = (loadOptions) =>
  loadOptions.grouping && loadOptions.grouping.length > 0
    ? {
        group: loadOptions.grouping.map((g) => ({
          selector: g.columnName,
          isExpanded: false,
        })),
        requireGroupCount: true,
        // skip and take override any previous settings when
        // these params are combined with others
        skip: undefined,
        take: undefined, // always query all groups
      }
    : {};

const isNotEmpty = (value) => {
  return value !== undefined && value !== null && value !== "";
};

import React from "react";
import {
  Card,
  CardActionArea,
  CardContent,
  makeStyles,
  Typography,
} from "@material-ui/core";
import CountUp from "react-countup";

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    height: 150,
    backgroundColor: "#FFA500",
    textAlign: "center",
    paddingTop: 30,
    color: "white",
  },
  number: {
    fontSize: 32,
    fontWeight: "bold",
  },
});

const CountUpStats = ({
  start,
  end,
  duration,
  description,
  decimals,
  prefix = "",
  suffix = "",
}) => {
  const classes = useStyles();

  return (
    <>
      <Card className={classes.root} variant="outlined">
        <CardActionArea>
          <CardContent className={classes.content}>
            <CountUp
              className={classes.number}
              star={start}
              end={end}
              duration={duration}
              decimals={decimals}
              prefix={prefix}
              suffix={suffix}
              decimal=","
              separator="."
            />
            <Typography variant="body2" component="p">
              {description}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </>
  );
};

export default CountUpStats;

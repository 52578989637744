import React, { useContext } from "react";

import PerfectScrollbar from "react-perfect-scrollbar";

import VerticalNavigation from "../navigation/VerticalNavigation";
import AppContext from "../providers/AppContext";
import useStyles from "./SidebarContent.style";

const SidebarContent = () => {
  const classes = useStyles();

  const { sidebarItems } = useContext(AppContext);

  return (
    <PerfectScrollbar className={classes.perfectScrollbarSidebar}>
      <VerticalNavigation menuItems={sidebarItems} />
    </PerfectScrollbar>
  );
};

export default SidebarContent;

import axios from "axios";

import { createAuthHeader } from "./apiUtils";

const baseUrl = `${process.env.REACT_APP_ADMIN_API}/api/statistics`;

export function getCounters(token) {
  return axios.get(`${baseUrl}/counters`, createAuthHeader(token));
}

export function getStatistics(token) {
  return axios.get(`${baseUrl}/statistics`, createAuthHeader(token));
}

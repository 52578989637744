import React from "react";
import { Box } from "@material-ui/core";
import logo from "../../../assets/images/logo-light.png";
import logoText from "../../../assets/images/logo-text-light.png";
import { Link } from "react-router-dom";

const Logo = (props) => {
  return (
    <Box className="pointer" {...props}>
      <Link to="/">
        <img src={logo} alt="logo" height="36" />
        <img src={logoText} alt="logo text" height="36" />
      </Link>
    </Box>
  );
};

export default Logo;

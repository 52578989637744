const VIRTUAL_PAGE_SIZE = 100;
const MAX_ROWS = 50000;

const initialState = {
  rows: [],
  skip: 0,
  requestedSkip: 0,
  take: VIRTUAL_PAGE_SIZE * 2,
  totalCount: 0,
  loading: false,
  lastQuery: "",
  sorting: [],
  filters: [],
  forceReload: false,
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case "FORCE_RELOAD":
      return {
        ...state,
        loading: false,
        forceReload: true,
        rows: [],
        filters: [],
        sorting: [],
        requestedSkip: 0,
      };
    case "UPDATE_ROWS":
      return {
        ...state,
        ...payload,
        loading: false,
      };
    case "CHANGE_SORTING":
      return {
        ...state,
        forceReload: true,
        rows: [],
        sorting: payload,
      };
    case "CHANGE_FILTERS":
      return {
        ...state,
        forceReload: true,
        requestedSkip: 0,
        rows: [],
        filters: payload,
      };
    case "START_LOADING":
      return {
        ...state,
        requestedSkip: payload.requestedSkip,
        take: payload.take,
      };
    case "REQUEST_ERROR":
      return {
        ...state,
        loading: false,
      };
    case "FETCH_INIT":
      return {
        ...state,
        loading: true,
        forceReload: false,
      };
    case "UPDATE_QUERY":
      return {
        ...state,
        lastQuery: payload,
      };
    default:
      return state;
  }
};

export { reducer, initialState, VIRTUAL_PAGE_SIZE, MAX_ROWS };

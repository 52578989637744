import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import AppThemeWrapper from "./components/common/AppThemeWrapper";
import HomePage from "./pages/HomePage";
import TripsPage from "./pages/TripsPage";
import AppContextProvider from "./components/common/providers/AppContextProvider";
import "devextreme/dist/css/dx.material.blue.light.css";
import "./main.scss";
import TripManagementPage from "./pages/TripManagementPage";
import BookingsPage from "./pages/BookingsPage";
import BookingManagementPage from "./pages/BookingManagementPage";
import { useAuth0 } from "@auth0/auth0-react";
import CustomersPage from "./pages/CustomersPage";
import CustomerManagementPage from "./pages/CustomerManagementPage";
import BookingViewPage from "./pages/BookingViewPage";
import BookingValidationPage from "./pages/BookingValidationPage";
import ReportsPage from "./pages/ReportsPage";
import UnauthorizedPage from "./pages/UnauthorizedPage";
import ProtectedRoute from "./components/common/ProtectedRoute";
import HomeAdministratorPage from "./pages/HomeAdministratorPage";
import HomeAssociatePage from "./pages/HomeAssociatePage";
import HomeSailorPage from "./pages/HomeSailorPage";
import PaymentInformationPage from "./pages/PaymentInformationPage";
import AffiliatesPage from "./pages/AffiliatesPage";
import MyCreditSlipsPage from "./pages/MyCreditSlipsPage";
import AffiliatesCreditslipsPage from "./pages/AffiliatesCreditslipsPage";

const App = () => {
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect({
        appState: {
          returnTo: window.location.pathname,
        },
      });
    }
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  return (
    isAuthenticated && (
      <AppContextProvider>
        <AppThemeWrapper>
          <Switch>
            <Route
              path="/reports"
              render={() => (
                <ProtectedRoute authorizedRoles={["administrator"]}>
                  <ReportsPage />
                </ProtectedRoute>
              )}
            />
            <Route
              path="/trips/:tripId"
              render={() => (
                <ProtectedRoute authorizedRoles={["administrator"]}>
                  <TripManagementPage />
                </ProtectedRoute>
              )}
            />
            <Route
              path="/trips"
              render={() => (
                <ProtectedRoute authorizedRoles={["administrator"]}>
                  <TripsPage />
                </ProtectedRoute>
              )}
            />
            <Route
              path="/add-trip"
              render={() => (
                <ProtectedRoute authorizedRoles={["administrator"]}>
                  <TripManagementPage />
                </ProtectedRoute>
              )}
            />
            <Route
              path="/bookings/:bookingId/view"
              render={() => (
                <ProtectedRoute
                  authorizedRoles={["administrator", "validator"]}
                >
                  <BookingViewPage />
                </ProtectedRoute>
              )}
            />
            <Route
              path="/bookings/:bookingId/validate"
              render={() => (
                <ProtectedRoute
                  authorizedRoles={["administrator", "validator"]}
                >
                  <BookingValidationPage />
                </ProtectedRoute>
              )}
            />
            <Route
              path="/bookings/:bookingId"
              render={() => (
                <ProtectedRoute authorizedRoles={["administrator"]}>
                  <BookingManagementPage />
                </ProtectedRoute>
              )}
            />
            <Route
              path="/bookings"
              render={() => (
                <ProtectedRoute authorizedRoles={["administrator"]}>
                  <BookingsPage />
                </ProtectedRoute>
              )}
            />
            <Route
              path="/add-booking"
              render={() => (
                <ProtectedRoute authorizedRoles={["administrator"]}>
                  <BookingManagementPage />
                </ProtectedRoute>
              )}
            />

            <Route
              path="/customers/:customerId"
              render={() => (
                <ProtectedRoute authorizedRoles={["administrator"]}>
                  <CustomerManagementPage />
                </ProtectedRoute>
              )}
            />
            <Route
              path="/customers"
              render={() => (
                <ProtectedRoute authorizedRoles={["administrator"]}>
                  <CustomersPage />
                </ProtectedRoute>
              )}
            />
            <Route
              path="/add-customer"
              render={() => (
                <ProtectedRoute authorizedRoles={["administrator"]}>
                  <CustomerManagementPage />
                </ProtectedRoute>
              )}
            />
            <Route
              path="/home-affiliate"
              render={() => (
                <ProtectedRoute authorizedRoles={["affiliate"]}>
                  <HomeAssociatePage />
                </ProtectedRoute>
              )}
            />
            <Route
              path="/payment-information"
              render={() => (
                <ProtectedRoute authorizedRoles={["affiliate"]}>
                  <PaymentInformationPage />
                </ProtectedRoute>
              )}
            />
            <Route
              path="/my-credit-slips"
              render={() => (
                <ProtectedRoute authorizedRoles={["affiliate"]}>
                  <MyCreditSlipsPage />
                </ProtectedRoute>
              )}
            />
            <Route
              path="/home-sailor"
              render={() => (
                <ProtectedRoute authorizedRoles={["validator"]}>
                  <HomeSailorPage />
                </ProtectedRoute>
              )}
            />
            <Route
              path="/home-administrator"
              render={() => (
                <ProtectedRoute authorizedRoles={["administrator"]}>
                  <HomeAdministratorPage />
                </ProtectedRoute>
              )}
            />
            <Route
              path="/payment-information"
              render={() => (
                <ProtectedRoute authorizedRoles={["affiliate"]}>
                  <PaymentInformationPage />
                </ProtectedRoute>
              )}
            />
            <Route
              path="/affiliates"
              render={() => (
                <ProtectedRoute authorizedRoles={["administrator"]}>
                  <AffiliatesPage />
                </ProtectedRoute>
              )}
            />
            <Route
              path="/credit-slips"
              render={() => (
                <ProtectedRoute authorizedRoles={["administrator"]}>
                  <AffiliatesCreditslipsPage />
                </ProtectedRoute>
              )}
            />
            <Route path="/unauthorized" component={UnauthorizedPage} />
            <Route
              path="/"
              render={() => (
                <ProtectedRoute
                  authorizedRoles={["administrator", "validator", "affiliate"]}
                >
                  <HomePage />
                </ProtectedRoute>
              )}
            />
          </Switch>
        </AppThemeWrapper>
      </AppContextProvider>
    )
  );
};

export default App;

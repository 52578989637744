import React, {
  cloneElement,
  isValidElement,
  useCallback,
  useContext,
  useState,
} from "react";

import { Collapse, List, ListItem } from "@material-ui/core";
import IconExpandLess from "@material-ui/icons/ExpandLess";
import IconExpandMore from "@material-ui/icons/ExpandMore";

import NavSection from "./NavSection";
import NavMenuItem from "./NavMenuItem";
import SidebarContext from "../providers/SidebarContext";
import useStyles from "./NavCollapse.style";
import { useTranslation } from "react-i18next";

const NavCollapse = (props) => {
  const { t } = useTranslation();
  const { sidebarTheme } = useContext(SidebarContext);
  const classes = useStyles({ sidebarTheme });

  const { name, icon, children = [] } = props;
  const isExpandable = children.length;
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const renderIcon = useCallback(() => {
    if (icon && isValidElement(icon)) {
      return cloneElement(icon, {
        className: classes.iconRoot,
      });
    }
    return null;
  }, [icon, classes]);

  const MenuCollapse = (
    <ListItem
      className={`${classes.navCollapseBtn} Cmt-navCollapseBtn`}
      button
      onClick={handleClick}
    >
      <span className={classes.navCollapseBtnInner}>
        {/* Display the expand menu if the item has children */}
        {isExpandable && !open && (
          <IconExpandMore className={classes.navArrow} />
        )}
        {isExpandable && open && (
          <IconExpandLess className={classes.navArrow} />
        )}
        {/* Display an icon if any */}
        {renderIcon()}
        <span className={classes.navText}>
          {t(`components.sidebar.menus.${name}`)}
        </span>
      </span>
    </ListItem>
  );

  const MenuItemChildren = isExpandable ? (
    <Collapse className={classes.navCollapseItem} in={open} timeout="auto">
      <List component="div" disablePadding>
        {children.map((item, index) => {
          switch (item.type) {
            case "section":
              return <NavSection {...item} key={index} />;
            case "collapse":
              return <NavCollapse {...item} key={index} />;
            case "item":
              return <NavMenuItem {...item} key={index} />;
            default:
              return null;
          }
        })}
      </List>
    </Collapse>
  ) : null;

  return (
    <div className={`${classes.navCollapse} ${open ? "open" : ""}`}>
      {MenuCollapse}
      {MenuItemChildren}
    </div>
  );
};

export default NavCollapse;

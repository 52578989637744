import React from "react";
import {
  Plugin,
  Template,
  TemplatePlaceholder,
} from "@devexpress/dx-react-core";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Add } from "@material-ui/icons";
import { useTranslation } from "react-i18next";

const AddCustomerToolbarPlugin = () => {
  const { t } = useTranslation();

  return (
    <Plugin name="AddCustomerToolbar" dependencies={[{ name: "Toolbar" }]}>
      <Template name="toolbarContent">
        <TemplatePlaceholder />
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to={"add-customer"}
          startIcon={<Add />}
        >
          {t("pages.customers.add")}
        </Button>
      </Template>
    </Plugin>
  );
};

export default AddCustomerToolbarPlugin;

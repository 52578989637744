import axios from "axios";

import { createAuthHeader, createQueryURL } from "./apiUtils";

const baseUrl = `${process.env.REACT_APP_AFFILIATES_API}/api/credit-slips`;

export function getMyCreditSlips(token, loadOptions) {
  return axios.get(
    createQueryURL(baseUrl, loadOptions),
    createAuthHeader(token)
  );
}

export function getAllCreditSlips(token, loadOptions) {
  return axios.get(
    createQueryURL(`${baseUrl}/all`, loadOptions),
    createAuthHeader(token)
  );
}

export function downloadMyCreditSlip(token, id) {
  return axios.get(`${baseUrl}/${id}/download-mine`, {
    ...createAuthHeader(token),
    responseType: "blob",
  });
}

export function downloadCreditSlip(token, id) {
  return axios.get(`${baseUrl}/${id}/download`, {
    ...createAuthHeader(token),
    responseType: "blob",
  });
}

export function payCreditSlip(token, id) {
  return axios.post(`${baseUrl}/${id}/pay`, null, createAuthHeader(token));
}

import { IconButton, Menu, MenuItem } from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";
import React, { useState } from "react";

const ActionFormatter = ({ column, row }) => {
  const [openMenus, setOpenMenus] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event, item) => {
    setAnchorEl(event.currentTarget);
    if (openMenus.includes(item.id)) {
      setOpenMenus(openMenus.filter((x) => x !== item.id));
    } else {
      setOpenMenus([...openMenus, item.id]);
    }
  };
  const handleClose = (item) => {
    setAnchorEl(null);
    if (openMenus.includes(item.id)) {
      setOpenMenus(openMenus.filter((x) => x !== item.id));
    } else {
      setOpenMenus([...openMenus, item.id]);
    }
  };

  return (
    <>
      <IconButton size="small" onClick={(event) => handleClick(event, row)}>
        <MoreVert />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={openMenus.includes(row.id)}
        onClose={() => handleClose(row)}
        elevation={2}
      >
        {column.actions
          .filter((x) => !x.isActionVisible || x.isActionVisible(row))
          .map((action, index) => (
            <MenuItem
              key={index}
              onClick={() => {
                action.run(row);
                handleClose(row);
              }}
            >
              {action.name}
            </MenuItem>
          ))}
      </Menu>
    </>
  );
};

export default ActionFormatter;

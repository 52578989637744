import { Card, CardContent } from "@material-ui/core";
import React from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import BookingsGrid from "../components/bookings/BookingsGrid";
import PageContainer from "../components/common/page/pageContainer";
import AppContext from "../components/common/providers/AppContext";
import { sidebarNavs } from "../menu-validator";

const HomeSailorPage = () => {
  const { setSidebarItems } = useContext(AppContext);

  const { t } = useTranslation();
  const breadcrumbs = [{ label: t("pages.home-sailor.title"), isActive: true }];

  useEffect(() => {
    setSidebarItems(sidebarNavs);
  }, [setSidebarItems]);

  return (
    <PageContainer
      heading={t("pages.home-sailor.title")}
      breadcrumbs={breadcrumbs}
    >
      <Card>
        <CardContent>
          <BookingsGrid isValidator />
        </CardContent>
      </Card>
    </PageContainer>
  );
};

export default HomeSailorPage;

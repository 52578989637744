import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory, useParams } from "react-router";
import PageContainer from "../components/common/page/pageContainer";
import ManageBookingForm from "../components/bookings/ManageBookingForm";
import { getBooking, payBooking, saveBooking } from "../api/bookingsApi";
import { useTranslation } from "react-i18next";
import { addHours } from "date-fns";

const BookingManagementPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { getAccessTokenSilently } = useAuth0();
  const { bookingId } = useParams();
  const [saving, setSaving] = useState(false);
  const [formIsDirty, setFormDirty] = useState(false);

  const breadcrumbs = [
    { label: t("pages.home.title"), link: "/" },
    { label: t("pages.bookings.title"), link: "/bookings" },
    {
      label: `${
        bookingId ? t("pages.edit-booking.title") : t("pages.add-booking.title")
      }`,
      isActive: true,
    },
  ];

  const [booking, setBooking] = useState({
    customer: null,
    tripSlot: null,
    departureOriginTime: "",
    departureDestinationTime: "",
    numberOfAdults: 0,
    numberOfChildren: 0,
    applyResidentsDiscount: false,
    extras: [],
  });

  const handleOnChange = (event) => {
    const { name, value } = event.target;

    switch (name) {
      case "tripSlot":
        if (addHours(new Date(), 48) > value.startDate) {
          setBooking({ ...booking, [name]: value, extras: [] });
        } else {
          setBooking({ ...booking, [name]: value });
        }
        break;
      case "applyResidentsDiscount":
        setBooking({ ...booking, [name]: event.target.checked });
        break;
      default:
        setBooking({ ...booking, [name]: value });
        break;
    }

    setFormDirty(true);
  };

  const handleOnSave = () => {
    if (formIsDirty) {
      setSaving(true);
      getAccessTokenSilently().then((token) => {
        saveBooking(token, {
          id: booking.id,
          customerId: booking.customer ? booking.customer.id : null,
          tripSlotId: booking.tripSlot
            ? booking.tripSlot.id
            : booking.tripSlotId,
          numberOfAdults: booking.numberOfAdults,
          numberOfChildren: booking.numberOfChildren,
          departureOriginTime: booking.departureOriginTime,
          departureDestinationTime: booking.departureDestinationTime,
          applyResidentsDiscount: booking.applyResidentsDiscount,
          extras: booking.extras,
        })
          .then((response) => {
            setSaving(false);
            setBooking(response.data);
            setFormDirty(false);
          })
          .catch(() => {
            setSaving(false);
          });
      });
    }
  };

  const handleOnPay = () => {
    setSaving(true);
    getAccessTokenSilently().then((token) => {
      payBooking(token, booking)
        .then(() => {
          setSaving(false);
          history.push("/bookings");
        })
        .catch(() => {
          setSaving(false);
        });
    });
  };

  const handleOnAddExtra = (extra) => {
    if (booking.extras.find((x) => x.extraId === extra.extraId)) {
      setBooking({
        ...booking,
        extras: [
          ...booking.extras.map((x) =>
            x.extraId === extra.extraId
              ? { ...x, amount: x.amount + extra.amount }
              : x
          ),
        ],
      });
    } else {
      setBooking({ ...booking, extras: [...booking.extras, extra] });
    }

    setFormDirty(true);
  };

  const handleOnDeleteExtra = (item) => {
    setBooking({
      ...booking,
      extras: booking.extras.filter((x) => x.extraId !== item.extraId),
    });

    setFormDirty(true);
  };

  useEffect(() => {
    if (bookingId) {
      getAccessTokenSilently().then((token) => {
        getBooking(token, bookingId).then((response) => {
          setBooking({ ...response.data });
        });
      });
    }
  }, [bookingId, getAccessTokenSilently]);

  return (
    <PageContainer
      heading={`${
        bookingId ? t("pages.edit-booking.title") : t("pages.add-booking.title")
      }`}
      breadcrumbs={breadcrumbs}
    >
      <ManageBookingForm
        booking={booking}
        saving={saving}
        onChange={handleOnChange}
        onSave={handleOnSave}
        onPay={handleOnPay}
        onAddExtra={handleOnAddExtra}
        onDeleteExtra={handleOnDeleteExtra}
      />
    </PageContainer>
  );
};

export default BookingManagementPage;

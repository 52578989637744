import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  textSm: {
    fontSize: 12,
  },
  linkBlock: {
    display: "block",
    color: "inherit",
  },
}));

export default useStyles;

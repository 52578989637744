import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  AppBar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  Tab,
  Tabs,
  TextField,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import CommonGrid from "../common/grids/CommonGrid";
import { Add, Close } from "@material-ui/icons";
import format from "date-fns/format";
import { useTranslation } from "react-i18next";
import noImage from "../../assets/images/no-image-found-360x260.png";
import { DropzoneDialogBase } from "material-ui-dropzone";

const useStyles = makeStyles({
  root: {
    paddingTop: "50px",
  },
  card: {
    width: 345,
    margin: "0 auto",
  },
});

const ManageTripForm = ({
  trip,
  tripSlots,
  errors,
  extras,
  onChange,
  onThumbnailChange,
  onCancel,
  onOpenSlot,
  onBlur,
  onDeleteSlot,
  onOpenExtra,
  onDeleteExtra,
  onDisable,
  onEnable,
}) => {
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState(0);
  const [open, setOpen] = useState(false);
  const [fileObjects, setFileObjects] = React.useState([]);

  // const [blob, setBlob] = useState(null);

  const { t } = useTranslation();
  const tripSlotsColumns = [
    {
      name: "date",
      title: t("pages.manage-trip.trip-slots.grid.date"),
      getCellValue: (row) =>
        format(new Date(row.slotDate.startDate), "E d MMM yyyy"),
    },
    {
      name: "startTime",
      title: t("pages.manage-trip.trip-slots.grid.startTime"),
      getCellValue: (row) => format(new Date(row.slotDate.startDate), "HH:mm"),
    },
    {
      name: "endTime",
      title: t("pages.manage-trip.trip-slots.grid.endTime"),
      getCellValue: (row) => format(new Date(row.slotDate.endDate), "HH:mm"),
    },
    {
      name: "totalNumberOfPeople",
      title: t("pages.manage-trip.trip-slots.grid.totalNumberOfPeople"),
    },
    {
      name: "availableNumberOfPeople",
      title: t("pages.manage-trip.trip-slots.grid.numberOfPeopleAvailable"),
    },
    {
      name: "enabled",
      type: "bool",
      title: t("pages.manage-trip.trip-slots.grid.enabled"),
    },
    {
      name: "actions",
      title: t("pages.manage-trip.trip-slots.grid.actions"),
      actions: [
        {
          name: t("pages.manage-trip.trip-slots.grid.action.edit"),
          run: (item) => {
            onOpenSlot(item);
          },
        },
        {
          name: t("pages.manage-trip.trip-slots.grid.action.delete"),
          run: (item) => {
            onDeleteSlot(item);
          },
        },
        {
          name: t("pages.manage-trip.trip-slots.grid.action.enable"),
          isActionVisible: (item) => !item.enabled,
          run: (item) => {
            onEnable(item);
          },
        },
        {
          name: t("pages.manage-trip.trip-slots.grid.action.disable"),
          isActionVisible: (item) => item.enabled,
          run: (item) => {
            onDisable(item);
          },
        },
      ],
    },
  ];

  var extraColumns = [
    {
      name: "price",
      title: t("pages.manage-trip.extras.grid.price"),
      getCellValue: (row) => `${row.price.amount} €`,
    },
    {
      name: "title",
      title: t("pages.manage-trip.extras.grid.title"),
    },
    {
      name: "reference",
      title: t("pages.manage-trip.extras.grid.reference"),
    },
    {
      name: "actions",
      title: t("pages.manage-trip.extras.grid.actions"),
      actions: [
        {
          name: t("pages.manage-trip.extras.grid.action.edit"),
          run: (item) => {
            onOpenExtra(item);
          },
        },
        {
          name: t("pages.manage-trip.extras.grid.action.delete"),
          run: (item) => {
            onDeleteExtra(item);
          },
        },
      ],
    },
  ];

  useEffect(() => {
    // if (trip.thumbnail) {
    //   console.log(trip.thumbnail);
    //   fetch(trip.thumbnail ? trip.thumbnail : noImage).then((x) =>
    //     setBlob(x.blobl())
    //   );
    // }
  }, [trip]);

  return (
    <Card>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box mb={3}>
              url
              {errors.onSave && <Alert severity="error">{errors.onSave}</Alert>}
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box mb={3}>
              <TextField
                autoComplete="off"
                required
                fullWidth
                label={t("pages.manage-trip.form.name.label")}
                name="name"
                id="name"
                error={!!errors.name}
                helperText={errors.name}
                onChange={onChange}
                onBlur={onBlur}
                value={trip.name}
              />
            </Box>
            <Box mb={3}>
              <TextField
                autoComplete="off"
                fullWidth
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">€</InputAdornment>
                  ),
                }}
                label={t("pages.manage-trip.form.pricePerAdult.label")}
                name="pricePerAdult"
                id="pricePerAdult"
                type="number"
                error={!!errors.pricePerAdult}
                helperText={errors.pricePerAdult}
                onChange={onChange}
                onBlur={onBlur}
                value={trip.pricePerAdult.amount}
              />
            </Box>
            <Box mb={3}>
              <TextField
                autoComplete="off"
                fullWidth
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">€</InputAdornment>
                  ),
                }}
                label={t("pages.manage-trip.form.pricePerChild.label")}
                name="pricePerChild"
                id="pricePerChild"
                type="number"
                error={!!errors.pricePerChild}
                helperText={errors.pricePerChild}
                onChange={onChange}
                onBlur={onBlur}
                value={trip.pricePerChild.amount}
              />
            </Box>
            <Box mb={3}>
              <TextField
                autoComplete="off"
                fullWidth
                label={t("pages.manage-trip.form.description.label")}
                name="description"
                multiline
                minRows={3}
                error={!!errors.description}
                helperText={errors.description}
                onChange={onChange}
                onBlur={onBlur}
                value={trip.description}
              />
            </Box>
            <Box mb={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    onBlur={onBlur}
                    checked={trip.ignoreDiscounts}
                    onChange={onChange}
                    name="ignoreDiscounts"
                    color="primary"
                  />
                }
                label={t("pages.manage-trip.form.ignore-discounts.label")}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box className={classes.root}>
              <Card className={classes.card}>
                <CardMedia
                  src={trip.thumbnail ? trip.thumbnail : noImage}
                  alt="Trip"
                  component="img"
                  title="Preview"
                />
              </Card>
            </Box>
            <Box
              sx={{
                marginTop: "16px",
                marginBottom: "16px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={() => setOpen(true)}
              >
                Upload Image
              </Button>

              <DropzoneDialogBase
                dialogTitle={
                  <>
                    <span>Upload file</span>
                    <IconButton
                      style={{
                        right: "12px",
                        top: "8px",
                        position: "absolute",
                      }}
                      onClick={() => setOpen(false)}
                    >
                      <Close />
                    </IconButton>
                  </>
                }
                acceptedFiles={["image/*"]}
                fileObjects={fileObjects}
                cancelButtonText={"cancel"}
                submitButtonText={"submit"}
                maxFileSize={5000000}
                filesLimit={1}
                open={open}
                onAdd={(newFileObjs) => {
                  setFileObjects([].concat(fileObjects, newFileObjs));
                }}
                onClose={() => setOpen(false)}
                onSave={() => {
                  onThumbnailChange(fileObjects);
                  setOpen(false);
                }}
                showPreviews={true}
                showFileNamesInPreview={true}
              />
            </Box>
          </Grid>
        </Grid>
        {trip.id && (
          <>
            <AppBar position="static">
              <Tabs value={selectedTab}>
                <Tab
                  label={t("pages.manage-trip.tabs.time-slots")}
                  onClick={() => setSelectedTab(0)}
                />
                <Tab
                  label={t("pages.manage-trip.tabs.extras")}
                  onClick={() => setSelectedTab(1)}
                />
              </Tabs>
            </AppBar>
            {selectedTab === 0 && (
              <Box>
                <Box mt={4}>
                  <Grid container justifyContent="flex-end">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => onOpenSlot()}
                      startIcon={<Add />}
                    >
                      {t("pages.manage-trip.trip-slots.buttons.add-time-slot")}
                    </Button>
                  </Grid>
                </Box>
                <CommonGrid
                  columns={tripSlotsColumns}
                  items={tripSlots.sort(
                    (a, b) =>
                      new Date(a.slotDate.startDate) -
                      new Date(b.slotDate.startDate)
                  )}
                  defaultColumnWidths={[
                    { columnName: "date", width: "auto" },
                    { columnName: "startTime", width: "auto" },
                    { columnName: "endTime", width: "auto" },
                    { columnName: "totalNumberOfPeople", width: "auto" },
                    { columnName: "availableNumberOfPeople", width: "auto" },
                    { columnName: "enabled", width: "auto" },
                    { columnName: "actions", width: 100 },
                  ]}
                />
              </Box>
            )}
            {selectedTab === 1 && (
              <Box>
                <Box mt={4}>
                  <Grid container justifyContent="flex-end">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => onOpenExtra()}
                      startIcon={<Add />}
                    >
                      {t("pages.manage-trip.extras.buttons.add-extra")}
                    </Button>
                  </Grid>
                </Box>
                <CommonGrid
                  columns={extraColumns}
                  items={extras}
                  defaultColumnWidths={[
                    { columnName: "price", width: "auto" },
                    { columnName: "title", width: "auto" },
                    { columnName: "reference", width: "auto" },
                    { columnName: "actions", width: 100 },
                  ]}
                />
              </Box>
            )}
          </>
        )}
      </CardContent>
      <CardActions style={{ justifyContent: "flex-end" }}>
        <Button variant="contained" color="secondary" onClick={onCancel}>
          {t("pages.manage-trip.trip-slots.buttons.close")}
        </Button>
      </CardActions>
    </Card>
  );
};

ManageTripForm.propTypes = {
  trip: PropTypes.object.isRequired,
  tripSlots: PropTypes.array.isRequired,
  extras: PropTypes.array.isRequired,
  errors: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onThumbnailChange: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  onOpenSlot: PropTypes.func.isRequired,
  onDeleteSlot: PropTypes.func.isRequired,
  onOpenExtra: PropTypes.func.isRequired,
  onDeleteExtra: PropTypes.func.isRequired,
  onEnable: PropTypes.func.isRequired,
  onDisable: PropTypes.func.isRequired,
};

export default ManageTripForm;

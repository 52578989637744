import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { GB, ES } from "country-flag-icons/react/3x2";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  iconWidth: {
    width: "30px",
  },
  cardRoot: {
    "& .Cmt-header-root": {
      paddingTop: 14,
      paddingBottom: 14,
    },
  },
  popoverRoot: {
    "& .MuiPopover-paper": {
      width: 205,
    },
  },
}));

const LanguageSwitcher = () => {
  const { i18n, t } = useTranslation();

  const classes = useStyles();
  const languages = [
    {
      languageId: "english",
      locale: "en-GB",
      name: "English",
      icon: <GB className={classes.iconWidth} />,
    },
    {
      languageId: "spanish",
      locale: "es-ES",
      name: "Spanish",
      icon: <ES className={classes.iconWidth} />,
    },
  ];
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "language" : undefined;

  const switchLanguage = (item) => {
    handleClose();
    i18n.changeLanguage(item.locale);
  };

  return (
    <>
      <IconButton size="small" onClick={handleClick}>
        {languages.find((x) => x.locale === i18n.language) ? (
          languages.find((x) => x.locale === i18n.language).icon
        ) : (
          <GB />
        )}
      </IconButton>

      <Popover
        className={classes.popoverRoot}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Card className={classes.cardRoot}>
          <CardHeader title={t("components.languageSwitcher.title")} />
          <Divider />
          <CardContent>
            <List>
              {languages.map((language, index) => (
                <ListItem
                  key={index}
                  button
                  onClick={() => switchLanguage(language)}
                >
                  <ListItemIcon>{language.icon}</ListItemIcon>
                  <ListItemText
                    primary={t(
                      `components.languageSwitcher.languages.${language.locale}`
                    )}
                  />
                </ListItem>
              ))}
            </List>
          </CardContent>
        </Card>
      </Popover>
    </>
  );
};

export default LanguageSwitcher;

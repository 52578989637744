import React from "react";
import PageHeader from "./pageHeader";
import { Box, Slide } from "@material-ui/core";
import PageBreadcrumbs from "./pageBreadcrumbs";
import useStyles from "./pageContainer.style";

const PageContainer = ({ heading, breadcrumbs, children, className, rest }) => {
  const classes = useStyles();

  return (
    <Slide in={true} direction="up" mountOnEnter unmountOnExit>
      <Box className={`${classes.pageFull} ${className || ""}`} {...rest}>
        {(heading || breadcrumbs) && (
          <PageHeader
            heading={heading}
            breadcrumbComponent={
              breadcrumbs && <PageBreadcrumbs items={breadcrumbs} />
            }
          />
        )}
        {children}
      </Box>
    </Slide>
  );
};

export default PageContainer;

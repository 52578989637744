import React from "react";
import { FormControl, MenuItem, Select, TableCell } from "@material-ui/core";

const BoolFilterCell = ({ filter, onFilter, column }) => {
  return (
    <TableCell sx={{ width: "100%", p: 1 }}>
      <FormControl fullWidth>
        <Select
          sx={{ fontSize: "14px", width: "100%" }}
          placeholder="Filter..."
          value={filter !== null && filter !== undefined ? filter.value : ""}
          onChange={(e) =>
            onFilter(
              e.target.value !== null && e.target.value !== undefined
                ? { operation: "=", value: e.target.value }
                : null
            )
          }
          inputProps={{
            style: { textAlign: "right", height: "inherit" },
          }}
        >
          <MenuItem value={true}>Yes</MenuItem>
          <MenuItem value={false}>No</MenuItem>
        </Select>
      </FormControl>
    </TableCell>
  );
};

export default BoolFilterCell;

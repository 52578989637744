import React from "react";
import ChoiceFilterCell from "./ChoiceFilterCell";
import DateFilterCell from "./DateFilterCell";
import { TableFilterRow } from "@devexpress/dx-react-grid-material-ui";
import BoolFilterCell from "./BoolFilterCell";

const FilterCell = (props) => {
  const { column } = props;

  if (column.type === "choice") {
    return <ChoiceFilterCell {...props} />;
  }

  if (column.type === "bool") {
    return <BoolFilterCell {...props} />;
  }

  if (column.type === "date") {
    return <DateFilterCell {...props} />;
  }

  if (column.type === "actions") {
    return null;
  }

  return <TableFilterRow.Cell {...props} />;
};

export default FilterCell;

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  appFooter: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.15)",
  },
  appFooterWrapper: {
    padding: "12px 30px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    minHeight: 64,
    [theme.breakpoints.up("md")]: {
      paddingLeft: 50,
      paddingRight: 50,
    },
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 65,
      paddingRight: 65,
    },
    [theme.breakpoints.up("xl")]: {
      paddingLeft: 88,
      paddingRight: 88,
    },
  },
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  btnRoot: {
    [theme.breakpoints.down("xs")]: {
      padding: "6px 12px",
      fontSize: 11,
    },
  },
}));

export default useStyles;

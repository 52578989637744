import React, { useContext, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { saveCustomer } from "../../api/customersApi";
import ManageCustomerForm from "./ManageCustomerForm";
import AppContext from "../../components/common/providers/AppContext";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const CustomerManagement = (props) => {
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();
  const { quickFeedback, setQuickFeedback } = useContext(AppContext);
  const [errors, setErrors] = useState({});
  const [formIsDirty, setFormDirty] = useState(false);
  const [save, setSave] = useState(false);
  const [customer, setCustomer] = useState(props.customer);

  const formIsValid = () => {
    const errors = {};

    const { email } = customer;

    if (!email)
      errors.email = t("components.customer-management.form.email.error");

    setErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const saveData = () => {
    if (!formIsValid() || !formIsDirty) return;
    getAccessTokenSilently().then((token) => {
      saveCustomer(token, customer)
        .then((response) => {
          setFormDirty(false);
          setCustomer(response.data);
          setQuickFeedback({
            ...quickFeedback,
            show: true,
            severity: "success",
            message: "Customer has been saved successfully.",
          });
          if (props.onCustomerSaved) props.onCustomerSaved(response.data);
        })
        .catch((error) => {
          setErrors({
            onSave:
              error.response && error.response.data
                ? error.response.data
                : error.message,
          });
          setQuickFeedback({
            ...quickFeedback,
            show: true,
            severity: "error",
            message: "Oops! Something went wrong! Try again later.",
          });
        });
    });
  };

  const handleOnChange = (event) => {
    setFormDirty(true);
    const { name, value } = event.target;

    setCustomer({ ...customer, [name]: value });

    if (name === "address" || name === "dateOfBirth") {
      setSave(true);
    }
  };

  useEffect(() => {
    if (save) {
      saveData();
      setSave(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [save]);

  useEffect(() => {
    setCustomer(props.customer);
  }, [props.customer]);

  return (
    <ManageCustomerForm
      showCancelButton={props.showCancelButton}
      showBookings={props.showBookings}
      customer={customer}
      errors={errors}
      onCancel={props.onCancel}
      onChange={handleOnChange}
      onBlur={() => setSave(true)}
    />
  );
};

CustomerManagement.propTypes = {
  customer: PropTypes.object.isRequired,
  onCancel: PropTypes.func,
  showCancelButton: PropTypes.bool,
  onCustomerSaved: PropTypes.func,
  showBookings: PropTypes.bool,
};

export default CustomerManagement;

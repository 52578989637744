import React from "react";
import {
  Plugin,
  Template,
  TemplatePlaceholder,
} from "@devexpress/dx-react-core";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Add } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

const AddBookingToolbarPlugin = ({ isValidator }) => {
  const { t } = useTranslation();

  return (
    <Plugin name="AddBookingToolbarPlugin" dependencies={[{ name: "Toolbar" }]}>
      <Template name="toolbarContent">
        <TemplatePlaceholder />
        <Button
          disabled={isValidator}
          variant="contained"
          color="primary"
          component={Link}
          to={"add-booking"}
          startIcon={<Add />}
        >
          {t("pages.bookings.buttons.add")}
        </Button>
      </Template>
    </Plugin>
  );
};

AddBookingToolbarPlugin.propTypes = {
  isValidator: PropTypes.bool,
};

export default AddBookingToolbarPlugin;

import { Card, CardContent } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import PageContainer from "../components/common/page/pageContainer";
import CreditSlipsGrid from "../components/credit-slips/CreditSlipsGrid";

const MyCreditSlipsPage = () => {
  const { t } = useTranslation();

  const breadcrumbs = [
    { label: t("pages.home-associate.title"), link: "/home-affiliate" },
    { label: t("pages.my-credit-slips.title"), isActive: true },
  ];

  return (
    <PageContainer
      heading={t("pages.my-credit-slips.title")}
      breadcrumbs={breadcrumbs}
    >
      <Card>
        <CardContent>
          <CreditSlipsGrid />
        </CardContent>
      </Card>
    </PageContainer>
  );
};

export default MyCreditSlipsPage;

import axios from "axios";

import { createQueryURL, createAuthHeader } from "./apiUtils";

const baseUrl = `${process.env.REACT_APP_AFFILIATES_API}/api/account`;

export function getCurrentAccount(token) {
  return axios.get(`${baseUrl}`, createAuthHeader(token));
}

export function createAccount(token) {
  return axios.post(`${baseUrl}`, {}, createAuthHeader(token));
}

export function savePaymentInformation(token, paymentInformation) {
  return axios.post(
    `${baseUrl}/payment-information`,
    paymentInformation,
    createAuthHeader(token)
  );
}

export function getPaymentInformation(token) {
  return axios.get(`${baseUrl}/payment-information`, createAuthHeader(token));
}

export function getAssociates(token, loadOptions) {
  return axios.get(
    createQueryURL(baseUrl + "/all", loadOptions),
    createAuthHeader(token)
  );
}

export function approveAccount(token, id) {
  return axios.post(`${baseUrl}/${id}/approve`, null, createAuthHeader(token));
}

import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { cancelBooking, getBookings } from "../../api/bookingsApi";
import ConfirmationDialog from "../common/dialogs/ConfirmationDialog";
import CommonGridExtended from "../common/grids/CommonGridExtended";
import { useReducer } from "react";
import {
  initialState,
  MAX_ROWS,
  reducer,
  VIRTUAL_PAGE_SIZE,
} from "../common/grids/commonGridExtenderUtils";
import { useMemo } from "react";
import { createRowCache } from "@devexpress/dx-react-grid";
import { createQueryURL } from "../../api/apiUtils";
import { useEffect } from "react";
import AddBookingToolbarPlugin from "./AddBookingToolbarPlugin";
import PropTypes from "prop-types";

const BookingsGrid = ({ isValidator }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { getAccessTokenSilently } = useAuth0();
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    sorting: [{ columnName: "ticket", direction: "desc" }],
  });

  const columns = [
    {
      name: "ticket",
      title: t("pages.bookings.grid.ticket"),
      type: "string",
    },
    {
      name: "trip",
      title: t("pages.bookings.grid.trip"),
      type: "string",
    },
    {
      name: "slot",
      title: t("pages.bookings.grid.slot"),
      type: "string",
    },
    {
      name: "customerEmail",
      title: t("pages.bookings.grid.customerEmail"),
      type: "string",
    },
    {
      name: "customerFullName",
      title: t("pages.bookings.grid.customerFullName"),
      type: "string",
    },
    {
      name: "created",
      title: t("pages.bookings.grid.created"),
      width: 150,
      type: "date",
    },
    {
      name: "state",
      title: t("pages.bookings.grid.state"),
      type: "choice",
      options: ["Draft", "Paid", "TicketValidated", "Cancelled"],
      width: 100,
    },
    {
      name: "actions",
      title: t("pages.bookings.grid.actions"),
      actions: [
        {
          name: "View",
          run: (item) => {
            history.push(`/bookings/${item.id}/view`);
          },
          isActionVisible: (item) =>
            item.state === "Paid" ||
            item.state === "TicketValidated" ||
            item.state === "PaidArchived",
        },
        {
          name: "Edit",
          run: (item) => {
            history.push(`/bookings/${item.id}`);
          },
          isActionVisible: (item) => item.state === "Draft" && !isValidator,
        },
        {
          name: "Validate",
          run: (item) => {
            history.push(`/bookings/${item.id}/validate`);
          },
          isActionVisible: (item) => item.state === "Paid",
        },
        {
          name: "Cancel",
          run: (item) => {
            setSelectedBooking(item);
            setOpenCancelConfirmation(true);
          },
          isActionVisible: (item) => item.state === "Paid" && !isValidator,
        },
      ],
      allowSorting: false,
      allowFiltering: false,
      width: 100,
    },
  ];

  const [selectedBooking, setSelectedBooking] = useState(null);
  const [openCancelConfirmation, setOpenCancelConfirmation] = useState(false);

  const handleOnCancelCancelConfirmation = () => {
    setOpenCancelConfirmation(false);
    setSelectedBooking(null);
  };

  const handleOnAcceptCancelConfirmation = () => {
    getAccessTokenSilently().then((token) => {
      cancelBooking(token, selectedBooking).then((response) => {
        setSelectedBooking(null);
        setOpenCancelConfirmation(false);
        dispatch({
          type: "FORCE_RELOAD",
        });
      });
    });
  };

  const cache = useMemo(() => createRowCache(VIRTUAL_PAGE_SIZE), []);

  const updateRows = (skip, count, newTotalCount) => {
    dispatch({
      type: "UPDATE_ROWS",
      payload: {
        skip: Math.min(skip, newTotalCount),
        rows: cache.getRows(skip, count),
        totalCount: newTotalCount < MAX_ROWS ? newTotalCount : MAX_ROWS,
      },
    });
  };

  const loadData = () => {
    const {
      requestedSkip,
      take,
      lastQuery,
      loading,
      forceReload,
      totalCount,
      filters,
      sorting,
    } = state;

    const loadOptions = {
      take,
      filters,
      sorting,
      skip: requestedSkip,
    };
    const query = createQueryURL("", loadOptions);

    if ((query !== lastQuery || forceReload) && !loading) {
      if (forceReload) {
        cache.invalidate();
      }

      const cached = cache.getRows(requestedSkip, take);

      if (cached.length === take) {
        updateRows(requestedSkip, take, totalCount);
      } else {
        dispatch({ type: "FETCH_INIT" });

        getAccessTokenSilently().then((token) => {
          getBookings(token, loadOptions)
            .then((response) => {
              cache.setRows(requestedSkip, response.data.data);
              updateRows(requestedSkip, take, response.data.totalCount);
            })
            .catch(() => dispatch({ type: "REQUEST_ERROR" }));
        });
      }
      dispatch({ type: "UPDATE_QUERY", payload: query });
    }
  };

  const handleOnChangeFilters = (value) => {
    dispatch({ type: "CHANGE_FILTERS", payload: value });
  };

  const handleOnSortingChange = (value) => {
    dispatch({ type: "CHANGE_SORTING", payload: value });
  };

  const handleOnGetRows = (requestedSkip, take) => {
    dispatch({ type: "START_LOADING", payload: { requestedSkip, take } });
  };

  useEffect(() => loadData());

  return (
    <>
      <CommonGridExtended
        state={state}
        columns={columns}
        onChangeFilters={handleOnChangeFilters}
        onGetRows={handleOnGetRows}
        onSortingChange={handleOnSortingChange}
      >
        <AddBookingToolbarPlugin isValidator={isValidator} />
      </CommonGridExtended>
      <ConfirmationDialog
        open={openCancelConfirmation}
        onCancel={handleOnCancelCancelConfirmation}
        onAccept={handleOnAcceptCancelConfirmation}
        message={t("pages.bookings.cancel-booking.message")}
        title={t("pages.bookings.cancel-booking.title")}
      />
    </>
  );
};

BookingsGrid.propTypes = {
  isValidator: PropTypes.bool,
};

export default BookingsGrid;

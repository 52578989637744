import React, { cloneElement, isValidElement, useContext } from "react";

import { List } from "@material-ui/core";
import useStyles from "./NavMenuItem.style";
import SidebarContext from "../providers/SidebarContext";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const NavMenuItem = (props) => {
  const { t } = useTranslation();
  const { name, icon, link } = props;
  const { sidebarTheme } = useContext(SidebarContext);
  const classes = useStyles({ sidebarTheme });

  const renderIcon = () => {
    if (icon && isValidElement(icon)) {
      return cloneElement(icon, {
        className: `${classes.iconRoot} Cmt-icon-root`,
      });
    }

    return null;
  };

  return (
    <List
      component="div"
      className={`${classes.navMenuItem} Cmt-nav-menu-item`}
    >
      <Link className={`${classes.navMenuLink} Cmt-nav-menu-link`} to={link}>
        {/* Display an icon if any */}
        {renderIcon()}
        <span className={`${classes.navText} Cmt-nav-text`}>
          {t(`components.sidebar.menus.${name}`)}
        </span>
      </Link>
    </List>
  );
};

export default NavMenuItem;

import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  makeStyles,
  Grid,
  CardActions,
  Button,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import { getMonthSummary, withdraw } from "../../api/rewardsApi";
import { format, getMonth, getYear } from "date-fns";
import ConfirmationDialog from "../common/dialogs/ConfirmationDialog";
import WithdrawAlert from "./WithdrawAlert";

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: "bold",
  },
}));

const MonthSummary = ({ date, account }) => {
  const [withdrawing, setWithdrawing] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [showWidthdrawAlert, setShowWithdrawAlert] = useState(false);
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();

  const classes = useStyles();
  const [monthSummary, setMonthSummary] = useState(null);

  useEffect(() => {
    getAccessTokenSilently().then((token) => {
      getMonthSummary(token, getYear(date), getMonth(date) + 1).then(
        (response) => {
          setMonthSummary(response.data);
        }
      );
    });
  }, [getAccessTokenSilently, date]);

  const handleOnClickWithdraw = () => {
    setWithdrawing(true);
    setOpenConfirmation(true);
  };

  const handleOnCancelConfirmation = () => {
    setOpenConfirmation(false);
  };

  const handleOnAcceptConfirmation = () => {
    getAccessTokenSilently().then((token) => {
      withdraw(token, getYear(date), getMonth(date) + 1).then(() => {
        getMonthSummary(token, getYear(date), getMonth(date) + 1).then(
          (response) => {
            setMonthSummary(response.data);
            setWithdrawing(false);
            setOpenConfirmation(false);
            setShowWithdrawAlert(true);
          }
        );
      });
    });
  };

  return (
    <>
      <Card>
        <CardHeader
          classes={{ title: classes.title }}
          title={`${t("components.month-summary.title")} ${format(
            date,
            "MMM yyyy"
          )}`}
        ></CardHeader>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={9}>
              {t("components.month-summary.total-trips-sold")}
            </Grid>
            <Grid item xs={3}>
              {monthSummary
                ? monthSummary.potentialItems +
                  monthSummary.cancelledItems +
                  monthSummary.dueItems +
                  monthSummary.paidItems
                : 0}
            </Grid>
            <Grid item xs={9}>
              {t("components.month-summary.potential-earnings")}
            </Grid>
            <Grid item xs={3}>
              {monthSummary
                ? Math.round(
                    (monthSummary.potentialAmount + Number.EPSILON) * 100
                  ) /
                    100 +
                  " €"
                : 0 + " €"}
            </Grid>
            <Grid item xs={9}>
              {t("components.month-summary.due-earnings")}
            </Grid>
            <Grid item xs={3}>
              {monthSummary
                ? Math.round((monthSummary.dueAmount + Number.EPSILON) * 100) /
                    100 +
                  " €"
                : 0 + " €"}
            </Grid>
            <Grid item xs={9}>
              {t("components.month-summary.paid-earnings")}
            </Grid>
            <Grid item xs={3}>
              {monthSummary
                ? Math.round((monthSummary.paidAmount + Number.EPSILON) * 100) /
                    100 +
                  " €"
                : 0 + " €"}
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleOnClickWithdraw}
            disabled={
              withdrawing ||
              !monthSummary ||
              monthSummary.dueAmount === 0 ||
              !account ||
              !account.isPaymentInformationSet
            }
          >
            {t("components.month-summary.withdraw-button")}
          </Button>
        </CardActions>
        <WithdrawAlert show={showWidthdrawAlert} />
      </Card>
      <ConfirmationDialog
        open={openConfirmation}
        onCancel={handleOnCancelConfirmation}
        onAccept={handleOnAcceptConfirmation}
        message={`${t(
          "components.month-summary.confirmation.message"
        )} ${format(date, "MMM yyyy")}?`}
        title={t("components.month-summary.confirmation.title")}
      />
    </>
  );
};

export default MonthSummary;

import React, { useContext, useMemo } from "react";
import { create } from "jss";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import {
  createTheme,
  jssPreset,
  StylesProvider,
} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppContext from "./providers/AppContext";
import VerticalLayout from "./layout/VerticalLayout";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { es } from "date-fns/locale";

const jss = create({ plugins: [...jssPreset().plugins] });

const AppThemeWrapper = ({ className, children }) => {
  const { theme } = useContext(AppContext);

  const muiTheme = useMemo(() => {
    return createTheme(theme);
  }, [theme]);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
      <ThemeProvider theme={muiTheme}>
        <StylesProvider jss={jss}>
          <CssBaseline />
          <VerticalLayout
            className={`verticalMinimalLayout ${className && className}`}
          >
            {children}
          </VerticalLayout>
        </StylesProvider>
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
};

export default AppThemeWrapper;

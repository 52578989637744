import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { useState } from "react";

const SalesReportDialog = ({ open, onClose, onGenerate }) => {
  const { t } = useTranslation();
  const [date, setDate] = useState(new Date());

  return (
    <Dialog maxWidth="xs" open={open} onClose={onClose} fullWidth>
      <DialogTitle>{t("components.sales-report-dialog.title")}</DialogTitle>
      <DialogContent>
        <Box>
          <KeyboardDatePicker
            views={["year", "month"]}
            fullWidth
            disableToolbar
            variant="inline"
            format="MMM yyyy"
            margin="normal"
            label={t("components.sales-report-dialog.form.date")}
            value={date}
            onChange={(value) => setDate(value)}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose} color="secondary">
          {t("components.sales-report-dialog.buttons.cancel")}
        </Button>
        <Button
          variant="contained"
          disabled={!date}
          onClick={() => onGenerate(date)}
          color="primary"
        >
          {t("components.sales-report-dialog.buttons.generate")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

SalesReportDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onGenerate: PropTypes.func.isRequired,
};

export default SalesReportDialog;

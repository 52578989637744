import {
  MenuItem,
  MenuList,
  Paper,
  Popover,
  Typography,
} from "@material-ui/core";
import React, { useContext, useState } from "react";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import useStyles from "./SidebarHeader.style";
import SidebarContext from "../providers/SidebarContext";
import CustomAvatar from "../customAvatar/CustomAvatar";
import { useAuth0 } from "@auth0/auth0-react";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import AppContext from "../providers/AppContext";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import PaymentIcon from "@material-ui/icons/Payment";
import { useHistory } from "react-router-dom";

const SidebarHeader = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const { roles } = useContext(AppContext);
  const { sidebarTheme } = useContext(SidebarContext);
  const classes = useStyles({ sidebarTheme });
  const { user, logout } = useAuth0();
  const { name, picture, email } = user;
  const [isAssociate, setIsAssociate] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const onLogoutClick = () => {
    handlePopoverClose();
    logout({
      returnTo: window.location.origin,
    });
  };

  useEffect(() => {
    if (roles.includes("affiliate")) {
      setIsAssociate(true);
    }
  }, [roles]);

  const handleOnPaymentInformation = () => {
    handlePopoverClose();
    history.push("/payment-information");
  };

  return (
    <div className={classes.root}>
      <CustomAvatar src={picture} alt="User Avatar" />
      <div className={classes.userInfo} onClick={handlePopoverOpen}>
        <div
          className="pointer"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          <div className="mr-2">
            <Typography
              className={classes.userTitle}
              component="h3"
              variant="h6"
            >
              {name}
            </Typography>
            <Typography className={classes.userSubTitle}>{email}</Typography>
          </div>
          <ArrowDropDownIcon />
        </div>
      </div>
      {open && (
        <Popover
          open={open}
          anchorEl={anchorEl}
          container={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
        >
          <Paper elevation={8}>
            <MenuList>
              {isAssociate && (
                <MenuItem onClick={handleOnPaymentInformation}>
                  <PaymentIcon />
                  <div className="ml-2">
                    {t("components.sidebar-header.payment-information")}
                  </div>
                </MenuItem>
              )}
              <MenuItem onClick={onLogoutClick}>
                <ExitToAppIcon />
                <div className="ml-2">
                  {t("components.sidebar-header.logout")}
                </div>
              </MenuItem>
            </MenuList>
          </Paper>
        </Popover>
      )}
    </div>
  );
};

export default SidebarHeader;

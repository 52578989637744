import React from "react";
import { Box } from "@material-ui/core";
import useStyles from "./Footer.style";

const Footer = (props) => {
  const classes = useStyles();
  const date = new Date();

  return (
    <Box className={`${classes.appFooter} Cmt-footer`}>
      <Box className={`${classes.appFooterWrapper} Cmt-footer-wrapper`}>
        <Box className={classes.root} {...props}>
          <Box display="flex" alignItems="center">
            <Box
              fontSize={{ xs: 12, sm: 14 }}
              component="p"
              color="text.disabled"
            >
              Copyright Lanchas la gaviota © {date.getFullYear()}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;

import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Card,
  CardMedia,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useTranslation } from "react-i18next";
import noImage from "../../assets/images/no-image-found-360x260.png";
import { DropzoneDialogBase } from "material-ui-dropzone";
import { Close } from "@material-ui/icons";

const useStyles = makeStyles({
  root: {
    paddingTop: "50px",
  },
  card: {
    width: 345,
    margin: "0 auto",
  },
});
const ManageExtraForm = ({ errors, onChange, extra, onThumbnailChange }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [fileObjects, setFileObjects] = React.useState([]);

  const { t } = useTranslation();
  return (
    <Grid container>
      <Grid item xs={6}>
        <Box className={classes.root}>
          <Card className={classes.card}>
            <CardMedia
              src={extra.image ? extra.image : noImage}
              alt="Extra"
              component="img"
              title="Preview"
            />
          </Card>
        </Box>
        <Box
          sx={{
            marginTop: "16px",
            marginBottom: "16px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpen(true)}
          >
            {t("components.manage-trip-extra-dialog.form.upload-image")}
          </Button>

          <DropzoneDialogBase
            dialogTitle={
              <>
                <span>
                  {t("components.manage-trip-extra-dialog.form.upload-image")}
                </span>
                <IconButton
                  style={{
                    right: "12px",
                    top: "8px",
                    position: "absolute",
                  }}
                  onClick={() => setOpen(false)}
                >
                  <Close />
                </IconButton>
              </>
            }
            acceptedFiles={["image/*"]}
            fileObjects={fileObjects}
            cancelButtonText={"cancel"}
            submitButtonText={"submit"}
            maxFileSize={5000000}
            filesLimit={1}
            open={open}
            onAdd={(newFileObjs) => {
              setFileObjects([].concat(fileObjects, newFileObjs));
            }}
            onClose={() => setOpen(false)}
            onSave={() => {
              onThumbnailChange(fileObjects);
              setOpen(false);
            }}
            showPreviews={true}
            showFileNamesInPreview={true}
          />
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box mb={3}>
          {errors.onSave && <Alert severity="error">{errors.onSave}</Alert>}
        </Box>

        <Box mb={3}>
          <TextField
            autoComplete="off"
            required
            fullWidth
            label={t("components.manage-trip-extra-dialog.form.title.label")}
            name="title"
            type="text"
            error={!!errors.title}
            helperText={errors.title}
            onChange={onChange}
            value={extra.title}
          />
        </Box>
        <Box mb={3}>
          <FormControl fullWidth error={!!errors.reference}>
            <InputLabel>
              {t("components.manage-trip-extra-dialog.form.reference.label")}
            </InputLabel>
            <Select
              name="reference"
              value={extra.reference}
              onChange={onChange}
            >
              <MenuItem value="ESPIGO-ADULT-MENU">ESPIGO-ADULT-MENU</MenuItem>
              <MenuItem value="ESPIGO-CHILD-MENU">ESPIGO-CHILD-MENU</MenuItem>
            </Select>
            <FormHelperText>{errors.reference}</FormHelperText>
          </FormControl>
        </Box>
        <Box mb={3}>
          <TextField
            autoComplete="off"
            required
            fullWidth
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
            }}
            label={t("components.manage-trip-extra-dialog.form.price.label")}
            name="price"
            type="number"
            error={!!errors.price}
            helperText={errors.price}
            onChange={onChange}
            value={extra.price.amount.toString()}
          />
        </Box>
        <Box mb={3}>
          <TextField
            autoComplete="off"
            required
            fullWidth
            multiline
            minRows={3}
            label={t(
              "components.manage-trip-extra-dialog.form.description.label"
            )}
            name="description"
            type="text"
            error={!!errors.description}
            helperText={errors.description}
            onChange={onChange}
            value={extra.description}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

ManageExtraForm.propTypes = {
  extra: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onThumbnailChange: PropTypes.func.isRequired,
};

export default ManageExtraForm;

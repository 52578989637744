import React from "react";
import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";
import { getStatistics } from "../../api/statisticsApi";
import { useState } from "react";
import {
  Chart,
  Tooltip,
  Border,
  CommonSeriesSettings,
  Series,
  Legend,
  Export,
} from "devextreme-react/chart";

const emptyRevenues = [
  { month: 1, revenue: 0 },
  { month: 2, revenue: 0 },
  { month: 3, revenue: 0 },
  { month: 4, revenue: 0 },
  { month: 5, revenue: 0 },
  { month: 6, revenue: 0 },
  { month: 7, revenue: 0 },
  { month: 8, revenue: 0 },
  { month: 9, revenue: 0 },
  { month: 10, revenue: 0 },
  { month: 11, revenue: 0 },
  { month: 12, revenue: 0 },
];

const Statistics = (props) => {
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();

  const [monthlyRevenue, setMonthlyRevenue] = useState([]);
  const [series, setSeries] = useState([]);

  const getMonthName = (month) => {
    switch (month) {
      case 1:
        return "Jan";
      case 2:
        return "Feb";
      case 3:
        return "Mar";
      case 4:
        return "Apr";
      case 5:
        return "May";
      case 6:
        return "Jun";
      case 7:
        return "Jul";
      case 8:
        return "Ago";
      case 9:
        return "Sep";
      case 10:
        return "Oct";
      case 11:
        return "Nov";
      case 12:
        return "Dec";
      default:
        return "";
    }
  };

  const mapStatisticsForChart = (data) => {
    return data
      .map(({ year, number, ...rest }) => rest)
      .reduce((acc, { tripName, ...rest }) => {
        let item = acc.find((x) => x.trip === tripName);

        if (item) {
          item.items = [...item.items, { ...rest }];
          return acc;
        } else {
          return [...acc, { trip: tripName, items: [{ ...rest }] }];
        }
      }, [])
      .map(({ trip, items }) => {
        return {
          trip,
          items: [...items, ...emptyRevenues]
            .reduce((acc, value) => {
              let item = acc.find((x) => x.month === value.month);

              if (item) {
                item.revenue += value.revenue;
              } else {
                acc.push({ ...value });
              }
              return acc;
            }, [])
            .sort((a, b) => (a.month > b.month ? 1 : -1))
            .map((x) => {
              return {
                ...x,
                monthName: getMonthName(x.month),
              };
            }),
        };
      })
      .reduce((acc, value) => {
        return acc.concat(
          value.items.map((x) => {
            return {
              monthName: x.monthName,
              [value.trip]: x.revenue,
            };
          })
        );
      }, [])
      .reduce((acc, { monthName, ...rest }) => {
        let index = acc.findIndex((x) => x.monthName === monthName);

        if (index >= 0) {
          acc[index] = { ...acc[index], ...rest };
          return acc;
        } else {
          return [...acc, { monthName, ...rest }];
        }
      }, []);
  };

  useEffect(() => {
    getAccessTokenSilently().then((token) => {
      getStatistics(token).then((response) => {
        let data = mapStatisticsForChart(response.data);

        setMonthlyRevenue(data);
        setSeries(
          response.data
            .map((x) => x.tripName)
            .filter((value, index, self) => self.indexOf(value) === index)
        );
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAccessTokenSilently]);

  return (
    <Chart title={t("components.statistics.title")} dataSource={monthlyRevenue}>
      <CommonSeriesSettings argumentField="monthName" type="stackedBar" />

      {series.map((x, index) => {
        return <Series key={index} valueField={x} name={x} />;
      })}

      <Legend>
        <Border visible />
      </Legend>
      <Export enabled />

      <Tooltip enabled />
    </Chart>
  );
};

export default Statistics;

import React from "react";

import { Box, AppBar, Toolbar, Hidden, InputBase } from "@material-ui/core";
import SidebarToggleHandler from "./SidebarToggleHandler";
import Logo from "./Logo";
import SearchIcon from "@material-ui/icons/Search";

import useStyles from "./Header.style";
import LanguageSwitcher from "./LanguageSwitcher";
import { useTranslation } from "react-i18next";

const Header = ({ className }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <AppBar
      position="static"
      className={`${classes.appHeader} ${className || ""} Cmt-header`}
    >
      <Toolbar className={classes.root}>
        <SidebarToggleHandler edge="start" color="inherit" aria-label="menu" />
        <Logo ml={2} />
        <Box flex={1} />
        <Hidden smDown>
          <Box pr={3} className={classes.searchRoot}>
            <InputBase
              placeholder={t("components.header.search")}
              inputProps={{ "aria-label": "search" }}
            />
            <SearchIcon />
          </Box>
        </Hidden>
        {/* <HeaderMessages /> */}
        {/* <HeaderNotifications /> */}
        <Box className={classes.langRoot}>
          <LanguageSwitcher />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;

import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemText,
  TextField,
} from "@material-ui/core";
import { format } from "date-fns";
import { useState } from "react";

const DeleteTripSlotDialog = ({
  tripSlot,
  open,
  onClose,
  onDelete,
  onDeleteAll,
}) => {
  const { t } = useTranslation();

  const [message, setMessage] = useState("");

  return (
    <Dialog maxWidth="md" open={open} onClose={onClose} fullWidth>
      <DialogTitle>{t("components.delete-trip-slot-dialog.title")}</DialogTitle>
      <DialogContent>
        {tripSlot && (
          <>
            <Box>{t("components.delete-trip-slot-dialog.content")}</Box>
            <Box mt={3}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label={t("components.delete-trip-slot-dialog.form.date")}
                    InputProps={{
                      readOnly: true,
                    }}
                    value={format(
                      new Date(tripSlot.slotDate.startDate),
                      "E, dd MMMM yyyy"
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox disabled checked={tripSlot.isRecurring} />
                    }
                    label={t(
                      "components.delete-trip-slot-dialog.form.recurring"
                    )}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box mt={3}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Box>
                    {t(
                      "components.delete-trip-slot-dialog.form.departure-origin"
                    )}
                  </Box>
                  <List>
                    {tripSlot.departureOriginTimes.map((item, index) => (
                      <ListItem dense key={index}>
                        <ListItemText primary={item}></ListItemText>
                      </ListItem>
                    ))}
                  </List>
                </Grid>
                <Grid item xs={6}>
                  <Box>
                    {t(
                      "components.delete-trip-slot-dialog.form.departure-destination"
                    )}
                  </Box>
                  <List>
                    {tripSlot.departureDestinationTimes.map((item, index) => (
                      <ListItem dense key={index}>
                        <ListItemText primary={item}></ListItemText>
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label={t(
                      "components.delete-trip-slot-dialog.form.totalNumberOfPeople"
                    )}
                    InputProps={{
                      readOnly: true,
                    }}
                    value={tripSlot.totalNumberOfPeople}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label={t(
                      "components.delete-trip-slot-dialog.form.availableNumberOfPeople"
                    )}
                    InputProps={{
                      readOnly: true,
                    }}
                    value={tripSlot.availableNumberOfPeople}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box my={4}>
              <TextField
                autoComplete="off"
                multiline
                minRows={3}
                required
                fullWidth
                label={t("components.delete-trip-slot-dialog.form.message")}
                name="message"
                id="message"
                onChange={(event) => setMessage(event.target.value)}
                value={message}
              />
            </Box>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose} color="secondary">
          {t("components.delete-trip-slot-dialog.buttons.cancel")}
        </Button>
        <Button
          variant="contained"
          disabled={!message}
          onClick={() => onDelete(message)}
          color="primary"
        >
          {t("components.delete-trip-slot-dialog.buttons.delete")}
        </Button>
        {tripSlot && tripSlot.isRecurring && (
          <Button
            variant="contained"
            disabled={!message}
            onClick={() => onDeleteAll(message)}
            color="primary"
          >
            {t("components.delete-trip-slot-dialog.buttons.deleteAll")}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

DeleteTripSlotDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  tripSlot: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onDeleteAll: PropTypes.func.isRequired,
};

export default DeleteTripSlotDialog;
